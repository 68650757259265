@import '../../../../../styles/_colors.scss';
@import '../../../../../styles/_mixins.scss';

.member-details-header {
  background-color: $white;
  padding: 0 30px;

  isc-button.go-back {
    margin-top: 5px;

    .md-button {
      color: $insites-green;
      padding: 0;

      .icon {
        margin-right: 0;

        i {
          font-weight: bold;
        }
      }
      .label {
        font-size: 1rem;
      }
    }
  }

  .profile-details {
    margin: 5px 0 0;

    .booking {
      float: right;
      isc-button.booking-button {
        .md-button {
          background-color: $square-primary;
          color: $white;

          .icon {
            margin-top: 5px;
            i {
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          &[disabled] {
            background-color: $hover-grey;
            color: $box-shadow;
          }
        }
      }
    }

    .profile-image {
      flex: 0 0 auto;
      border-radius: 100%;
      width: 140px;
      height: 140px;
      margin-right: 25px;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;

      .overlay {
        background: rgba(201, 201, 201, .75);
        text-align: center;
        opacity: 0;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all .2s ease-in-out;
        display: flex;
        font-size: 2.8rem;
        outline: none;
        cursor: pointer;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }

    .error-messages {
      color: $errors;
      margin-top: 5px;
    }

    .details {
      float: left;
      width: 100%;
      .details-container {
        height: 100%;
        position: relative;

        .member-name {
          font-size: 2.5rem;
          font-weight: 600;
          color: $primary-text;
          margin-bottom: 5px;
        }

        .member-info {
          margin-top: 10px;
        }

        .bottom-aligned {
          position: absolute;
          bottom: 0;
        }

        isc-progress-linear {
          .progress-bar-line {
            max-width: 200px;
          }
        }
      }
    }
  }
}

.activity-tab-filter {
  .md-button {
    font-size: 1rem;
    color: $secondary-text;
  }
}

.member-details-content {
  margin: 0;

  md-table-container {
    margin: 30px;
    div.pull-right.layout-row {
      margin: 10px;
    }
  }

  isc-incentive .incentives-summary {
    margin-top: 30px;
  }

  .md-nav-bar {
    margin-bottom: 30px;
    padding-left: 30px;
    background-color: $white;

    .md-button {
      text-transform: initial;
    }
  }

  member-details-segmentations {
    md-icon {
      margin: 0;
      @include md-icon-size(20px);
      margin-top: -12px;
      margin-right: 2px;
      color: $insites-green;
    }
    thead tr th md-icon {
      margin-top: 0;
    }
    isc-timeline ul {
      &:before {
        display: none;
      }

      .moreCards {
        display: none;
      }

      .listItem {
        width: 80%;

        &:after {
          margin-left: -2px;
          margin-bottom: -20px;
          position: absolute;
          content: ' ';
          width: 2px;
          background-color: transparent;
          top: 0;
          bottom: 0;
          left: 0;
        }
      }

      & > div:first-of-type {
        .listItem:after {
          top: 26px;
        }
      }

      & > div:last-of-type {
        .listItem:after {
          height: 26px;
          margin-bottom: 0;
        }
      }
    }
  }

  .timeline-badge {
    .md-button {
      &.md-fab {
        &:not([disabled]):active,
        &:not([disabled]):hover {
          background-color: $square-primary;
        }

        &[disabled] {
          background-color: $hover-grey;
        }

        &.outdated {
          background-color: $white;
          border: 1px solid $square-primary;

          md-icon {
            color: $square-primary;
          }
        }
      }
    }
  }

  isc-forum-post {
    &.reply-level-0,
    &.reply-level-1,
    &.reply-level-2,
    &.reply-level-3 {
      margin-left: 0;

      .profile-image {
        width: 30px;
        height: 30px;
      }
    }
  }

  .post-author-details.layout-align-start-center.layout-row {
    display: none;
  }
  isc-forum-post .header-replypostcontent-container .post-author-details {
    display: none;
  }
  flag-button {
    display: none;
  }
  isc-post-footer {
    display: none;
  }
  isc-button.member-segments {
    display: none;
  }
  .isc-activity-status-filter {
    bottom: 0;
  }
  .far.fa-star {
    display: none;
  }

  isc-table-container md-table-container table.md-table tbody.md-body > tr {
    &.details.md-row td.reward-list {
      border-bottom: 1px solid rgba(0, 0, 0, .12);
      &:hover {
        background-color: transparent;
      }
      .md-button.md-accent[disabled] md-icon {
        color: $radio-button-on;
      }
      isc-forum-post.top-post {
        background-color: $white;
      }
    }
    &.md-row {
      &.expanded {
        background-color: $table-grey;
      }

      td.md-cell {
        i.fa {
          font-size: .875rem;
        }
        i.fa:before {
          margin-top: 7px;
        }
      }
    }
    isc-forum-post {
      .header-replypostcontent-container,
      .top-post-contents {
        padding-left: 40px;

        img.profile-image {
          display: none;
        }
      }
    }
  }
  .arrow-box {
    position: relative;
    background: #F0F3F5;
    border: 2px solid #E7E9E9;
    padding: 5px;
  }

  .admin-intf-card-container {
    background-color: $white;
  }

  .background-white {
    background-color: $white;
  }

  .arrow-box:after,
  .arrow-box:before {
    right: 100%;
    top: 15px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow-box:after {
    border-color: rgba(240, 243, 245, 0);
    border-right-color: #F0F3F5;
    border-width: 15px;
    margin-top: -15px;
  }

  .arrow-box:before {
    border-color: rgba(231, 233, 233, 0);
    border-right-color: #E7E9E9;
    border-width: 17px;
    margin-top: -17px;
  }

  .page-content > div {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
  }

  .icon-button .md-button {
    .inactive {
      color: $disabled-or-hint-text;
    }
  }

  .data-moderation {
    padding: 0 10px;
    max-height: 100%;
    width: 100%;
    transition: all .4s;
    overflow: hidden;
    position: absolute;

    &:hover {
      z-index: 5;
    }

    .notes,
    .tags {
      display: none;
      opacity: .8;
      box-sizing: border-box;
      position: relative;
      max-height: 50%;
      max-width: 100%;
      height: 100%;
      margin-bottom: 5px;
    }

    &.on-top-note,
    &.on-top-tag {
      position: unset;

      .shadow-div {
        display: none;
      }

      .arrow-box {
        z-index: 3;
      }
    }
    &.on-top-note .notes,
    &.on-top-tag .tags,
    .notes:hover,
    .tags:hover {
      display: block;
      opacity: 1;
    }

    .arrow-box {
      width: calc(100% - 16px);
      margin-left: 16px;
    }
    &.notes-filter .notes,
    &.tags-filter .tags {
      display: block;
      max-height: 100%;
    }

    &.on-top-note.tags-filter .tags,
    &.on-top-tag.notes-filter .notes {
      opacity: 1;
    }

    &.notes-filter.tags-filter .tags {
      max-height: 50%;
      overflow: hidden;
      height: auto;
    }

    isc-activity-data-post-notes {
      width: 100%;
    }

    .shadow-div {
      position: absolute;
      box-shadow: 0 0 20px 25px #FAFAFA;
      bottom: 0;
      width: 100%;
      margin: 0;
      left: 0;
    }

    .no-shadow .shadow-div {
      display: none;
    }

    .note {
      &.note-list-item {
        border-bottom: 2px solid #E7E9E9;

        &.no-border {
          border: 0;
        }
      }
      b,
      span.note-text {
        width: calc(100% - 60px);
        display: block;
        word-wrap: break-word;
        white-space: pre-wrap;
      }

      textarea {
        height: auto;
        width: 100%;
        max-width: 100%;
        border: none;
        margin: 0;
      }
    }

    md-chips-wrap {
      background-color: #FFFFFF;
      box-shadow: none;

      md-chip {
        display: table;

        span {
          white-space: initial;
          word-break: break-all;
        }
      }
    }
  }

  .convPagination {
    overflow: visible;
  }

  .page-title {
    padding-top: 0;
    height: initial;
    min-height: initial;
  }

  .edit-forum-post {
    display: block;
    padding: 0 20px 20px;
  }

  export-data-component {
    margin-bottom: 6px;
  }

  .notes-column {
    position: relative;
    padding-bottom: 40px;
  }

  isc-activity-data-post {
    .stimuli-item {
      position: relative;

      isc-stimuli-download i {
        position: absolute;
        color: #FFFFFF;
        top: 6px;
        right: 3px;
        z-index: 2;
        cursor: pointer;
        text-shadow: -1px 0 $black, 0 1px $black, 1px 0 $black, 0 -1px $black;
        visibility: hidden;
      }
    }
    .stimuli-item:hover isc-stimuli-download i {
      visibility: visible;
    }
  }

  isc-rich-input {
    flex-grow: 1;
    width: 100%;

    md-input-container {
      margin-top: 0;
      padding-top: 0;

      .nw-editor {
        height: auto;
        min-height: auto;

        .nw-editor__res {
          min-height: 80px;
        }
      }
    }
  }
}

.dot-divider {
  margin: 0 10px;
}

md-content.redemption-container {
  text-align: center;
  overflow: hidden;
}

/* This css is needed to make sure that the tooltip covers the entire request a call button */

.booking-container {
  position: relative;
}

.booking-container.tooltip-enabled:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 1;
}

.booking-container:hover md-tooltip {
  display: block;
}

md-tooltip {
  position: absolute;
  display: none;
}
