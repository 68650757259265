isc-modal-dialog.data-import-modal {
  width: 700px;
  .infotext {
    margin-bottom: 20px;
  }
  .inputfield {
    display: unset;
  }
  .inputlabel {
    position: unset;
  }
}
