﻿.md-color-picker-dialog,
.mdp-datepicker,
.mdp-timepicker {
  background-color: $white;
}

.modal-edit-communication {
  font-size: .8rem;
  width: 900px !important;
  overflow: auto;
  .sso-engagement-message {
    .sso-ideastream-tick {
      min-width: 200px;
    }
  }

  .infoPage .inputField {
    width: 410px;
  }

  .dateTime-label {
    width: 390px;
  }

  button {
    box-shadow: none !important;
  }

  md-dialog-content {
    $edit-communication-dialog-padding: 15px;
    padding: $edit-communication-dialog-padding;
    width: 100%;

    .buttons {
      margin-left: -$edit-communication-dialog-padding;
      margin-right: -$edit-communication-dialog-padding;
    }
  }

  .page-content {
    margin: 0;
  }
}

.insites-dialog {
  overflow: visible;

  .md-button.close-button {
    font-size: 1.6rem;
    color: $white;
    position: absolute;
    margin: 0;
    right: 0;
    min-width: 0;
    min-height: 0;
    top: -34px;
    padding: 0;
    width: auto;
  }
}

.new-square-dialog {
  max-width: 500px;
  ul {
    list-style: none;
    padding-left: 0;
  }
}
