$opacity-regular: .87;
$opacity-onethird: .38;
$opacity-half: .5;

// navigation top bar
$nav-top-height: 75px;

// side nav
$sider-nav-width: 225px;
$menu-bar-height: 65px;

// page content
$page-content-margin: 50px;
$page-title-height: 155px;

// info page
$info-page-padding-right: 50px;

// info page buttons
$info-page-buttons-height: 80px;
$info-page-buttons-padding-left: 50px;
$info-page-buttons-margin-top: 20px;
$info-page-buttons-margin-bottom: 20px;

// buttons
$button-height: 40px;
