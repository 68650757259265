@import '../../_colors.scss';
@mixin isc-button-size($height, $font-size) {
  height: $height;

  .md-button {
    height: $height;
    font-size: $font-size;
  }
}
@mixin isc-icon-button-size($button-size, $icon-size) {
  height: $button-size;

  .md-button {
    min-width: $button-size;
    max-width: $button-size;
    line-height: $button-size;
    width: $button-size;
    height: $button-size;
    min-height: $button-size;
    font-size: $icon-size;

    .icon {
      line-height: $icon-size;
    }

    md-icon {
      min-width: $icon-size;
      max-width: $icon-size;
      width: $icon-size;
      height: $icon-size;
    }
  }
}

isc-button,
.request-a-call-btn {
  display: inline-block;
  border-radius: border-inline-radius-values(2px);
  color: $white;
  #{$margin-inline-end}: 15px;
  @include isc-button-size($button-small-height, $button-small-font-size);

  .md-redirect-to-communication-wizard {
    color: #347F62;
    background-color: #347F62;
  }

  .md-button.md-raised:not([disabled]),
  .md-button.md-raised:not([disabled]):active {
    box-shadow: none;
  }

  .md-button.md-raised {
    &.md-accent,
    &.md-primary,
    &.md-warn {
      color: $white;

      .icon md-icon {
        color: $white;
        vertical-align: top;
      }
    }
  }

  .md-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: normal;
    text-transform: none;
    #{$padding-inline-start}: 20px;
    #{$padding-inline-end}: 20px;
    min-width: 0;
    margin: margin-inline-values(0);
    color: $white;

    .icon {
      line-height: 24px;
      display: inline-block;
      vertical-align: middle;

      &:empty {
        display: none;
      }

      &:last-child {
        #{$margin-inline-end}: 0;
      }
    }

    &.has-label {
      .icon {
        #{$margin-inline-end}: 10px;
      }
    }

    &.md-raised[disabled] {
      background-color: $button-disabled;
      color: $disabled-or-hint-text;
    }

    &.default {
      color: $text-base;

      &[disabled] {
        background-color: transparent;
        color: $button-disabled;
      }
    }
  }

  &.ellipsis {
    .md-button {
      .label {
        text-overflow: ellipsis;
        width: 160px;
        overflow: hidden;
        display: inline-block;
      }
    }
  }

  &.outline {
    .md-button {
      border: solid 1px;
      border-color: currentColor;
    }
  }

  &.big {
    @include isc-button-size($button-big-height, $button-big-font-size);
  }

  &.icon-button {
    margin: margin-inline-values(0);
    color: inherit;
    @include isc-icon-button-size($icon-button-size, $icon-button-icon-size);

    .md-button {
      color: inherit;
      padding: padding-inline-values(0);

      md-icon {
        margin: margin-inline-values(0);
        color: inherit;
      }
    }

    &.large-size {
      @include isc-icon-button-size($icon-button-large-size, $icon-button-large-icon-size);
    }
  }
}
