﻿md-toast {
  &.toast {
    div.md-toast-content {
      border-radius: 10px;
      padding: 0 24px;
      text-transform: uppercase;
      font-weight: bold;
      position: relative;
      .close-icon {
        position: absolute;
        top: 5px;
        right: -10px;
        color: $white;
        font-size: 1.1rem;
        cursor: pointer;
      }
    }
  }

  &.toast-error {
    div.md-toast-content {
      background-color: #FDDFE5;
      color: $insites-red;

      > md-icon {
        line-height: 21px;
        font-size: 1.5rem;
        color: $insites-red;
      }
    }
  }

  &.toast-success {
    div.md-toast-content {
      background-color: $insites-green;
      color: $white;

      > md-icon {
        color: $white;
        font-size: 1.5rem;
      }
    }
  }
}
