@import '../_colors.scss';

.notif {
  text-align: center;
}

.clear-container {
  text-align: center;
  background-color: $white;
  cursor: pointer;
  color: $primary;
  font-weight: bold;
  height: 30px;

  span {
    position: relative;
    top: 5px;
  }
}

.clear-container:hover {
  background-color: rgba(0, 0, 0, .12);
}

.md-open-menu-container {
  &:before {
    #{$end}: 27px !important;
  }

  > .notification-menu-content {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 540px;
    max-width: 320px;
    display: block;
    overflow-x: auto;
    @media screen and (max-height: 700px) {
      max-height: 70vh;
    }

    .md-button {
      padding-bottom: 8px;
      padding-top: 8px;
      line-height: 17px;
    }

    .md-button:not(.last-element) {
      border-bottom: 1px solid $notification-item-border;
    }

    .notification-label {
      white-space: normal;
      font-size: .8rem;
      #{$margin-inline-end}: 7px;
    }

    md-menu-item {
      display: block;
      height: auto;
      min-height: 0;

      md-icon {
        &.close {
          position: absolute;
          #{$end}: 0;
          top: 7px;
          #{$margin-inline-end}: 0;
          #{$padding-inline-start}: 5px;
        }
      }
    }
  }
}

isc-user-notification {
  a.bell:not([disabled]):hover {
    background-color: transparent;
  }

  .notifications {
    .menu-icon {
      cursor: pointer;
      position: relative;
      top: 7px;
    }

    .bell {
      min-width: inherit;
      min-height: inherit;
    }

    &.mobile {
      notification-counter {
        .numbered-circle {
          bottom: 32px;
          #{$start}: 11px;
          width: 17px;
          height: 17px;
          line-height: 17px;
        }
      }

      md-icon {
        font-size: 1.6rem;
      }
    }
  }
}
