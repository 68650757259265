@import '../../_variables.scss';
@import '../../colors';

isc-rich-input {
  > md-input-container {
    width: 100%;
  }

  .isc-rich-input-label {
    font-size: .875rem;
    #{$padding-inline-start}: 4px;
  }

  .isc-rich-input-prefixsuffix {
    background: $input-prefixsuffix;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .error-message {
    font-size: .8rem;
  }

  .length-counter {
    float: $end;
    min-height: 24px;
    min-width: 1px;
    font-size: .8rem;
    line-height: 14px;
    overflow: hidden;
    transition: all .3s cubic-bezier(.55, 0, .55, .2);
    opacity: 1;
    margin-top: 0;
    padding-top: 5px;
    text-align: $end;
    #{$padding-inline-end}: 2px;
    #{$padding-inline-start}: 0;
  }
}

.nw-button.isc-nw-indent {
  &:before {
    content: '\F03C';
  }
}

.nw-button.isc-nw-outdent {
  &:before {
    content: '\F03B';
  }
}

.nw-button.isc-nw-remove-format {
  &:before {
    content: '\F12D';
  }
}
.nw-editor {
  padding: padding-inline-values(0);
  color: rgba(0, 0, 0, .54);
}

.nw-editor__res {
  font-size: .875rem;
  padding: padding-inline-values(10px 0 5px 5px);
  min-height: 200px;
  display: block;
  word-break: break-word;
}

ng-wig {
  .nw-toolbar {
    height: 32px;

    > li {
      float: $start;
    }
  }
  &.hide-toolbar {
    .nw-toolbar {
      display: none;
    }
    .nw-editor-container {
      border: 1px solid $caption-editor-border-color;
    }
  }

  .nw-editor-container .nw-editor > div {
    &:focus-visible {
      outline: none;
    }
  }
}
