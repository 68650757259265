@import '../../../styles/_colors.scss';

wizard-targeting {

  .box {
    border: 1px solid;
    border-color: rgba(0, 0, 0, .38);
    padding: 5px;
    width: fit-content;
    color: #000000;
  }

  .targeting-section-title {
    display: inline;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: bold;
    font-size: .875rem;
  }

  .section-title-container {
    margin-top: 0;
    margin-bottom: 10px;
  }

  label.section-title {
    display: inline;
    padding: 0;
    margin-bottom: 10px;
  }

  .isc-input-label-subscript {
    margin-bottom: 25px;
  }

  .radioButtons {
    display: block;
  }

  .inlineRadioGroup md-radio-button {
    margin-right: 0;
  }

  .subtitle-bar {
    width: 50%;
  }

  .subtitle-bar.left {
    text-align: left;
    position: relative;
  }

  .subtitle-bar.right {
    text-align: right;
  }

  .h4 {
    color: #000000;
    font-weight: 600;
  }

  .additionalTargeting {
    background-color: #F5F5F5;
    padding: 0;
  }

  .additionalTargeting-container {
    padding: 30px;
  }

  .and-label {
    margin: 0;
    padding: 0;
    position: relative;
    top: 20px;
    font-weight: bold;
  }

  div.randomization,
  div.exclusion {
    margin: -25px 0 50px 25px;
    span {
      position: absolute;
      margin-top: 21px;
    }
  }

  div.targeting-review {
    margin-bottom: 20px;
  }

  div.display-randomization {
    margin-top: 20px;
    label.section-sub-title {
      margin-bottom: 10px;
      display: block;
      font-size: .875rem;
    }
  }

  div.targeting-by-segmentation {
    margin-bottom: 30px;
  }

  targeting-result.matches div {
    margin-bottom: 20px;
  }

  div.randomization-warnings {
    p {
      font-size: .6rem;
    }
  }

  isc-input.randomly-selected-input {
    margin-left: 5px;
    margin-top: 15px;
  }

  div.md-input-messages-animation {
    width: 350px;
    font-size: .6rem;
  }

  md-input-container {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;

    .md-input {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  isc-input + span {
    &.tooltip-container {
      height: auto;
      top: 0;
    }
  }

  .completion-target-tooltip {
    line-height: 16px;
    padding: 4px 0;
  }

  .activity-target-completion-wrapper {
    .segment-selector {
      margin-top: 40px;
      margin-bottom: 20px;

      delete-button {
        margin-left: 15px;
      }
    }

    isc-table-container.with-border md-table-container {
      border-collapse: collapse;
      padding: 20px 30px;
      border-radius: 0;

      .total-row {
        color: $light-grey;
        border-top: 1px solid $border-grey;
      }
    }
  }

  .add-segmentation {
    margin-top: 40px;

    isc-button {
      button {
        padding-top: 2px;
        padding-left: 0;
        isc-button-icon {
          margin-bottom: 5px;

          span {
            font-size: .875rem;
          }
        }
      }
    }
  }

  .total-number-unique-completes {
    font-size: .8rem;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
  }

  tr > :first-child {
    width: 35%;
  }

  .total-tooltip > * {
    display: inline-block;// Stops tooltip from jumping to next line
  }

  .tooltip-container {
    position: relative;

    > span.tooltip {
      position: absolute;
      bottom: 8px;
    }
  }

  .red {
    color: #FF0000;
  }
}
