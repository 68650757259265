isc-pagination {
  display: block;
  text-align: center;

  .dots {
    font-size: .3rem;
  }

  .hide-arrow {
    visibility: hidden;
  }

  .md-button {
    border-radius: border-inline-radius-values(100%);
    width: 26px;
    height: 26px;
    min-height: 0;
    min-width: 0;
    line-height: 1;
    padding: padding-inline-values(0);

    .md-ripple-container {
      border-radius: border-inline-radius-values(100%);
    }
  }

  &.intro {
    padding-top: 0;
    padding-bottom: 0;
  }
}
