﻿.activity-preview-modal.md-transition-in {
  background-color: rgba(0, 0, 0, 0) !important;
  .previewActivityDialog-header {
    margin-bottom: 10px;
    min-width: 900px !important;
    color: #FFFFFF;
    text-align: center;

    h2,
    .h2 {
      position: relative;
      font-size: .875rem;
      bottom: 5px;

      .right {
        position: relative;
        float: right;
        margin-right: 20px;
        font-size: 1.3rem;
        bottom: 5px;
      }
    }
  }
}

.activity-preview-modal {
  box-shadow: none;
  max-width: 50%;

  isc-challenge-card {
    flex: 0 0 400px;
  }
}
