@import '../../_colors.scss';
@import '../../variables.scss';

isc-progress-linear {
  .progress-bar-container {
    align-items: center;

    .progress-bar-line {
      height: 7px;
      border-radius: border-inline-radius-values(10.5px);
      background-color: $lighter-grey;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      width: 100%;

      &.line {
        border-radius: border-inline-radius-values(0);

        &.green {
          background-color: $green;
        }

        &.orange {
          background-color: $orange;
        }

        &.darkGrey {
          background-color: $dark-grey;
        }
      }
    }

    isc-progress-linear-content {
      #{$margin-inline-start}: 10px;
    }
  }
}
