@import '../../../../styles/colors.scss';

isc-user-details-button {
  isc-button .md-button {
    /*override the default padding of the button since we want to save space here*/
    padding-right: 10px;
    padding-left: 10px;
  }
}

isc-switch {
  color: $black;
}
