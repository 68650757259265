@import '../../_colors.scss';
@import '../../_variables.scss';

isc-switch md-switch.no-margin {
  margin: margin-inline-values(0);
}

isc-switch {
  font-size: .875rem;
  .tooltip {
    display: inline;
    color: $disabled-or-hint-text;

    .fa {
      font-size: .875rem;
      #{$margin-inline-start}: 1%;
    }
  }
}
