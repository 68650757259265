show-replies-button {
  .show-replies-button-content {
    display: block;
    background-color: $white;
  }

  .md-button {
    height: 40px;
    margin: margin-inline-values(0);
    width: 100%;
    text-align: $start;
    padding: padding-inline-values(0 20px);
    @include media(lt-md) {
      padding: padding-inline-values(0 10px);
    }
    @include media(xxs) {
      padding: padding-inline-values(0 10px);
    }
  }

  &.full-width {
    .show-replies-button-content {
      width: 100%;
    }
  }

  .scout-conversation {
    width: 100%;
    max-width: 614px;
    margin: margin-inline-values(0 auto);

    .md-button {
      padding: padding-inline-values(0);
      text-align: center;
    }
  }
}
