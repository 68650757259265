@import '../_colors.scss';
@import '../_language.scss';

$forum-post-horizontal-padding: 30px;
$forum-post-image-size-level0-desktop: 50px;
$forum-post-image-size-level1-desktop: 50px;
$forum-post-image-size-level2-desktop: 40px;
$forum-post-image-size-level3-desktop: 30px;

$forum-post-image-size-level0-mobile: 40px;
$forum-post-image-size-level1-mobile: 40px;
$forum-post-image-size-level2-mobile: 35px;
$forum-post-image-size-level3-mobile: 30px;

$forum-post-stimuli-size-width: 250px;
$forum-post-stimuli-size-height: 115px;

$forum-post-stimuli-size-height-mobile: 80px;
$forum-post-profile-image-to-username: 10px;
$forum-post-profile-image-to-username-mobile: 5px;
$forum-post-title-padding: 4px;
$forum-username-font-size: 1rem;

$scout-mobile-lateral-margin: 20px;

isc-forum-post {
  background-color: $white;
  display: block;

  &.highlight {
    background-color: $forum-topic-background;
  }

  .md-ink-ripple i:before {
    font-size: larger;
  }
  .nw-editor {
    height: auto;
    min-height: 200px;
    display: table;
  }
  .nw-editor,
  .reply-forum-post.read-only {
    font-size: .875rem;

    &.caption {
      clear: both;
      font-size: .8rem;
      font-weight: bold;
    }
  }
  .top-header-reply-contents {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
    flex: 1 1 auto;
  }

  .bold-600 {
    font-weight: 600;
  }

  .reply-button {
    margin: margin-inline-values(0 8px);
    @include media(xxs) {
      padding: padding-inline-values(0);
      margin: margin-inline-values(0);
      min-width: 40px;

      i {
        font-size: 1.4rem;
      }

      span {
        #{$padding-inline-end}: 5px;
        #{$padding-inline-start}: 5px;
      }
    }
  }

  .content .mention {
    background-color: $mention-color;
    color: $black;
    display: inline-block;
    width: auto;
  }

  .post-label {
    height: 21px;
    font-size: .8rem;
    border-radius: border-inline-radius-values(5px);
    #{$margin-inline-start}: 7px;
    padding-top: 1px;

    .post-label-text {
      #{$margin-inline-start}: 7px;
      #{$margin-inline-end}: 7px;
      color: $white;
    }
    @include media (lt-md) {
      .post-label-text {
        line-height: 18px;
      }
    }
    @include media (xxs) {
      height: 21px;
      width: 65px;
      font-size: .7rem;
      border-radius: border-inline-radius-values(5px);
      #{$margin-inline-start}: 4px;
      padding-top: 1px;

      .post-label-text {
        #{$margin-inline-start}: 5px;
        line-height: 16px;
      }
    }
  }

  .update-label {
    width: auto;
    text-transform: uppercase;
    float: $end;

    .update-label-text {
      #{$margin-inline-start}: 7px;
      #{$margin-inline-end}: 7px;
    }
    @include media (xxs) {
      width: auto;
      text-transform: uppercase;

      .post-label-text {
        #{$margin-inline-start}: 5px;
        #{$margin-inline-end}: 5px;
      }
    }
  }

  .md-button.accept {
    background-color: $button-disabled;
    color: $dark-grey;
    min-height: 20px;
    line-height: 20px;
    font-weight: normal;
    #{$margin-inline-start}: 0;
    width: auto;
    text-transform: uppercase;
    float: $end;
    min-width: auto;
    font-size: .8rem;
    border-radius: border-inline-radius-values(5px);
    #{$margin-inline-start}: 7px;
    padding-top: 1px;
    &.toBeAccepted {
      background-color: $button-disabled !important;
      color: $dark-grey !important;
    }
    .accept-label-text {
      color: $dark-grey;
    }
    @include media (xxs) {
      width: auto;
      text-transform: uppercase;

      .accept-label-text {
        #{$margin-inline-start}: 5px;
        #{$margin-inline-end}: 5px;
      }
    }
    &[disabled] {
      background-color: $primary;
      .accept-label-text {
        color: $white;
      }
    }
  }

  .accept-label {
    width: auto;
    text-transform: uppercase;
    float: $end;

    .accept-label-text {
      #{$margin-inline-start}: 7px;
      #{$margin-inline-end}: 7px;
    }
    @include media (xxs) {
      width: auto;
      text-transform: uppercase;

      .accept-label-text {
        #{$margin-inline-start}: 5px;
        #{$margin-inline-end}: 5px;
      }
    }
  }

  .top-post-contents {
    #{$padding-inline-end}: 20px;
    #{$padding-inline-start}: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    word-break: break-word;

    &.scout-conversation-no-padding {
      #{$padding-inline-end}: 0;
      #{$padding-inline-start}: 0;
    }
  }

  .forum-message-text {
    font-size: .875rem;
    line-height: 19px;
    padding-bottom: 1px;

    span {
      word-wrap: break-word;
      font-size: .875rem;
      line-height: 19px;
      @include media(lt-md) {
        word-wrap: break-word;
        font-size: .8rem;
        line-height: 18px;
      }
    }
  }

  .header-replypostcontent-container {
    display: flex;
    justify-content: space-between;
    border: 0;
    word-wrap: break-word;
    word-break: break-word;
    padding-top: 10px;
    border-top: 1px solid $lighter-grey;
    flex-direction: row;

    &.scout-conversation {
      max-width: 614px;
      width: 100%;
      margin: margin-inline-values(0 auto);
      #{$padding-inline-end}: 0;
      #{$padding-inline-start}: 0;
    }

    .profile-image-link {
      display: inline-block;

      .profile-image {
        flex: 0 0 auto;
        border-radius: border-inline-radius-values(100%);
        width: $forum-post-image-size-level0-desktop;
        height: $forum-post-image-size-level0-desktop;
        float: $start;
        #{$margin-inline-end}: $forum-post-profile-image-to-username;
        @include media(lt-md) {
          width: $forum-post-image-size-level0-mobile;
          height: $forum-post-image-size-level0-mobile;
          #{$margin-inline-start}: $forum-post-profile-image-to-username-mobile;
          #{$margin-inline-end}: $forum-post-profile-image-to-username-mobile;
        }
      }
    }
    @include media(lt-md) {
      .reply-post-contents {
        flex: 1 1 auto;
        #{$padding-inline-end}: 10px;
        padding-top: 0;
        word-break: break-word;
        word-wrap: break-word;
      }
    }
    @include media(xxs) {
      .reply-post-contents {
        padding: padding-inline-values(0);
      }
    }

    .header-content {
      flex: 1 1 auto;
      word-break: break-word;

      &.scout-conversation {
        max-width: 614px;
        width: 100%;
        margin: margin-inline-values(0 auto);
        padding: padding-inline-values(10px 0);

        .profile-image-link {
          display: inline;
        }

        & ~ .reply-post-contents.scout-conversation .isc-inline-image-stimuli {
          @include media(lt-md) {
            margin: margin-inline-values(0); /* reset the margins set for the general case */
          }
        }
      }
    }

    .reply-post-contents {
      flex: 1 1 auto;
      padding-top: 5px;

      &.scout-conversation {
        max-width: 614px;
        margin: 0 auto;
        width: 100%;
        #{$padding-inline-end}: 0;
        #{$padding-inline-start}: 0;
      }
    }

    .post-title {
      font-size: 1.3rem;
      font-weight: bold;
      margin: margin-inline-values(0);
    }

    .post-user {
      font-weight: bold;
      margin: margin-inline-values(0 0 1px);
      #{$padding-inline-end}: 5px;
      font-size: $forum-username-font-size;

      &.single-line {

        a > span {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100%);
          white-space: nowrap;
        }

        & ~ .post-label {
          white-space: nowrap;
        }
      }

      a {
        text-decoration: none;
      }
    }

    .post-buttons {
      float: $end;
      #{$margin-inline-start}: 6px;

      .action-buttons {
        clear: both;
        width: fit-content;
        #{$margin-inline-start}: auto;
      }
    }

    .post-info {
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .post-author-details {
      font-size: .8rem;
      line-height: 1.5;
      width: 100%;
      .date {
        #{$padding-inline-start}: 3px;
      }
      @include media(lt-md) {
        font-size: .8rem;
        line-height: 14px;
        display: inline-flex;
      }
      @include media(xxs) {
        font-size: .6rem;
      }
    }
  }

  > .content {
    padding: padding-inline-values(0 20px);
    $content-font-size: .875rem;
    font-size: $content-font-size;
    line-height: calc(18px / #{$content-font-size});
    margin: margin-inline-values(13px 0);
    word-break: break-word;
    @include media(lt-md) {
      $content-font-size: .8rem;
      font-size: $content-font-size;
      line-height: calc(14px / #{$content-font-size});
      margin: margin-inline-values(7px 0);
    }
  }

  .one-question-answer {
    font-size: .875rem;
    color: $black;
    padding: padding-inline-values(10px 20px);
  }

  .contribution-title {
    font-size: .875rem;
    color: rgba(0, 0, 0, .54);
    margin-bottom: 5px;
  }

  footer {
    line-height: 36px;
    margin-bottom: 10px;

    like-button {
      min-width: 0;
      min-height: 0;
      line-height: 1;
      height: 20px;
      font-size: .875rem;
      font-weight: normal;
      text-transform: none;
      color: inherit;

      button.md-button {
        #{$padding-inline-start}: 0;
        #{$padding-inline-end}: 0;
      }

      span {
        color: inherit;
      }

      > .typcn {
        font-size: 1.1rem;
      }

      &:first-child {
        #{$margin-inline-start}: 0;
      }

      &:last-child {
        #{$margin-inline-end}: 0;
      }
    }

    md-button {
      min-width: 0;
      min-height: 28px;
      line-height: 1;
      height: 20px;
      font-size: .875rem;
      font-weight: normal;
      text-transform: none;

      > .typcn {
        font-size: 1.1rem;
      }

      &:first-child {
        #{$margin-inline-start}: 0;
      }

      &:last-child {
        #{$margin-inline-end}: 0;
      }
    }
    .forum-button {
      display: none;
    }

    &:focus-within .forum-button {
      display: inline-block;
      padding-inline-start: 0;
      padding-inline-end: 0;
      min-width: 0;
      min-height: 19px;
      line-height: 19px;
      height: 28px;
      margin-inline-start: 0;
      margin-inline-end: 0;

      .typcn {
        font-size: 1.2rem;
        line-height: 1;
        height: 19px;
        display: inline-block;
      }
    }

    .md-button.generic-reply {
      font-size: .8rem;
      #{$margin-inline-start}: 0;
      &.update-post {
        color: $black;
      }
      &.opening-post {
        border-radius: border-inline-radius-values(2px 2px 2px 2px);
        min-height: 26px;
        line-height: 26px;
        font-weight: normal;
        .reply-button {
          font-size: .875rem;
        }
      }

      &[disabled] {
        background-color: $button-disabled !important;
        color: $disabled-or-hint-text !important;
      }
    }

    &.scout-conversation {
      max-width: 614px;
      margin: 0 auto;
      width: 100%;
      @include media(lt-md) {
        padding: padding-inline-values(0 $scout-mobile-lateral-margin);
      }

      .reply-button {
        margin: margin-inline-values(0);
      }
    }

    &.scout-opening-post {
      max-width: 614px;
      margin: 0 auto;
      width: 100%;

      .reply-button {
        margin: margin-inline-values(0);
      }
    }
  }

  isc-stimuli,
  isc-stimulus-item-preview {
    display: block;
    float: $start;
    max-width: 100%;
    #{$padding-inline-end}: 5px;
    padding-bottom: 5px;
    @include media(xxs) {
      #{$padding-inline-end}: 0;
      margin: margin-inline-values(0);
    }
  }

  .attachments {
    a {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
    }

    p {
      font-size: .875rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      margin: margin-inline-values(0);
    }
  }

  &.top-post {
    background-color: $forum-topic-background;
    border-bottom: 1px solid $lighter-grey;

    .header-replypostcontent-container {
      margin: margin-inline-values(0);
      padding: padding-inline-values(10px 20px 0);
      @include media(lt-md) {
        padding: padding-inline-values(15px 10px 0);
      }

      &.scout-conversation {
        margin: margin-inline-values(0 auto);
        #{$padding-inline-end}: 0;
        #{$padding-inline-start}: 0;
      }
    }

    &.consumer-intf {
      .posted-key:before {
        display: none;
      }
    }

    &.scout-conversation footer {
      @include media(lt-md) {
        #{$margin-inline-start}: $scout-mobile-lateral-margin; /* override lateral margins*/
        #{$margin-inline-end}: $scout-mobile-lateral-margin;
      }
    }

    like-button {
      .md-button.md-primary {
        padding: padding-inline-values(0 1px);
        margin: margin-inline-values(0);
      }
    }
  }

  &.consumer-intf {
    .post-author-details {
      .role,
      .status {
        display: none;
      }
      @include media(lt-md) {
        .posted-key:before {
          display: none;
        }
      }
    }
  }
  // button
  &.consumer-intf:hover .forum-button {
    display: inline-block;
    padding: padding-inline-values(0);
    min-width: 0;
    min-height: 19px;
    line-height: 19px;
    height: 28px;
    margin: margin-inline-values(0);

    .typcn {
      font-size: 1.2rem;
      line-height: 1;
      height: 19px;
      display: inline-block;
    }
  }
  @include media(lt-md) {
    &.consumer-intf:focus .forum-button {
      display: inline-block;
    }
  }

  //  dots
  .post-info {
    .role:before,
    .id:before,
    .status:before,
    .posted-key:before {
      content: '\A';
      width: 4px;
      height: 4px;
      border-radius: border-inline-radius-values(100%);
      background: $secondary-text;
      display: inline-block;
      margin: margin-inline-values(3px 4px);
    }
  }
  // replies
  $reply-padding-sides-default-desktop: 20px;
  $reply-margin-sides-default-mobile: 0;
  $reply-padding-sides-default-mobile: 0;

  &.reply {
    #{$padding-inline-end}: $reply-padding-sides-default-desktop;
    #{$padding-inline-start}: $reply-padding-sides-default-desktop;

    .reply-button {
      font-size: .8rem;
      #{$margin-inline-start}: 0;
    }

    .post-info {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      flex: 1 1 100%;
      @include media(lt-md) {
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 100%;

        //  dots
        .role:before,
        .id:before,
        .status:before,
        .posted-key:before {
          width: 3px;
          height: 3px;
        }
      }
    }

    .header-replypostcontent-container {
      .underlined-title {
        display: none;
      }
    }

    .stimuli-container {
      border: solid 1px $stimuli-border-color;
    }

    .broken-stimuli-container {
      border: dashed 1px $broken-stimuli-border-color;
    }

    .broken-stimuli-conatiner-owner:hover {
      background-color: $border-grey;
      cursor: hand;
    }
    @include media(lt-md) {
      #{$margin-inline-start}: $reply-margin-sides-default-mobile;
      #{$padding-inline-end}: $reply-margin-sides-default-mobile;
      #{$padding-inline-start}: $reply-padding-sides-default-mobile;
      #{$margin-inline-end}: 0;
    }
  }

  &.reply-level-0 {
    .profile-image {
      width: $forum-post-image-size-level0-desktop;
      height: $forum-post-image-size-level0-desktop;
      @include media(lt-md) {
        width: $forum-post-image-size-level0-mobile;
        height: $forum-post-image-size-level0-mobile;
      }
    }

    .stimuli-container {
      height: $forum-post-stimuli-size-height;
      width: $forum-post-stimuli-size-width;
      max-width: 100%;
      font-size: 1.8rem;
      @include media(lt-md) {
        min-height: $forum-post-stimuli-size-height-mobile;
      }
    }

    .isc-inline-image-stimuli img {
      max-width: $forum-post-stimuli-size-width;
    }
  }

  &.reply-level-1 {
    @extend .reply;

    .profile-image {
      width: $forum-post-image-size-level1-desktop;
      height: $forum-post-image-size-level1-desktop;
      @include media(lt-md) {
        width: $forum-post-image-size-level1-mobile;
        height: $forum-post-image-size-level1-mobile;
      }
    }

    .stimuli-container {
      height: calc(#{$forum-post-stimuli-size-height} * 9 / 10);
      width: calc(#{$forum-post-stimuli-size-width} * 9 / 10);
      max-width: 100%;
      font-size: 1.6rem;
      @include media(lt-md) {
        min-height: calc(#{$forum-post-stimuli-size-height-mobile} * 9 / 10);
      }
    }

    .isc-inline-image-stimuli img {
      max-width: calc(#{$forum-post-stimuli-size-width} * 9 / 10);
    }
  }

  $level2-start-padding-desktop: $forum-post-image-size-level1-desktop + $forum-post-profile-image-to-username + $reply-padding-sides-default-desktop;
  $level2-start-padding-mobile: $forum-post-image-size-level1-mobile + $forum-post-profile-image-to-username-mobile + $reply-margin-sides-default-mobile;

  &.reply-level-2 {
    @extend .reply;
    #{$padding-inline-start}: $level2-start-padding-desktop;
    @include media(lt-md) {
      #{$padding-inline-start}: $level2-start-padding-mobile;

      .reply-button {
        #{$padding-inline-end}: 2px;
        #{$margin-inline-end}: 2px;
      }
    }

    &.scout-conversation {
      max-width: 614px;
      margin: 0 auto;
      #{$padding-inline-start}: 25px;
      #{$padding-inline-end}: 0;
    }

    .profile-image {
      width: $forum-post-image-size-level2-desktop;
      height: $forum-post-image-size-level2-desktop;
      @include media(lt-md) {
        width: $forum-post-image-size-level2-mobile;
        height: $forum-post-image-size-level2-mobile;
      }
    }

    .stimuli-container {
      height: calc(#{$forum-post-stimuli-size-height} * 8 / 10);
      width: calc(#{$forum-post-stimuli-size-width} * 8 / 10);
      max-width: 100%;
      font-size: 1.4rem;
      @include media(lt-md) {
        min-height: calc(#{$forum-post-stimuli-size-height-mobile} * 8 / 10);
      }
      @include media(xxs) {
        max-width: 90%;
      }
    }

    .isc-inline-image-stimuli img {
      max-width: calc(#{$forum-post-stimuli-size-width} * 8 / 10);
    }
  }
  $level3-start-padding-desktop: $level2-start-padding-desktop + $forum-post-image-size-level2-desktop + $forum-post-profile-image-to-username;
  $level3-start-padding-mobile: $level2-start-padding-mobile + $forum-post-image-size-level2-mobile + $forum-post-profile-image-to-username-mobile;

  &.reply-level-3 {
    @extend .reply;
    #{$padding-inline-start}: $level3-start-padding-desktop;
    @include media(lt-md) {
      #{$padding-inline-start}: $level3-start-padding-mobile;
    }

    &.scout-conversation {
      max-width: 614px;
      margin: 0 auto;
      #{$padding-inline-start}: 50px;
      #{$padding-inline-end}: 0;
    }

    .profile-image {
      width: $forum-post-image-size-level3-desktop;
      height: $forum-post-image-size-level3-desktop;
      @include media(lt-md) {
        width: $forum-post-image-size-level3-mobile;
        height: $forum-post-image-size-level3-mobile;
      }
    }

    .stimuli-container {
      height: calc(#{$forum-post-stimuli-size-height} * 7 / 10);
      width: calc(#{$forum-post-stimuli-size-width} * 7 / 10);
      max-width: 100%;
      font-size: 1.2rem;
      @include media(lt-md) {
        min-height: calc(#{$forum-post-stimuli-size-height-mobile} * 7 / 10);
      }
      @include media(xxs) {
        max-width: 150px;
      }
    }

    .isc-inline-image-stimuli img {
      max-width: calc(#{$forum-post-stimuli-size-width} * 7 / 10);
    }
  }

  &.probing-question {
    .forum-message-text.bulleted-question:before {
      content: '•';
      #{$margin-inline-end}: 5px;
    }

    .post-info,
    .profile-image-link {
      display: none;
    }

    .header-replypostcontent-container {
      #{$padding-inline-start}: 0;
      #{$margin-inline-start}: 5px;
      vertical-align: top;
      width: calc(100% - 15px);
      border-top: 0;
      padding-top: 0;
      margin-top: -2px;

      &.scout-conversation {
        margin: margin-inline-values(0);
        width: 100%;
      }
    }
  }
}

.sort-options-banner {
  flex-wrap: wrap;

  .qual-title {
    span {
      display: block;
      word-wrap: break-word;
    }
  }
  @include media(lt-md) {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;

    .qual-title {
      align-self: flex-start;
      order: 2;

      span {
        display: block;
        word-wrap: break-word;
      }
    }

    .qual-banner-dropdown {
      order: 1;
      align-self: flex-end;
    }
  }
}

show-replies-button {
  color: $secondary-text;
}

div.conversation-sort-header {
  margin-top: 10px;
  padding: padding-inline-values(10px 20px 10px 19px);
  font-size: 1.3rem;
  background-color: $forum-topic-background;
  border-bottom: 1px solid $notification-item-border;
}

.reply-buttons {
  padding: padding-inline-values(10px 20px);
}

.scout-conversation {
  &.reply-buttons {
    max-width: 614px;
    margin: 0 auto;
    width: 100%;
    padding: padding-inline-values(0);
  }

  .submit-answer-button {
    @include media(lt-md) {
      #{$margin-inline-start}: $scout-mobile-lateral-margin; /* override lateral margins*/
      #{$margin-inline-end}: $scout-mobile-lateral-margin;
    }
  }

  isc-upload-conversation .info-text {
    @include media(lt-md) {
      #{$margin-inline-start}: $scout-mobile-lateral-margin; /* override lateral margins*/
      #{$margin-inline-end}: $scout-mobile-lateral-margin;
    }
  }

  isc-caption-editor {
    @include media(lt-md) {
      margin: margin-inline-values(auto $scout-mobile-lateral-margin);
    }
  }

  .forum-message-text {
    &.bulleted-question:before {
      @include media(lt-md) {
        #{$margin-inline-start}: $scout-mobile-lateral-margin;
      }
    }
    &:not(.bulleted-question) > .isc-inline-image-stimuli {
      @include media(lt-md) {
        margin: margin-inline-values(auto $scout-mobile-lateral-margin);
      }
    }
  }

  .reply-forum-post {
    @include media(lt-md) {
      margin: margin-inline-values(auto $scout-mobile-lateral-margin);
    }
  }
}

.text-focus-scout {
  padding: padding-inline-values(0 20px);
  max-width: 614px;
  margin: 0 auto;
  width: 100%;
}
