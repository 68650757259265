@import '../../../styles/colors';

.infoPage {
  .tooltip-container {
    i.fa {
      margin-left: 0;
    }
  }

  td {
    position: relative;
  }

  tr.strikeout td:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid $text-base;
    width: 100%;
  }

  tr.strikeout td:after {
    content: '\00B7';
    font-size: 1px;
  }
}
