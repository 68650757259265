@import '../../_colors.scss';

isc-modal-preset {
  max-width: 500px;

  .question {
    color: $warning-message;
    font-weight: bold;
    font-size: 1rem;
  }

  .text {
    white-space: pre-line;
    margin: margin-inline-values(0);
  }

  isc-modal-dialog.grey-footer .modal-actions {
    background-color: $isc-grey !important;
    justify-content: flex-start !important;
  }
}
