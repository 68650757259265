squareclosure {
  background: #FFFFFF;
  width: 100%;
  display: flex;
  @include isc-status();
  .square-status {
    margin-top: 10px;
    .status-badge {
      margin-top: 10px;
      margin-bottom: 10px;
      &.status-active:before,
      &.status-closed:before,
      &.status-scheduled:before,
      .status-archived:before {
        display: none;
      }
    }
    label.sub-title {
      font-weight: bold;
      font-size: .875rem;
      margin-top: 10px;
    }
    isc-rich-input.closure-message {
      md-input-container {
        width: 1000px;
        margin-top: 30px;
        .isc-rich-input-label {
          font-weight: bold;
          font-size: .875rem;
        }
        .nw-editor {
          height: 150px;
        }
      }
    }
    .close-square-radio,
    .close-square-label {
      color: $red;
    }
    .close-square-radio-bold {
      font-weight: 500;
    }
    .close-square-radio {
      md-radio-button {
        &.md-checked .md-off {
          border-color: $red;
        }
        .md-on {
          background-color: $red;
        }
      }
    }
    .archived-square-radio,
    .archived-square-label {
      color: $red;
      opacity: .5;
    }
    .archived-square-radio {
      md-radio-button {
        &.md-checked .md-off {
          border-color: $red;
          opacity: .5;
        }
        .md-on {
          background-color: $red;
          opacity: .5;
        }
      }
    }

    .activate-square-label {
      color: $green;
    }
    span.tooltip-container .fa {
      font-size: .875rem;
      margin-left: 1%;
      color: $black;
    }
  }
}
