@import '../../../../../styles/colors';

activity-probe-qual-build {
  .isc-input-label-subscript {
    color: $disabled-or-hint-text;
    font-size: .7rem;
    font-weight: 600;
    line-height: 15px;
    display: block;
  }
  .nw-editor {
    height: 167px;
  }
}
