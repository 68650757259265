﻿@import 'overview-page-mixins';

.overview-page {
  @include overview-page;

  .deleted-square {
    pointer-events: none;
  }
}

.content-header activity-config-component,
activity-health-component,
activity-info-component,
activity-qual-research-config-component,
activity-wizard-type,
help-center,
incentives,
instant-rewards,
main-pages,
message-overview,
periodic-rewards,
square-activities-component,
square-engagement,
square-research-activity,
tabs-footer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}

//need for IE and firefox scrolling
activity-health-component,
activity-health-component .activity-health-component,
activity-info-component,
activity-qual-research-config-component,
square-forum-room,
square-health-component,
square-health-component .health-page,
square-research-activity,
square-research-activity .page-content {
  min-height: 0;
}

activity-qual-research-config-component,
activity-config-component,
activity-info-component {
  .page-title {
    padding-top: 0;
    height: initial;
    min-height: initial;
  }

  isc-button.go-back {
    margin-top: 5px;

    .md-button {
      color: $insites-green;
      padding: 0;

      .icon {
        margin-right: 0;

        i {
          font-weight: bold;
        }
      }
      .label {
        font-size: 1rem;
      }
    }
  }
}
