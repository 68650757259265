﻿.md-autocomplete-suggestions-container {
  list-style: none;

  li {
    color: $text-base;

    i.typcn-star,
    i.typcn-star-outline {
      float: right;
    }

    i.fa,
    i.fas.fa-globe {
      float: left;
      position: relative;
      top: 16px;
      font-size: .875rem;
    }

    i.fas.fa-globe {
      margin-right: 10px;
    }

    i.fa-star {
      float: none;
      top: -20px;
    }

    pin-button {
      position: relative;
      top: -20px;
    }

    .highlight {
      color: $insites-orange;
    }

    &.selected,
    &:hover {
      background-color: $insites-orange;
      color: $white;

      .highlight {
        color: $black;
      }
    }

    > .selected {
      background-color: $insites-orange;
      color: $white;
    }

    span.breadcrumbList {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 40px);
    }
  }

  .active {
    font-weight: bold;
  }
}

.pinned-last-selected-list {
  width: 190px;
  margin-top: 40px;
  box-shadow: 0 2px 8px #DDDDDD;
  max-height: 240px;
  overflow-y: auto;

  .md-autocomplete-suggestions-container {
    padding-left: 0;
    margin: 0;

    li {
      font-size: .875rem;
      overflow: hidden;
      padding: 0 15px;
      line-height: 48px;
      height: 48px;
      transition: background .15s linear;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.main-search {
  display: inline-block;
  position: relative;
  left: 40px;
  vertical-align: middle;
  width: 250px;

  md-content {
    overflow: hidden;
  }

  input[type='search']::placeholder {
    /* WebKit, Blink, Edge */
    opacity: .5;
  }
}
