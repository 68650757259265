﻿search-bar:not(.noblock) {
  display: block;
}

md-toolbar.md-table-toolbar:not(.md-menu-toolbar).md-default {
  background-color: transparent;
  color: $text-base;
  border-radius: 2px;
}

md-toolbar.search-area {
  $search-button-width: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 45px;
  border: solid 1px rgba(0, 0, 0, .12);
  border-radius: 2px;
  width: 200px;
  margin-right: 20px;
  padding: 0;

  div.md-toolbar-tools {
    padding: 0;
    height: 45px;

    md-icon {
      margin-left: 10px;
    }

    input {
      width: calc(100% - 50px);
      padding: 5px 0 5px 15px;
      border-radius: 5px 0 0 5px;
      border: none;
      font-size: .875rem;
      color: $black;
      opacity: .56;
      background-color: transparent;

      &::placeholder {
        color: $black;
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden !important;
        display: inline-block;
      }
    }

    .md-button {
      width: $search-button-width;
      text-align: center;
      background-color: #838383;
      height: 100%;
      min-width: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin: 6px 0;

      i {
        margin: auto;
        color: #FFFFFF;
        font-size: 1.3rem;
      }
    }

    .md-button.clear {
      display: none;
      background-color: transparent;

      i {
        color: $text-base;
      }
    }
  }

  &.not-empty {
    div.md-toolbar-tools {
      input {
        width: calc(100% - 55px);
      }

      .md-button.clear {
        display: block;
      }
    }
  }
}

input[type='search']::-ms-clear {
  display: none;
}

.forum-search {
  position: relative;
  margin: 30px 30px 0;
  md-toolbar.search-area {
    width: 480px;
  }
}
