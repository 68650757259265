// In an actual Vue app with Quasar installed this is done by Quasar itself.
// Here we have to do it manually
:root {
  --q-primary: #275B85;
  --q-secondary: #49B48A;
  --q-accent: #9C27B0;

  --q-dark: #1D1D1D;

  --q-positive: #347F62;
  --q-negative: #C2273D;
  --q-info: #31CCEC;
  --q-warning: #F2C037;
}

:where(.vue-page) {
  @import 'node_modules/@insites/vue-eco-temp-library/dist/vue-eco-temp-library';

  :not([data-v-app]) .flex {
    flex-wrap: unset;
  }
  .q-date__navigation div {
    overflow: visible !important;
  }
  .q-date__months {
    flex-wrap: wrap !important;
  }
}

// We want to show notify on non-vue pages as well.
/* stylelint-disable-next-line selector-max-id */
:where(#q-notify) {
  @import 'node_modules/@insites/vue-eco-temp-library/dist/vue-eco-temp-library';

  /* stylelint-disable-next-line selector-max-id */
  :not([data-v-app]) .flex {
    flex-wrap: unset;
  }
}
