md-input-container :not(label).md-placeholder,
md-input-container.md-default-theme .md-placeholder,
md-input-container.md-default-theme label {
  color: $text-base;
}

md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label,
md-input-container:not(.md-input-invalid).md-input-has-value label,
md-input-container:not(.md-input-invalid).md-input-has-placeholder label,
md-input-container:not(.md-input-invalid) label.isc-rich-input-label {
  color: $text-base;
}

isc-checkbox {
  md-input-container {
    margin-top: 0;
  }
}

isc-rich-input {
  md-input-container {
    margin-top: 30px;
    .isc-rich-input-label {
      padding-bottom: 13px;
    }
  }
}

md-input-container {
  .md-errors-spacer:empty {
    display: none;
  }
  .md-input-messages-animation {
    white-space: normal;
  }
  label {
    font-size: $font-size-small;
  }
}

md-autocomplete.md-no-float md-input-container .md-placeholder,
md-autocomplete.md-no-float md-input-container label:not(.md-no-float):not(.md-container-ignore),
md-autocomplete.md-no-float md-input-container.md-input-focused label:not(.md-no-float),
md-autocomplete.md-no-float md-input-container.md-input-has-placeholder label:not(.md-no-float),
md-autocomplete.md-no-float md-input-container.md-input-has-value label:not(.md-no-float) {
  transform: none;
  transition: none;
  padding-left: 0;
}

isc-dropdown,
isc-tag {
  md-input-container label:not(.md-container-ignore) {
    position: relative;
    bottom: auto;
  }
}

md-input-container .md-placeholder.md-no-float {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  top: inherit;
  padding-left: 0;
}

md-tab-content {
  transform: none;
}

mdp-date-picker,
mdp-time-picker {

  md-input-container {
    margin: 0;
  }

  .md-button.md-icon-button {
    margin: 0;
  }

  .md-required:after {
    //hide asterisk for date pickers that are required
    display: none;
  }
}

md-option {
  .md-text {
    white-space: normal;
  }

  .md-container {
    .md-icon {
      border-width: 1px;
      border-radius: 5px;
      width: 19px;
      height: 19px;
    }
  }

  &[selected] {
    .md-text {
      color: rgba(90, 188, 150, .87);
    }

    .md-container {
      .md-icon {
        background-color: rgba(90, 188, 150, .87);

        &:after {
          border-color: #FFFFFF;
        }
      }
    }
  }
}

// There's a known bug in the font-family 'Open Sans': a tilde (~) becomes a hyphen (-) when the font-size is 13px.
.md-input {
  font-size: .875rem;
}

button.reply-button {
  font-size: .875rem;
}

.md-button {
  text-transform: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: bold;
  font-size: .75rem;

  &.md-raised {
    &:not([disabled]) {
      box-shadow: none;
    }

    &.md-primary {
      color: $white;
    }
  }
}

.md-nav-bar {
  border: 0;

  &:after {
    content: ' ';
    background-color: $dividers;
    position: absolute;
    left: -10vw;
    right: -10vw;
    bottom: 0;
    height: 1px;
  }
}

.md-chips {
  padding: 4px 0;

  md-chip {
    height: 33px;
    line-height: 33px;
    border-radius: 100px;
    background-color: $tag-color;
    color: $black;
    opacity: .87;
    font-family: 'Open Sans';
    font-size: .75rem;
    margin: 0 4px 0 8px;

    .md-chip-remove md-icon {
      height: 16px;
      width: 16px;
      min-height: 16px;
      min-width: 16px;
      color: $black;

      &:hover {
        color: $tag-hover-color;
      }
    }
  }
}

instant-rewards md-toolbar.search-area {
  width: 300px;
}
square-forum-room md-toolbar.search-area {
  width: 500px;
}

.isc-custom-input-label {
  color: $text-base;
}
