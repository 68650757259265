@import '../_colors.scss';

md-content.page-not-found-page {
  text-align: center;

  div.image-top-container {
    margin-top: 20px;
    margin-bottom: 50px;

    img {
      width: 90%;
    }
  }

  img.image-bottom {
    width: 25px;
    margin: margin-inline-values(5px 0 -7px 0);
  }

  h1 {
    color: $primary;
    font-size: 3rem;
    font-family: 'Open Sans';
    font-weight: bold;

    &.title-1 {
      margin-bottom: 0;
    }

    &.title-2 {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 1.3rem;
    color: $black;
  }

  a {
    font-size: 1.3rem;
    text-decoration: underline;
  }

  div.contact {
    margin-top: 100px;
    margin-bottom: 50px;
  }
  @media screen and (min-width: 960px) {
    h1 {
      display: inline;
    }
  }
  @media screen and (min-width: 400px) {
    div.image-top-container img {
      width: auto;
    }
  }
}
