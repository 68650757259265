isc-incentive {
  .incentives-summary {
    color: $insites-green;

    .summary-item {
      md-icon {
        color: $insites-green;
      }
    }
  }

  .timeline-badge {
    .md-button {
      &.md-fab {
        &:not([disabled]):active,
        &:not([disabled]):hover {
          background-color: $human8-dark-teal;
        }

        &.outdated {
          background-color: $white;
          border: 1px solid $square-primary;

          md-icon {
            color: $square-primary;
          }
        }
      }
    }
  }

  .timeline-panel {
    .timeline-title {
      color: $square-primary;
    }
  }
}
