@import '../../../styles/_colors.scss';
@import '../../../styles/base/variables.scss';
@import '../../../styles/base/variables';

.isc-health-filter-default {
  position: fixed;
  top: 63px;
  width: calc(100% - #{$sider-nav-width});
  z-index: 50;
}

.isc-health-filter-top {
  $health-filter-padding-horizontal: 40px;
  background-color: $white;
  width: 100%;
  padding: 15px $health-filter-padding-horizontal 0;
  border-top: solid $border-color-base;
  border-width: 1px 0;

  isc-button {
    margin-right: 0;
  }

  .show-filter {
    color: $square-secondary;
  }

  .align-right {
    text-align: right;
  }

  .filter-space {
    margin-right: 20px;

    isc-dropdown {
      md-input-container {
        display: inline;
      }
    }
  }

  &.sticky {
    transition: linear .1s;
  }

  .fixedElement {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  isc-basic-health-filter {
    display: flex;
  }

  label {
    font-size: .75rem;
    color: $text-base;
    opacity: 1;
  }

  .title {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 24px;
    color: $square-filter-fields-title;
    margin-bottom: 14px;
  }

  .inputs {
    min-width: 42px;
  }

  .filter-show-hide {
    transition: all linear .2s;
  }

  .filter-show-hide.ng-hide {
    opacity: 0;
    height: 0;
  }

  .hide-health-filter {
    padding-bottom: 5px;
    border-top: 1px solid $border-color-base;
    background-color: $white;
    margin: 0 (-$health-filter-padding-horizontal);
    max-width: initial;
  }

  .date-picker {
    md-input-container {
      margin: -4px 0 0;
      padding-bottom: 0;
    }

    input {
      margin-top: 5px;
    }

    button {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      width: auto;
    }
  }

  .tag-select {
    margin-top: -6px;
  }
}

.filter-chips {
  display: flex;
  align-items: center;
  margin-top: -15px;

  &.margin-0 {
    margin: 0;
  }

  .md-chips {
    box-shadow: none;
  }
}

.isc-health-filter-bottom {
  $health-filter-padding-horizontal: 40px;
  background-color: $white;
  width: 100%;
  padding: 15px $health-filter-padding-horizontal 0;
  border-bottom: solid $border-color-base;
  border-width: 1px 0;
  font-size: .875rem;

  isc-button {
    margin-right: 0;
  }

  &.sticky.under-filter-fields {
    top: 80px;
  }

  .show-filter {
    color: $square-secondary;
    font-size: .75rem;
    padding-right: 5px;
  }

  .clear-chips {
    color: $square-secondary;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    font-size: .75rem;

    md-icon {
      color: $square-secondary;
      min-height: 11px !important;
      min-width: 11px !important;
      height: 11px !important;
      width: 11px !important;
      padding-bottom: 2px;
    }
  }

  &.disabled {
    color: $disabled-or-hint-text;

    md-icon {
      color: $disabled-or-hint-text;
    }
  }

  .action-buttons {
    text-align: right;
    margin-top: -9px;
    padding-bottom: 7px;
  }
}
