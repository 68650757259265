isc-incentive {
  .incentives-summary {
    margin-top: 20px;
    margin-bottom: 40px;
    .header-spacer {
      width: 15.5%;
      @include media(gt-xs) {
        width: 12%;
      }
      @include media(sm) {
        width: 14%;
      }
      @include media(md) {
        width: 15%;
      }
      @include media(lg) {
        width: 15.5%;
      }
    }

    .summary-item {
      font-size: .875rem;
      margin: margin-inline-values(0 30px);
      @include media(xs) {
        #{$margin-inline-start}: -3px;
        width: 50%;
      }

      md-icon {
        font-size: 1.4rem;
        #{$margin-inline-end}: 10px;

        &.fa-gift {
          font-size: 2.5rem;
          #{$margin-inline-end}: 15px;
          margin-top: -5px;
        }
      }

      .item-details {
        display: inline-block;
        line-height: 20px;
        font-size: .8rem;

        .layout-column > span:first-of-type {
          font-size: 1rem;
          font-weight: bold;

          &.number-of-credits {
            font-size: 1.4rem;
          }
        }
      }
    }

    &.has-rewards {
      @include media(xs) {
        #{$margin-inline-start}: 5%;
      }

      .summary-item {
        @include media(xs) {
          margin: margin-inline-values(0);
          width: 33.33%;
        }
      }
    }
  }
}
