@import '../../../../styles/_colors.scss';
$member-image-size: 30px;

member-overview {

  .profile-image {
    flex: 0 0 auto;
    border-radius: 100%;
    width: $member-image-size;
    height: $member-image-size;
    float: left;
    margin-right: 10px;
  }

  md-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    margin-right: 16px;
  }

  .member-name {
    padding-top: 7px;
  }

  isc-table-container.with-border {

    md-table-container table.md-table {
      padding-top: 0;
    }

    member-action-bar {
      transition: all .2s;
    }
  }

  table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
  table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
    padding: 0 10px;
  }

  .not-copyable {
    user-select: none;
  }

  table.md-table td.md-cell {

    &.usernameAlign {
      line-height: 31px;
    }

    .dateLastActivationEmail {
      margin: 6px 0;
      white-space: nowrap;
      font-size: .8rem;
    }
  }

  .buttons-help {
    margin-bottom: 15px;
    color: $secondary-text;
    font-style: italic;
    font-size: .875rem;
  }
}
