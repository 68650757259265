@import '../../../styles/_colors.scss';

isc-segments-filter {
  md-input-container.md-icon-left {
    padding-left: 0;

    label {
      left: 0;
    }
  }

  md-input-container {
    width: 200px;
    .dropdown-label {
      font-size: .75rem;
    }
    md-select {
      font-size: .875rem;
    }
  }
}

md-select-header.select-filter-header.segments-filter-header {
  padding-left: 0;
  height: 80px;
  md-radio-button {
    display: inline;
  }

  md-icon {
    margin-right: 10px;
    margin-left: 0;

    svg {
      fill: $breadcrumb-base-color;
    }
  }

  input {
    border: 0;
  }

  .search {
    margin-bottom: -10px;
  }

  .options,
  .search {
    padding-left: 10px;
  }

  .options {
    .any {
      margin-right: 40px;
    }
  }

  .hr-line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #E5E1E1;
    margin: 1em 0;
    padding: 0;
    width: 300px;
  }
}

.md-select-menu-container.md-active.segments-filter-container {
  md-select-menu {
    box-shadow: 1px 3px 10px 2px $box-shadow;
    position: relative;
    top: 145px;
    left: 40px;
    max-height: 600px;
    max-width: 300px;

    md-content {
      max-height: 600px;
      overflow-x: hidden;
    }

    md-optgroup {
      .bold {
        font-weight: bold;
        color: $primary-text;
      }
      label {
        display: flex;

        .test {
          flex-grow: 99;
        }

        .star {
          font-size: 1.1rem;
          flex-grow: 1;
          margin-top: -3px;
        }
      }
    }

    md-option {
      .md-text {
        white-space: normal;
      }

      .md-container {
        .md-icon {
          border-width: 1px;
          border-radius: 5px;
          width: 19px;
          height: 19px;
        }
      }

      &[selected] {
        .md-text {
          color: rgba(90, 188, 150, .87);
        }

        .md-container {
          .md-icon {
            background-color: rgba(90, 188, 150, .87);

            &:after {
              border-color: #FFFFFF;
            }
          }
        }
      }
    }
    md-optgroup[isstarred='true'] label:after {
      float: right;
      display: inline-block;
      font-weight: 900;
      content: '\f005';
      transition: all .25s;
      font-family: fontawesome;
    }
  }
}
