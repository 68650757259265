@import '../../../../styles/_colors.scss';

body {
  isc-activity-data-post,
  isc-forum-post {
    .snippet-highlightable {
      span.snippet-highlighted {
        background-color: $highlight;
        cursor: pointer;
      }
    }
  }
  .highlight-share-popover {
    z-index: 10;
    ul {
      padding: 5px 6px;
    }
    li[data-share-via='highlight'] {
      width: fit-content;
      height: fit-content;
      position: relative;
      a {
        width: 2em;
        &.highlight {
          color: $white;
        }
        &.unhighlight {
          color: $highlight;
        }
      }
    }
    li[data-share-via='copy'] {
      a {
        width: 2em;
      }
    }
  }
}
