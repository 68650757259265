@import '../_colors.scss';

$wizard-background-base: #FAFAFA;
$wizard-background-base-in-dialog: $white;
$wizard-bullet-size: 30px;
$wizard-bullet-small-size: 18px;
$wizard-bullet-text-color: $white;
$wizard-bullet-accent-color: $valid-input-color;
$wizard-bullet-outline-color: $text-base;
$wizard-step-title-color: #000000;
$wizard-step-background: $white;
$wizard-title-background: $white;
$wizard-buttons-background: #F5F5F5;
$wizard-border-radius: 5px;
$wizard-border-color: $light-grey;
$wizard-bullet-font-size: .875rem;
$wizard-bullet-substep-font-size: .8rem;

isc-wizard {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;

  .h4 {
    font-weight: 600;
  }

  &.dialog-display {
    .wizard-step-content {
      border: none;
    }

    .wizard-buttons {
      position: absolute;
      #{$start}: 0;
      #{$end}: 0;
    }

    .progress-container-text {
      border: none;
      #{$padding-inline-start}: 20px;
      padding-top: 0;

      h3 {
        margin-top: 10px;
        font-weight: 600;
      }
    }
  }

  .progress-container-text {
    background-color: $wizard-title-background;
    #{$border-top-start-radius}: $wizard-border-radius;
    #{$border-top-end-radius}: $wizard-border-radius;
    padding: padding-inline-values(15px 30px);
    border: 1px solid $wizard-border-color;
    color: $wizard-step-title-color;

    h3 {
      font-size: 1.1rem;
      margin: margin-inline-values(10px 0);
      font-weight: 600;
    }
  }

  .progress-text {
    margin: margin-inline-values(0 10px);
    font-weight: bolder;
    font-size: $wizard-bullet-font-size;
    max-width: 160px;

    &.current {
      color: $wizard-bullet-accent-color;

      &.normal {
        font-weight: normal;
      }
    }

    &.complete {
      font-weight: normal;

      &:hover {
        color: $wizard-bullet-accent-color;
        font-weight: bolder;
      }
    }

    &.next {
      font-weight: normal;

      &:hover {
        color: $wizard-bullet-accent-color;
        font-weight: bolder;
      }
    }

    &.incomplete {
      cursor: default;
      font-weight: 600;
    }
  }

  .progress-subitem-container {
    width: $wizard-bullet-size;
    height: $wizard-bullet-small-size;
  }

  .progress-bullet {
    width: $wizard-bullet-size;
    height: $wizard-bullet-size;
    line-height: $wizard-bullet-size - 3;
    border-radius: border-inline-radius-values(50%);
    color: $wizard-bullet-text-color;
    text-align: center;
    background-color: $wizard-background-base;
    font-size: 1.1rem;

    md-dialog & {
      background-color: $wizard-background-base-in-dialog;
    }

    &.small {
      width: $wizard-bullet-small-size;
      height: $wizard-bullet-small-size;
    }

    &.current {
      color: $wizard-bullet-accent-color;
      border: 1px solid $wizard-bullet-accent-color;
    }

    &.complete {
      background-color: $wizard-bullet-accent-color;

      svg {
        stroke: $wizard-bullet-text-color;
        width: 15.6px;
        height: 13.2px;
        #{$margin-inline-start}: 2px;
        margin-bottom: -2px;
      }

      &.current {
        color: #F5F5F5;
      }

      &.next {
        color: #F5F5F5;
      }
    }

    &.next {
      color: $wizard-bullet-outline-color;
      border: 1px solid $wizard-bullet-outline-color;
    }

    &.incomplete {
      color: $wizard-bullet-outline-color;
      border: 1px solid $wizard-bullet-outline-color;
      cursor: default;
      // merge:
      &.current {
        color: $wizard-bullet-accent-color;
        border: 1px solid $wizard-bullet-accent-color;
      }
    }
  }

  .progress-item {
    margin: margin-inline-values(10px);
    z-index: 1;
  }

  .wizard-header {
    position: sticky;
    top: 0;
    min-width: 230px;

    &:before {
      content: '';
      position: absolute;
      height: calc(100% - 50px);
      #{$margin-inline-start}: 25px;
      margin-top: 25px;
      #{$border-inline-start}: 1px solid $wizard-bullet-outline-color;
    }
  }

  .wizard-content {
    background: $wizard-background-base;
    border-radius: border-inline-radius-values(8px);
    min-height: 80%;
  }

  .wizard-step-content {
    #{$border-inline-start}: 1px solid $wizard-border-color;
    #{$border-inline-end}: 1px solid $wizard-border-color;
    background: $wizard-step-background;
    overflow: auto;
  }

  .progress-substeps-container {
    margin-top: 20px;

    > :nth-child(2) {
      margin-top: 14px;
    }

    .progress-text {
      font-size: $wizard-bullet-substep-font-size;
    }
  }

  .wizard-buttons {
    background: $wizard-buttons-background;
    border-bottom-#{$start}-radius: $wizard-border-radius;
    border-bottom-#{$end}-radius: $wizard-border-radius;
    border: 1px solid $wizard-border-color;
    padding: padding-inline-values(10px 30px);
    // merge: padding: 10px 10px;
  }

  .next-description {
    vertical-align: middle;
    margin: margin-inline-values(0 20px);
    font-style: italic;
    font-size: .875rem;
  }

  isc-wizard-step {
    flex: 0 1 auto;
    flex-direction: column;
    display: flex;
    .tooltip-container.tooltip-title {
      position: relative;
      top: 9px;
      #{$start}: 10px;
    }
  }
}
