@import '../../../../styles/_colors.scss';

saving-up-rewards {
  label.section-title {
    color: $secondary-text;
    font-weight: bold;
    display: block;
  }

  isc-table-container table.md-table {
    width: auto;

    thead.md-head tr.md-row th {
      border: none;
    }
  }

  .flex-container {
    display: flex;
    flex-direction: row;
  }

  .configuration {
    flex-grow: 1;
    margin-right: 50px;
  }

  .overview {
    flex-grow: 2;
  }

  .bold {
    font-weight: bold;
  }

  .automaticRewardsDisabled {
    color: $warning-message;
  }
}
