@import '../../../styles/base/_colors.scss';
@import '../../../styles/_colors.scss';

.isc-member-filter-top {
  $activity-filter-padding-horizontal: 30px;
  background-color: $white;
  width: 100%;
  padding: 15px $activity-filter-padding-horizontal 0;
  border-top: solid $border-color-base;
  border-width: 1px 0;

  &.sticky {
    transition: linear .1s;
  }

  md-input-container {
    margin: 21px 0 !important;
  }

  isc-input md-input-container:not(.md-input-invalid).md-input-focused label {
    color: #757575 !important;
  }

  isc-date-range {
    label {
      flex: 1 1 auto;
    }
  }

  label {
    font-size: .75rem;
    color: $square-filter-fields-label;
  }

  .divider {
    margin-right: 20px;

    isc-dropdown {
      md-input-container {
        display: inline;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 24px;
    color: $square-filter-fields-title;
    margin-bottom: 14px;
  }

  .inputs {
    min-width: 42px;
  }

  .filter-show-hide {
    transition: all linear .2s;
  }

  .filter-show-hide.ng-hide {
    opacity: 0;
    height: 0;
  }

  .hide-member-filter {
    padding-bottom: 5px;
    border: 1px solid $border-color-base;
    background-color: $alabaster;
    margin: 0 (-$activity-filter-padding-horizontal);
    max-width: initial;
  }

  .date-picker {
    md-input-container {
      margin: 0 0 0 16px !important;
      padding-bottom: 0;
      padding-top: 1px;
    }

    button {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      width: auto;
    }
  }
}
.isc-member-filter-bottom {
  font-size: .875rem;
  $activity-filter-padding-horizontal: 30px;
  background-color: $white;
  width: 100%;
  padding: 15px $activity-filter-padding-horizontal 0;
  border-bottom: solid $border-color-base;
  border-width: 1px 0;

  isc-button {
    margin-right: 0;
  }

  .filter-chips {
    display: flex;
    align-items: center;
    margin-top: -15px;

    .md-chips {
      box-shadow: none;
      font-size: .75rem;
    }
  }
  &.sticky.under-filter-fields {
    top: 80px;
  }

  .show-filter {
    color: $square-secondary;
    font-size: .75rem;
    padding-right: 5px;
  }

  .clear-chips {
    color: $square-secondary;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    font-size: .75rem;

    md-icon {
      color: $square-secondary;
      min-height: 11px !important;
      min-width: 11px !important;
      height: 11px !important;
      width: 11px !important;
      padding-bottom: 2px;
    }
  }

  &.disabled {
    color: $disabled-or-hint-text;

    md-icon {
      color: $disabled-or-hint-text;
    }
  }
  .action-buttons {
    text-align: right;
    margin-top: -9px;
    padding-bottom: 7px;
  }
}
