@import '../../../../styles/_colors.scss';

target-completion {
  md-input-container {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;

    .md-input {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  isc-input + span {
    &.tooltip-container {
      height: auto;
      top: 0;
    }
  }

  .completion-target-tooltip {
    line-height: 16px;
    padding: 4px 0;
  }

  .activity-target-completion-wrapper {
    .segment-selector {
      margin-top: 40px;
      margin-bottom: 20px;

      delete-button {
        margin-left: 15px;
      }
    }

    isc-table-container.with-border md-table-container {
      border-collapse: collapse;
      padding: 20px 30px;
      border-radius: 0;

      .total-row {
        color: $light-grey;
        border-top: 1px solid $border-grey;
      }
    }
  }

  .add-segmentation {
    margin-top: 40px;

    isc-button {
      button {
        padding-top: 2px;
        padding-left: 0;
        isc-button-icon {
          margin-bottom: 5px;

          span {
            font-size: .875rem;
          }
        }
      }
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
  }

  tr > :first-child {
    width: 35%;
  }

  .total-tooltip > * {
    display: inline-block;// Stops tooltip from jumping to next line
  }

  .tooltip-container {
    position: relative;

    > span.tooltip {
      position: absolute;
      bottom: 8px;
    }
  }

  .red {
    color: #FF0000;
  }

  tr.md-row.no-hover:hover {
    background-color: inherit !important;
  }
}
