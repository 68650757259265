﻿@import './_colors.scss';

.buttons {
  padding: 10px 30px;
  background-color: $isc-grey;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  isc-button {
    margin-right: 4px;
  }
}

button:not(.q-btn):disabled {
  cursor: not-allowed;
}

.typcn-tick {
  pointer-events: none;
}

.typcn-tick:before {
  font-size: 1.2rem;
  margin-right: 5px;
}

.last-bullet:before {
  font-size: .875rem;
  margin-right: 0;
}

.md-icon-button {
  font-size: 1.3rem;
}

.researcherPictureInput {
  position: relative;
  overflow: hidden;
  margin: 10px;
}

.researcherPictureInput input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.tabPageButtons {
  margin: 10px 0;
  border: 1px solid #DDDDDD;
}

.has-margin {
  margin-right: 10px;
}

.primary-add {
  margin: 10px 0 35px;
}

.inlineRadioGroup {
  md-radio-button {
    display: inline-block;
    margin-right: 20px;
  }
}

.last-button {
  margin-right: 0;
}
