
@import './../media-mixin';
@import './../colors';

isc-probequestion-editor {
  color: $black;

  isc-forum-post {
    &.top-post {
      margin-top: 30px;
      border: 0;
    }

    &.probing-question {
      background-color: transparent;
    }

    &.scout-conversation {
      max-width: 614px;
      margin: margin-inline-values(0 auto) !important;
    }
  }

  .optional-text {
    font-style: italic;
    font-size: .8rem;
    color: $secondary-text;
    #{$margin-inline-start}: 5px;
    word-break: keep-all;
  }

  md-input-container {
    margin-bottom: 0;
  }

  div.forum-message-text {
    display: flex;

    :first-child {
      margin-top: 0 !important;
    }
  }

  .nw-editor {
    height: auto;
    padding: padding-inline-values(0);
    min-height: auto;

    & .nw-editor .nw-editor__res {
      min-height: auto;
    }

    .nw-editor__res {
      min-height: 100px;
      display: block;
      transition: min-height .5s ease;
      padding: padding-inline-values(10px 0 5px 5px);

      &:focus {
        min-height: 200px;

        &:before {
          content: '';
          display: inline;
        }
      }
    }
  }

  isc-caption-editor {
    .nw-editor {
      .nw-editor__res {
        &:focus {
          min-height: initial;
        }
      }
    }
  }

  isc-post-footer {
    width: 100%;
  }

  .attachments {
    float: $start;
    width: 100%;
    margin-top: 7px;
  }

  // *********** Show more **************
  .show-more-container {
    overflow: hidden;
    flex: 1;
  }

  div[show-more] + div {
    > .attachments {
      margin-bottom: 13px;
      margin-top: 5px;
    }
    > .stimuli-item {
      margin-bottom: 13px;
      margin-top: 5px;
    }
    > md-input-container {
      margin-bottom: 3px;
    }
  }

  .show-more-collapse,
  .show-more-expand {
    text-align: center;
    display: none;
  }

  .show-more-expanded > .show-more-collapse,
  .show-more-collapsed > .show-more-expand {
    display: inherit;
  }

  a.show-more-expand,
  a.show-more-collapse {
    text-decoration: none;
    color: $disabled-or-hint-text;
    cursor: pointer;
  }

  a.show-more-collapse {
    align-items: flex-end;
  }

  // **************************************

  // ********* Slide Animation ************
  isc-forum-post.slide-in {
    animation: slide-in .75s ease-in forwards;
  }
  @keyframes slide-in {
    from {
      transform: translateY(75px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  // **************************************
}
