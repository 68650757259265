@import '../../../../styles/_colors.scss';

div.right {
  float: right;
}

div.result-segment {
  float: left;
  text-align: center;
  .h4 {
    margin-bottom: 0;
    padding: 0 2px;
    &.highlight {
      color: $insites-green;
    }
  }
  span {
    font-size: .5rem;
    font-weight: bold;
  }
}
