@import '../../_colors.scss';
// tag-container sizes
$tag-small: 90px;
$tag-medium: 250px;
$tag-medium-large: 350px;
$tag-large: 454px;

isc-tag-input {
  md-input-container:not(.md-input-invalid).md-input-focused {
    :after,
    label {
      color: $tag-valid;
    }

    .md-focused {
      box-shadow: 0 2px $tag-valid;
    }
  }

  .md-chips {
    md-chip {
      height: 33px;
      line-height: 33px;
      border-radius: border-inline-radius-values(100px);
      background-color: $tag-color;
      color: $black;
      opacity: .87;
      font-family: 'Open Sans';
      font-size: .8rem;

      .md-chip-content {
        .typcn-star-full-outline {
          color: $tag-starred;
        }
      }

      .md-chip-remove {
        height: 33px;

        md-icon {
          height: 16px;
          width: 16px;
          min-height: 16px;
          min-width: 16px;
          color: $black;

          &:hover {
            color: $tag-hover-color;
          }
        }
      }
    }
  }

  md-chips .md-chips.md-focused {
    box-shadow: 0 2px $tag-hover-color;
  }

  isc-tag-errors {
    font-size: .8rem;
  }

  .requiredLabel:after {
    content: ' *';
    font-size: .8rem;
    vertical-align: top;
  }

  &.small-size {
    > md-input-container {
      width: $tag-small;
    }
  }

  &.medium-small-size {
    > md-input-container {
      width: $tag-medium;
    }
  }

  &.medium-size {
    > md-input-container {
      width: $tag-medium-large;
    }
  }

  &.large-size {
    > md-input-container {
      width: $tag-large;
    }
  }
}
