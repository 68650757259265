﻿.highlighted {
  color: $insites-orange;
}

.segmentation-length {
  color: $insites-red;
  font-weight: bolder;
  padding: 10px;
  width: 100%;
}

.segmentations-list .segmentations-action-col {
  display: flex;
  align-items: center;
  height: inherit;
}

.segmentation-dialog-content {
  font-size: .8rem;
  min-width: 1000px;

  .tree-actions {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .segmentation-dialog-search {
    min-width: 800px;
  }

  .md-select-value {
    border-bottom-width: 0;
    border-bottom-style: none;
  }

  .dialog-content-container {
    padding: 15px;
    width: 100%;
    overflow: auto;
    max-height: 850px;
  }

  .cleanup-button {
    border: 1px solid $insites-orange;
    border-radius: 5px;
    height: 32px;
  }

  isc-button-icon i {
    font-size: 1.3rem;
    padding-left: 3px;
    padding-right: 3px;
  }

  .segment-treeview {
    overflow: auto;
    color: $text-base;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    background: $white;

    .ivh-treeview-node-label {
      font-weight: bold;
      cursor: pointer;
    }

    .ivh-treeview-node-leaf .ivh-treeview-node-label {
      font-weight: normal;
      cursor: default;
    }

    .ivh-treeview .ivh-treeview div.ng-scope > span[ivh-treeview-checkbox] {
      display: inline;
    }
  }

  .segment-source-container {
    display: flex;

    .segment-source-select {
      margin-left: 0;

      md-input-container {
        margin-top: 0;
        padding: 0;
      }

      md-select {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid #DDDDDD;
        background: #DDDDDD;
        width: auto;
        height: 47px;
        font-weight: bolder;
        text-transform: uppercase;
        color: $text-base;
        padding-left: 5px;
        margin: 0;
      }
    }
  }

  li,
  ul {
    list-style-type: none;
    padding-left: -10px;
  }

  .ivh-treeview .ivh-treeview-toggle:not(.ivh-treeview-node-label) {
    cursor: pointer;
    font-weight: bolder;
    font-size: 1rem;
    top: -1px;
    position: relative;
    right: 1px;
  }

  .ivh-treeview-node-content {
    cursor: pointer;
    position: relative;
  }

  .searching {
    .ivh-treeview-node.ng-hide .ivh-treeview-node-label {
      color: $black;
    }

    .ivh-treeview-node-leaf {
      color: #FFA500;
    }
  }

  .segmentation-container {
    border-radius: 5px;
    border: solid 1px $dividers;
    margin-top: 20px;
    padding: 10px;

    .segmentation-label {
      padding: 10px 0;
      margin: 10px 0;
      border: none;
      color: $black-50;
      font-size: .875rem;
      font-weight: bold;
      line-height: 40px;

      > div {
        width: 100%;
      }
    }

    isc-input {
      width: 100%;

      md-input-container {
        width: 100%;
        margin: 0;
      }
    }
  }

  isc-table-container {
    table.md-table tbody.md-body tr.md-row td.md-cell {
      &.segmentations-action-col {
        min-width: 60px;
        width: 60px;
        padding: 0;
      }
      &.lastupdate {
        min-width: 300px;
      }
      &.members-col {
        min-width: 130px;
      }
      &.segment-label {
        width: 100%;

        span {
          display: block;

          i {
            font-size: .8rem;
          }
        }
      }
      isc-button {
        float: left;
      }
    }
  }

  textarea {
    min-height: 100px;
    width: 100%;
    max-width: 100%;
    border: solid 1px $dividers;
    margin: 0;
  }
}
