div.add-reward isc-button button {
  padding: 0;
}
.currency-tooltip {
  display: flex;
  align-items: center;
}

.tooltip-container {
  position: relative;

  > span.tooltip {
    position: absolute;
    bottom: 8px;
    left: 2px;
  }
}
