periodic-rewards,
instant-rewards,
manual-rewards {
  isc-table-container.with-border md-table-container table.md-table {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    padding: 0;
  }

  .first-column {
    margin-left: 22px;
    &-header {
      margin-left: 30px;
    }
  }
}
