client-maintenance {
  .cogs {
    width: 400px;
    height: 350px;
    margin: 70px;
  }

  .cog {
    fill: #8C8C8C;
  }
}
