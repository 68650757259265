﻿.overview-tables {
  table.md-table {
    width: 300px;
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 20px;

    thead.md-head > tr.md-row {
      background-color: $table-grey;
    }

    tbody.md-body > tr.md-row {
      background-color: $white;
    }
  }

  table.md-table:not(.md-row-select) th.md-column:first-child {
    width: 300px;
  }
}
