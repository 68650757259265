﻿$tab-height: 50px;

.infoPage.tabLayout {
  padding: 0;
  md-input-container {
    position: sticky;
  }

  .md-tab {
    .deleted-page {
      opacity: .2;
    }
  }

  .selectIcon {
    font-size: .8rem;
    padding: 20px;
    display: inline-block;
  }

  .description-error {
    color: #F32651;
  }

  div.description-error {
    font-size: .8rem;
    padding-top: 5px;
  }

  md-tab-content .page-content {
    .selectIcon {
      margin-top: 18px;
      border: solid 1px rgba($black, .12);
      width: auto;
      height: auto;
      border-radius: 5px;

      &:focus {
        border: 1px solid $insites-orange;
      }

      md-icon {
        line-height: 50px;
        font-size: 3rem;
        width: 50px;
        height: 50px;
        color: rgba($black, .87);
      }
    }

    .selectColorDivContainer {
      display: inline-block;
      margin: 15px 0 25px;

      .selectColorDiv {
        display: inline-block;
        border: 1px solid #DDDDDD;
        margin: 0 20px 5px 0;

        &.borderError {
          border-color: #FF0000;
        }

        .md-color-picker-input-container {
          display: inline;

          .md-color-picker-preview {
            border-radius: 0;
            width: 120px;
            height: 120px;
            box-shadow: none;
            position: static;
            margin: 5px 5px 0;
            border: none;
          }

          md-input-container {
            margin: 3px 2px 5px;
            position: relative;

            .md-color-picker-input {
              width: 120px;
            }
          }
        }

        .selectColor {
          display: inline-block;
          width: 122px;
          min-height: 122px;
          border: 1px solid #DDDDDD;
          border-radius: 0;
          margin: 5px 5px 3px;
        }

        .selectIcon {
          width: 122px;
          min-height: 122px;
          border-radius: 0;
          background-color: #808080;
          border: 1px solid #DDDDDD;
          margin: 5px 5px 3px;
        }
      }
    }

    .description-error {
      color: #F32651;
    }

    div.description-error {
      font-size: .8rem;
      padding-top: 5px;
    }
  }
}

.designthumb {
  width: 200px;
  text-align: center;
  margin: 0 15px 15px 0;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  display: inline-block;

  div {
    height: 130px;
    background-color: #F2F2F2;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &.mobile {
      background-size: contain;
    }
  }

  &:hover .on-hover {
    display: block;
  }

  .on-hover {
    background: rgba(255, 255, 255, .95);
    display: none;

    span {
      width: 100%;
      text-align: center;
      display: block;
      padding-top: 20px;
    }
  }

  .label {
    padding-top: 5px;
    font-size: .6rem;
  }

  .focus-label {
    color: $insites-orange;
    margin: 0 10px;
  }
}

.error-message {
  font-size: small;
  color: #FF0000;
}

.inline-block {
  display: inline-block;
}

.designPage {
  .title {
    color: rgba(0, 0, 0, .54);
    font-weight: bold;
    font-size: .875rem;
  }

  .subtitle {
    color: rgba(0, 0, 0, .38);
    font-weight: normal;
    font-size: .8rem;
    margin-bottom: 10px;
  }

  &.infoPage.tabLayout md-tab-content .page-content {
    padding-bottom: 50px;
  }
}

.all-members-placeholder {
  margin: -25px 0 0;
}
