$standard-transition-length: .2s;

// Labels
$label-small: .563rem !default;
$label-medium: .688rem !default;
$label-default: .75rem !default;
$label-medium-large: .875rem !default;
$label-large: 1rem !default;

// Buttons
$button-small-height: 36px;
$button-small-font-size: .75rem;
$button-big-height: 45px;
$button-big-font-size: .875rem;
$icon-button-size: 28px;
$icon-button-icon-size: 17px;
$radio-button-font-size: .875rem;
$radio-button-size: 18px;
$icon-button-large-size: 100px;
$icon-button-large-icon-size: 40px;
$icon-button-size-extra-small: 5px;

//Cards
$card-height: 490px;
$card-lines-to-show: 6;
$card-line-height: 1.4;
$card-text-size: $label-medium-large;
$card-todo-border: 6px;
$card-content-padding-vertical: 20px;
$card-content-padding-vertical-mobile: 15px;
$card-content-padding-horizontal-mobile: 20px;

//media recorder
$isc-media-recorder-icons-size: 24px;

// font-awesome/fontawesome
// Update this when updating font awesome to a newer version
// This is needed because we set the icon in a css file
$fa-font-family: 'Font Awesome 6 Pro';
$fa-caret-up: '\f0d8';
$fa-caret-down: '\f0d7';
