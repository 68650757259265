@import '../../../styles/_colors.scss';

loginerror {
  .wrapper {
    padding: 0 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $black;
    background-color: $white;
    height: 100%;
  }

  .main {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-top: 100px;
  }

  .main-text {
    margin-top: 35px;
    margin-left: 65px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .main-text h1,
  .main-text .h1 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
    margin-bottom: 20px;
  }

  .main-text .body {
    font-size: 1.3rem;
    margin-bottom: 50px;
  }

  .main-text .body p {
    margin: 0;
  }

  .main-text .body a {
    color: inherit;
    text-decoration: underline;
  }
  @media only screen and (max-width: 1000px) {
    .wrapper {
      padding: 0;
    }
    .main-text h1,
    .main-text .h1 {
      font-size: 1.875rem;
      margin-bottom: 15px;
    }
    .main-text .body {
      font-size: 1.1rem;
      margin-bottom: 0;
    }
    .main {
      flex-direction: column;
      align-items: center;
      padding: 10px;
      word-break: break-all;
    }
    .main-text {
      margin-left: 0;
    }
  }
}
