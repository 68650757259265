$silver-tree-green: #347F62;
$froly: #F06F7E;
$st-tropaz-blue: #275B85;
$amaranth: #C2273D;
$cream-can-yellow: #F3D558;
$alabaster: #FAFAFA;
$wild-sand: #F5F5F5;
$black: #000000;
$white: #FFFFFF;
$dark-grey: #595959;
$light-grey: #757575;
$menu-color: #DEDEDE;
$human8-dark-teal: #004750;
$sidenav-active-color: #EBF0F1;

$primary-color: $silver-tree-green;
$secondary-color: $amaranth;
$error-color: $froly;
$valid-input-color: $silver-tree-green;
$disabled-color: $wild-sand;
$warning-color: $cream-can-yellow;
$primary-button-color: $human8-dark-teal;
$secondary-button-color: $froly;
$sidenav-color: $human8-dark-teal;
