@import '../../../styles/_colors.scss';

activity-data-component {
  .arrow-box {
    position: relative;
    background: $arrow-box-background;
    border: 2px solid $arrow-box-border;
    padding: 5px;
  }

  .admin-intf-card-container {
    background-color: $white;
  }

  .arrow-box:after,
  .arrow-box:before {
    right: 100%;
    top: 15px;
    border: solid transparent;
    content: ' ';
    position: absolute;
  }

  .arrow-box:after {
    border-right-color: #F0F3F5;
    border-width: 15px;
    margin-top: -15px;
  }

  .arrow-box:before {
    border-right-color: #E7E9E9;
    border-width: 17px;
    margin-top: -17px;
  }

  .icon-button .md-button {
    .inactive {
      color: $disabled-or-hint-text;
    }
  }

  .data-moderation {
    padding: 0 0 0 5px;
    max-height: 100%;
    overflow: hidden;
    position: absolute;

    &:hover {
      z-index: 5;
    }

    .notes,
    .tags {
      display: none;
      opacity: .8;
    }

    &.on-top-note,
    &.on-top-tag {
      position: unset;
    }

    &.on-top-note .notes,
    &.on-top-tag .tags,
    .notes:hover,
    .tags:hover {
      display: block;
      opacity: 1;
    }

    .arrow-box {
      margin-left: 16px;
    }

    &.notes-filter .notes,
    &.tags-filter .tags {
      display: block;
      max-height: 100%;
    }

    &.on-top-note.tags-filter .tags,
    &.on-top-tag.notes-filter .notes {
      opacity: 1;
    }

    &.notes-filter.tags-filter .tags {
      max-height: 50%;
      overflow: hidden;
      height: auto;
    }

    .note {
      &.note-list-item {
        border-bottom: 2px solid #E7E9E9;

        &.no-border {
          border: 0;
        }
      }

      b,
      span.note-text {
        width: calc(100% - 60px);
        white-space: pre-wrap;
        word-break: break-all;
      }

      textarea {
        height: auto;
        width: 100%;
        max-width: 100%;
        border: none;
        margin: 0;
      }
    }

    md-chips-wrap {
      background-color: #FFFFFF;
      box-shadow: none;

      md-chip {
        display: table;

        span {
          white-space: initial;
          word-break: break-all;
        }
      }
    }
  }

  .convPagination {
    overflow: visible;
  }

  isc-button.go-back {
    margin-top: 5px;

    .md-button {
      color: $insites-green;
      padding: 0;

      .icon {
        margin-right: 0;

        i {
          font-weight: bold;
        }
      }

      .label {
        font-size: 1rem;
      }
    }
  }

  isc-conversation-editor isc-save-as-draft-message {
    margin-top: 12px;
  }

  .page-title {
    padding-top: 0;
    height: initial;
  }

  .edit-forum-post {
    display: block;
    padding: 0 20px 20px;
  }

  export-data-component {
    margin-bottom: 6px;
  }

  isc-activity-data-post {
    .stimuli-item {
      position: relative;

      isc-stimuli-download i {
        position: absolute;
        color: #FFFFFF;
        top: 6px;
        right: 3px;
        z-index: 2;
        text-shadow: -1px 0 $black, 0 1px $black, 1px 0 $black, 0 -1px $black;
        visibility: hidden;
      }
    }

    .stimuli-item:hover isc-stimuli-download i {
      visibility: visible;
    }

    table.md-table {
      margin-bottom: 20px;

      thead.md-head tr.md-row {
        height: 30px !important;

        th.md-column {
          font-weight: 600;
          color: $primary-text;
        }
      }

      tbody.md-body tr.md-row {
        height: 30px;
        border-bottom: 1px solid rgba($black, .12);

        td.md-cell {
          font-size: .8rem;
        }
      }
    }
  }

  .caption-disabled {
    clear: both;
    font-size: .8rem;
    font-weight: bold;
  }

  .border-bottom {
    padding-top: 30px;
    border-bottom: 1px solid $border-grey;
    margin-left: 10px;
  }

  .probequestions-container {
    md-input-container {
      margin-top: 15px;
    }

    &.disabled {
      border-top: none;

      div.border-bottom {
        display: none;
      }

      md-input-container {
        margin-top: 0;
      }

      div.nw-editor__res {
        height: auto;
      }

      isc-forum-post {
        margin-top: 0;
      }
    }
  }

  .submit-answer-button {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .probequestions-container.questions-under-text {
    padding-left: 0;
    border-top: 1px solid $border-grey;
  }

  isc-post-footer.flex-custom-order {
    display: flex;
    flex-direction: column;

    isc-probe-questions-answer-editor {
      order: 1;
    }

    footer {
      order: 2;
    }

    div.border-bottom.no-margin {
      order: 3;
    }

    div.reply-type-picker {
      order: 4;
    }

    isc-conversation-editor {
      order: 5;
    }

    isc-save-as-draft-message {
      order: 6;
    }
  }

  .saved-as-draft-message {
    color: $disabled-or-hint-text;
    font-size: .8rem;
    margin-left: 30px;
  }

  .no-margin {
    margin: 0;
  }

  div.border-bottom.no-margin {
    display: block;
  }

  div.reply-type-picker {
    background-color: $forum-topic-background;
    border: 1px solid $border-color-base;
    font-size: .8rem;
    font-weight: bold;

    &.active {
      border: 2px solid $insites-green;
      margin-bottom: 20px;
    }

    md-switch {
      color: $black;
      margin: 8px 0 8px 20px;
    }
  }

  .isc-input-label {
    font-size: .8rem;
    color: $text-base;
  }

  isc-probe-question-overview {
    color: $black;
  }

  toggleable {
    display: block;

    .more-button {
      height: 40px;
      margin: 0;
      width: 100%;
      text-align: left;
      padding: 0;
    }

    &.visual-focused-conversation {
      .expander {
        max-width: 614px;
        margin: 0 auto;
      }

      .more-button {
        padding: 0;
        text-align: center;
      }
    }

    &.scout-conversation {
      max-width: 614px;
      margin: 0 auto;
    }

    &.toggle-hidden .expander {
      header {
        display: none;
      }

      section.isCollapsed {
        margin-top: 0;
      }
    }

    .expander {
      header {
        border-bottom: none;
        border-top: 1px solid $border-color-base;
        line-height: 1;
      }

      section.isCollapsed {
        display: none;
      }

      section.notCollapsed {
        margin-top: 0;

        isc-probequestion-editor:first-of-type isc-forum-post {
          margin: 0;
        }
      }
    }
  }

  .hashtag {
    color: #337AB7;
    cursor: pointer;
  }

  .discussion-detail-view {
    background-color: $white;
  }
}
