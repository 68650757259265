@import '../_colors.scss';
@import '../variables.scss';

isc-banner {
  md-card {
    border-radius: border-inline-radius-values(5px);
    border: solid 1px $lighter-grey;
    box-shadow: none !important;
    margin: margin-inline-values(10px);

    md-card-content {
      padding: padding-inline-values(5px);
    }
  }
}
