@import '../_colors.scss';
@import '../_variables.scss';

isc-conversation-sort-options {
  .sort-label {
    font-size: $label-default;
    color: $text-base;
    #{$margin-inline-end}: 10px;
  }

  .room-name {
    font-size: $label-default;
  }

  isc-checkbox.group-by-member md-input-container {
    margin: margin-inline-values(0);

    md-checkbox {
      #{$start}: auto;
      #{$end}: 0;
      margin-bottom: 0;

      .md-label {
        font-size: $label-default;
        #{$margin-inline-start}: 0;
        #{$margin-inline-end}: 30px;
        margin-top: 0;
        color: $disabled-or-hint-text;
      }

      .md-container {
        #{$end}: 0;
        #{$start}: auto;
        margin: margin-inline-values(2px);
        top: 9px;

        .md-icon {
          width: 16px;
          height: 16px;

          &:after {
            width: $icon-button-size-extra-small;
            height: $label-medium;
          }
        }
      }
    }
  }
}
