﻿@import './_colors.scss';

.top-title-login {
  height: calc($menu-bar-height / 2);
  background-color: $square-secondary;
  min-height: calc($menu-bar-height / 2);
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: $white;
  -webkit-font-smoothing: antialiased;

  .breadcrumb-toggle {
    position: absolute;
    color: $white;
    left: 0;
    top: 0;
    font-size: 1.1rem;
  }

  .switch-interfaces {
    font-size: 1.8rem;
    margin-top: -8px;

    button {
      md-icon {
        color: $white;
        position: relative;
        bottom: 2px;
      }

      &[disabled] md-icon {
        color: $light-grey;
      }
    }

    i.fa {
      cursor: pointer;

      &[disabled] {
        color: $light-grey;
        cursor: inherit;
      }
    }
  }

  .title {
    display: inline;
    position: relative;
    top: 4px;
  }
}

div.md-open-menu-container {
  z-index: 1001;
  overflow: auto;

  &:before {
    content: '\A';
    position: absolute;
    right: 7px;
    top: -15px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid $white;
  }

  md-menu-content {
    overflow: inherit;
    border-radius: 3px;
    font-size: .8rem;

    md-menu-divider {
      //box-shadow: 0 6px 13px 0 $black;
    }

    md-menu-item {
      min-height: 35px;
      height: 35px;

      &.menuLink:hover {
        background-color: $hover-grey;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      .md-button {
        position: relative;
        font-size: .8rem;
        &:focus {
          background-color: transparent;
        }

        &:hover {
          background-color: $hover-grey;
        }

        img.profile-image {
          margin-right: 5px;
          height: 25px;
          width: 25px;
          border-radius: 50%;
        }

        .with-check {
          img {
            margin-left: 23px;
          }
        }

        .green-check-icon {
          color: $insites-green;
          position: absolute;
          min-width: 13px;
          min-height: 13px;
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}

md-backdrop.md-menu-backdrop {
  z-index: 1000;
}
