﻿.activity-config {
  .remove-margin {
    margin-left: -10px;
  }

  .inputField,
  .inputField > md-input-container {
    width: 454px;
  }

  .timespan-message {
    padding-bottom: 40px;

    .error-message {
      color: $insites-red;
    }
  }

  .fixed-width {
    width: 454px;
  }
  //added this for IE/FF
  mdp-time-picker {
    md-icon {
      border: none;
      color: rgba(0, 0, 0, .54);
    }
  }

  mdp-date-picker {
    md-icon {
      border: none;
      color: rgba(0, 0, 0, .54);
    }

    div md-input-container input {
      width: 200px;
    }
  }

  .margin-top {
    margin-top: 25px;
  }

  .small-label {
    font-size: .8rem;
  }

  .complete-criteria {
    width: 100%;

    &.valid-input {
      color: $valid-input-color;

      input {
        color: $valid-input-color;
      }
    }

    isc-checkbox {
      float: left;

      md-input-container {
        width: 10px;
        height: 10px;
        margin: 6px 0 0;
      }
    }

    md-input-container {
      margin: 0;
      height: 40px;
    }
    span {
      padding: 5px;
      display: inline-block;
      line-height: 26px;
    }
  }
}

isc-reward .rewards {
  padding: 10px 0;

  label {
    font-size: .8rem;

    &.extra-label {
      font-size: .5rem;
      margin-bottom: 0;
      margin-top: 3px;
    }
  }

  span {
    margin-top: 26px;

    &.md-select-icon {
      margin-top: 26px;
    }
  }

  md-input-container {
    width: 70px;
    margin: 0;
    padding: 0;
    font-size: 1rem;

    .md-errors-spacer {
      display: none;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .rewards-layout {
    width: 180px;
    margin: 0;
  }

  md-select {
    margin-top: -15px;
    margin-bottom: 0;
  }
}

activity-publish,
activity-qual-publish {
  .url-container {
    position: relative;
    width: 700px;
    border: solid 1px $light-grey;
    padding: 0 10px;
    height: 46px;
    line-height: 46px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;

    i {
      color: $text-base;
      position: absolute;
      right: 0;
      top: 9px;
      bottom: 9px;
      font-size: 1.8rem;
      padding-right: 5px;
      background-color: $white;

      &:before {
        vertical-align: top;
      }
    }

    a {
      color: $black;
    }
  }

  .publish-qual,
  .publish-quant {
    .md-block {
      width: 350px;
      margin-right: 20px;

      .publish-value {
        color: $black;
        border-bottom: solid 1px $dividers;
        padding: 2px 0;
      }
    }

    timezone-dropdown {
      isc-dropdown {
        md-input-container {
          display: block;
        }
      }

      isc-dropdown.medium-small-size {
        display: inline-block;
        width: 70px;

        md-input-container.dropdown-container {
          width: 70px;
          margin: 0;

          md-select[disabled] .md-select-value {
            background-image: none;

            span {
              margin-top: -1px;
            }

            .md-select-icon {
              display: none;
            }
          }
        }
      }
    }

    isc-reward {
      label {
        font-size: .8rem;
      }

      .rewards {
        md-select {
          margin-top: -30px;
        }

        md-input-container {
          font-size: .875rem;

          .md-input[disabled] {
            color: $black;
            background-image: none;
          }

          md-select[disabled] .md-select-value {
            color: $black;
            background-image: none;

            .md-select-icon {
              display: none;
            }
          }
        }
      }
    }
  }

  .progress-message {
    padding-bottom: 20px;
  }

  md-icon {
    height: 12px;
    width: 12px;
    min-width: 12px;
    min-height: 12px;
    box-sizing: content-box;

    &.activity-error,
    &.activity-success {
      padding-top: 16px;
      margin-right: 5px;
    }
  }

  .progress-text {
    padding-top: 15px;
    padding-right: 25px;
    font-size: .8rem;
    max-width: none;
    font-weight: normal;
    line-height: 18px;
  }

  .progress-icon {
    padding-top: 15px;
  }

  .activity-error {
    padding-top: 14px;
    margin: 0;
    color: $insites-red;
  }

  .activity-success {
    padding: 10px;
    margin-right: -15px;
    margin-left: -10px;
    color: $insites-green;
  }
}

.activities-filter-bar {
  margin-right: 50px;
}

.activity-filter-matches {
  height: 80px;
  transition: all linear .2s;
  display: block;
  overflow: hidden;
  background-color: $white;
  padding: 15px 30px 0;
}

isc-wizard-step {
  label.section-title {
    color: $text-paragraph;
    padding: 5px 0;
    font-weight: bold;
  }

  label.section-sub-title {
    color: $text-paragraph;
    padding: 5px 0;
    margin-bottom: 5px;
  }
}

export-data-component div {
  display: inline-block;
}
