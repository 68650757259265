@import '../../../../../styles/_colors.scss';
@import '../../../../../styles/_mixins.scss';

.fiveStarRating {
  border: none;
  float: right;
  cursor: pointer;
  margin: 4px 6px;
  @mixin highlight {
    &:not(.clear):before {
      content: '\f005';
      color: $square-primary;
    }
  }

  input {
    display: none;
  }

  label:not(.clear):before {
    margin: 2px;
    font-family: FontAwesome;
    @include md-icon-size(1.1rem);
    display: inline-block;
    content: '\f006';
  }

  label {
    color: $disabled-or-hint-text;
    float: right;

    &.clear {
      font-size: .8rem;
      visibility: hidden;
      clear: both;

      &.visible {
        visibility: visible;
      }
    }
  }

  &:disabled {
    cursor: default;

    label {
      @include disabled();

      &.clear.visible {
        visibility: hidden;
      }
    }
  }

  input:checked ~ label {
    @include highlight();
  }

  &:not(:checked):not(:disabled) > label:hover {
    cursor: pointer;
    @include highlight();
  }

  &:not(:checked):not(:disabled) > label:hover ~ label {
    cursor: pointer;
    @include highlight();
  }
}
