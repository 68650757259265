@import './../media-mixin';
@import './../colors';

carousel {
  margin: margin-inline-values(0 0 20px 0);
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: $light-grey;

  div.label-preparing {
    position: inherit;
    span {
      position: absolute;
      #{$start}: 15px;
      bottom: 10px;
      font-size: 1.4rem;
      color: $text-base;
    }
  }

  .slides-container {
    height: 100%;
    position: relative;

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;

      .ng-scope img:first-child {
        max-width: 100%;
        max-height: 100%;
      }

      .fill-container {
        width: 100%;
        height: 100%;
      }
    }
  }

  .left-arrow-button,
  .right-arrow-button {
    position: absolute;
    margin: auto !important;
    top: 0;
    bottom: 0;
    display: table-cell;
    align-items: center;
    font-size: 1.875rem;
    padding: padding-inline-values(0 8px 0 8px);
    cursor: pointer;
    color: rgba($white, .87);
    height: 30px;
    /*rtl:raw:
    transform: scaleX(-1);
    */

    &:before,
    &:after {
      text-shadow: -1px 0 $black, 0 1px $black, 1px 0 $black, 0 -1px $black;
    }
  }

  .right-arrow-button {
    #{$end}: 0;
  }

  isc-video {
    position: relative;
  }

  .video-clickable {
    cursor: pointer;
  }

  video.video-clickable {
    position: static;
  }

  .play-button {
    width: 612px;
    top: calc(50% - 50px);
    text-align: center;
    position: absolute;
    cursor: pointer;

    md-icon {
      width: 100px;
      height: 100px;

      svg {
        fill: $white;
      }
    }
  }

  .stimulus-remove,
  isc-stimuli-download {
    opacity: .4;
    position: absolute;
    top: 5px;
    #{$end}: 5px;
    cursor: pointer;
    text-shadow: -1px 0 $white, 0 1px $white, 1px 0 $white, 0 -1px $white;

    &:hover {
      opacity: 1;
    }
  }
  .video-loading-container {
    display: block;
    position: relative;
    outline: none;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-color: transparent;
    padding: padding-inline-values(0);
    width: 100%;
    // height: 100%;
    padding-top: calc(100% / 1.86);
    border: none;
    font-size: 3.4rem;
    .video-overlay {
      height: 0;
      width: 30%;
      padding-bottom: 30%;
      background-color: rgba($black, .5);
      border-radius: border-inline-radius-values(50%);
      position: absolute;
      margin: auto;
      #{$start}: 0;
      top: 0;
      #{$end}: 0;
      bottom: 0;

      svg {
        top: 0;
        bottom: 0;
        #{$end}: 0;
        #{$start}: 0;
        position: absolute;
        height: 50%;
        fill: $white;
        margin: auto;

        &.play-icon,
        &.video-icon {
          #{$start}: 5%;
        }
      }

      md-progress-circular {
        height: 100% !important;
        width: 100% !important;
        #{$start}: 0;
        top: 0;
        position: absolute;

        &.white {
          svg path {
            stroke: $white;
          }
        }

        svg {
          width: 100% !important;
          height: 100% !important;
          transform-origin: inherit !important;
        }
      }
    }

    &.broken-stimuli-container {
      border: dashed 1px $broken-stimuli-border-color;
    }

    .hover-over-container {
      padding-bottom: 10%;
      background-color: $border-grey;
      position: absolute;
      margin: auto;
      #{$start}: 0;
      top: 0;
      #{$end}: 0;
      bottom: 0;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $text-base;

      &:hover {
        opacity: 1;
      }

      .icon {
        font-size: 3em;
        padding-bottom: 15%;
      }
    }
  }
}

vimeo-video {
  display: block;
  width: 100%;
  height: 100%;

  iframe {
    width: inherit;
    height: inherit;
    min-height: 345px;
  }
}

md-radio-group.md-focused.ng-not-empty .md-checked .md-container:before {
  bottom: 0;
  top: -4px;
  #{$end}: 0;
  #{$start}: -4px;
}

md-radio-group.radio-buttons-container.layout-row {
  margin: margin-inline-values(0 0 17px 0);
  width: 100%;

  md-radio-button {
    margin: margin-inline-values(0 5px 0 0);

    .md-on,
    .md-off {
      width: 15px;
      height: 15px;
    }

    .md-ripple-container {
      bottom: 0;
      top: -4px;
      #{$end}: 0;
      #{$start}: -4px;
    }
  }
}

// for some reason the angular puts an ng-hide class
.do-not-hide.ng-hide {
  display: block !important;
}

.loading {
  margin: 100px auto;
}
