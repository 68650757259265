@import '../../_colors.scss';
@import '../../_variables.scss';

isc-input {
  display: inline-table;

  &.block {
    display: block;
  }

  md-input-container:not(.md-input-invalid).md-input-focused {
    label,
    :after {
      color: $valid-input-color;
    }
    .md-input {
      border-color: $valid-input-color;
    }
  }

  isc-input-errors {
    font-size: .8rem;
  }

  .isc-input-label {
    font-size: $label-default;
  }

  &.md-block {
    > md-input-container {
      display: block;
    }
  }

  + span {
    &.tooltip-container {
      position: relative;
      top: 30px;
      height: 20px;
    }

    &.survey-link,
    &.decipher-id {
      top: -22px;
    }
  }

  &.small-size {
    > md-input-container {
      width: 80px;
    }
  }

  &.medium-small-size {
    > md-input-container {
      width: 260px;
    }
  }

  &.medium-size {
    > md-input-container {
      width: 350px;
    }
  }

  &.large-size {
    > md-input-container {
      width: 454px;
    }
  }
}
