﻿forum-room-component {
  text-align: center;
  display: block;

  > .intro {
    padding: 22px 0 0;
  }
}

.conversation-list {
  padding: 0;
}

.page-content-noconversation {
  background-color: $background-base;
  margin-top: $page-content-margin-top;

  span {
    color: $text-base;
    font-size: 2rem;
  }

  .no-topics {
    font-size: 1.1rem;
  }

  &.searching {
    margin-top: 50px;
  }
}

md-list-item.conversation-md-list-item {
  $conversation-list-item-line-height: 17px;
  $conversation-list-item-title-font-size: .75rem;
  display: block;

  & .md-no-style:before,
  &:before,
  .md-list-item-inner:before {
    display: none;
  }

  .conversation-md-list-item-header {
    display: flex;
    flex-direction: row;

    .md-avatar {
      width: 30px;
      height: 30px;
      margin: 3px 12px 0 0;
      flex: 0 0 auto;
    }

    .conversation-md-list-item-header-text {
      flex: 1 1 auto;
      font-size: $conversation-list-item-title-font-size;

      .conversation-title {
        color: $square-secondary;
        font-size: larger;
        font-weight: bold;
      }

      .conversation-author {
        color: $square-secondary;
        font-weight: 600;
      }

      .conversation-buttons {
        display: inline-block;
        z-index: 3;
        position: relative;

        > span,
        a {
          padding: 0 8px;
          color: $text-base;
        }
      }

      .conversation-author-details {
        .role:after {
          content: '\A';
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $text-base;
          display: inline-block;
          margin: 2px 10px;
        }
      }
    }
  }

  .conversation-message {
    padding: 0 0 15px;
    flex: 1 1 auto;
    line-height: $conversation-list-item-line-height;
    font-size: $conversation-list-item-title-font-size;
    letter-spacing: normal;
  }

  & + md-divider {
    margin: 0 25px;

    &:last-child {
      display: none;
    }
  }

  &:hover {
    background-color: $table-grey;

    .conversation-md-list-item-header {
      .conversation-md-list-item-header-text {
        .conversation-title {
          color: $square-quinary;

          a {
            text-decoration: none;
          }
        }

        .conversation-buttons {
          display: inline-block;

          .typcn {
            color: $text-base;
          }

          > span,
          a {
            color: $text-base;
            text-decoration: none;
            &:hover {
              color: $square-quinary;
            }
          }
        }
      }
    }
  }
}

.forum-search-matches {
  background-color: currentColor;
  border-radius: 3px;
  padding: 0 3px;
  font-size: xx-small;
  margin-left: 5px;
}

.forum-search-match-count {
  color: #FFFFFF;
}

forum-conversation-component {
  .forum-search md-toolbar.search-area {
    width: 290px;
  }

  md-toolbar.search-area {
    width: 400px;
  }
}

.forum-content {
  border: 0;
  background: #FFFFFF;
  border-radius: 5px;
}

.forum-room-page {
  border: 0;
  margin: 0 30px 30px;
}
