.isc-square-job-status-filter {
  position: relative;
}

.md-select-menu-container.md-active.square-job-filter-container {
  md-select-menu,
  md-select-menu md-content {
    max-height: 292px;
  }
}
