@import '../../_colors.scss';
@import '../../_variables.scss';
$modal-actions-background: $isc-grey;

md-dialog {
  background: transparent;
  box-shadow: none;
  overflow: visible;
  max-width: 80vw;
  form {
    overflow: visible;
  }
}

.request-a-call-modal {
  max-width: 500px;
}

.md-dialog-container {
  overflow-x: hidden;
  overflow-y: scroll;
}

// When multiple dialogs are shown, make the second's container darker
// to show an overlay above the first.
.md-dialog-container ~ .md-dialog-container {
  background: rgba($black, .48);
}
// hide the second backdrop (we already show an overlay using the styles above)
.md-dialog-backdrop ~ .md-dialog-backdrop {
  display: none;
}

isc-modal-dialog {

  .close-button-container {
    position: absolute;
    top: -33px;
    #{$end}: 0;

    .icon-button {
      color: rgba($white, .56);
      margin-bottom: 10px;
    }
  }

  .dialog {
    background: $white;
    border-radius: border-inline-radius-values(4px);
    display: flex;
    flex-direction: column;
  }

  md-dialog-content {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
  }

  md-toolbar {
    background-color: transparent !important;
    padding: padding-inline-values(23px 40px);
    margin: margin-inline-values(0);
    flex: 0 0 auto;

    h2,
    .h2 {
      margin: margin-inline-values(0);
      font-size: 1.5rem;
      font-weight: 600;
    }

    p {
      margin: margin-inline-values(0);
      font-size: .8rem;
      color: $disabled-or-hint-text;
    }

    i {
      font-size: .875rem;
    }
  }

  hr {
    margin: margin-inline-values(0 40px);
    border-top: none;
    border-color: $dialog-header-underline-color;
  }

  .modal-actions {
    background: $modal-actions-background;
    padding: padding-inline-values(20px 50px);
    justify-content: center;
    border-radius: border-inline-radius-values(0 0 4px 4px);

    .md-button {
      margin: margin-inline-values(0);
      border-radius: border-inline-radius-values(2px);
    }

    isc-button:last-child {
      #{$margin-inline-end}: 0;
    }
  }

  isc-modal-content {
    display: block;
    padding: padding-inline-values(35px 50px);
    overflow: visible;
  }

  &.buttons-right {
    .modal-actions {
      justify-content: flex-end;
    }
  }

  &.buttons-left {
    .modal-actions {
      justify-content: flex-start;
    }
  }

  &.transparent-actions {
    .modal-actions {
      background-color: transparent;
    }
  }

  &.hide-toolbar {
    md-toolbar {
      display: none;
    }

    isc-modal-content {
      padding-top: 50px;
    }
  }

  &.large-size {
    .dialog {
      width: 80vw;
    }
  }

  .tooltip-container.recruitment-dialog {
    font-size: unset;
  }
}
