@import '../../../styles/_colors.scss';
activity-info-component {

  .md-input-container {
    label {
      color: $text-base;
      font-size: .8rem;
    }
  }

  .error-message {
    color: $errors;
  }
}
