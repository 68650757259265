@import './../../components/colors';

isc-mention-menu {
  background: $white;
  box-shadow: $hashtag-box-shadow;
  width: fit-content;

  .scrollable-menu {
    height: auto;
    max-height: 250px;
    overflow: auto;
  }

  .menu-highlighted {
    font-weight: bold;
  }

  isc-mention-menu-item {
    user-select: none;
  }

  ul {
    list-style: none;
    padding: padding-inline-values(5px 0);
    margin: margin-inline-values(0);

    li {
      padding: padding-inline-values(0 24px);
      height: 48px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $hashtag-list-item-color;
      user-select: none;
      position: relative;
      transition: background .15s linear;
    }
  }

  .active {
    background: $hashtag-active-color;
  }
}
