@import '../../_colors.scss';
@import '../../variables.scss';

isc-progress-circular {
  position: relative;
  display: block;

  md-progress-circular {
    &.background {
      position: absolute;
      top: 0;
      #{$start}: 0;

      svg path {
        stroke: $lighter-grey;
      }
    }
    &.green {
      svg path {
        stroke: $green;
      }
    }
    &.red {
      svg path {
        stroke: $red;
      }
    }
  }
}
