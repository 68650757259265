@import '../../_colors.scss';
@import '../../_variables.scss';

isc-textarea {
  md-input-container:not(.md-input-invalid).md-input-focused {
    :after,
    label {
      color: $valid-input-color;
    }

    .md-input {
      border-color: $valid-input-color;
    }
  }

  .isc-textarea-label {
    font-size: $label-default;
  }

  isc-textarea-errors {
    font-size: .8rem;
  }

  &.md-block {
    > md-input-container {
      display: block;
    }
  }

  &.medium-size {
    > md-input-container {
      width: 700px;
    }
  }
}
