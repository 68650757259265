isc-conversation-list {
  .thick {
    font-weight: bold;
  }

  md-list {
    padding: padding-inline-values(0) !important;
  }

  md-list-item.conversation-md-list-item {
    $conversation-list-item-line-height: 17px;
    $conversation-list-item-title-font-size: .8rem;
    padding-top: 16px;
    padding-bottom: 16px;
    min-height: 62px;
    display: block;
    line-height: $conversation-list-item-line-height;
    font-size: $conversation-list-item-title-font-size;
    letter-spacing: normal;
    text-align: $start;
    word-break: break-word;
    @include media(gt-sm) {
      #{$padding-inline-start}: 25px;
      #{$padding-inline-end}: 25px;
    }
    @include media(lt-md) {
      #{$padding-inline-start}: 10px;
      #{$padding-inline-end}: 10px;
    }

    h3,
    .h3 {
      display: block;
      font-size: 1.17em;
      font-weight: bold;
    }

    & .md-no-style:before,
    &:before,
    .md-list-item-inner:before {
      display: none;
    }

    .conversation-md-list-item-header {
      display: flex;

      .md-avatar {
        width: 30px;
        height: 30px;
        margin: margin-inline-values(0 12px 0 0);
        flex: 0 0 auto;
      }

      h3,
      .h3 {
        display: inline;

        &.has-notification {
          position: relative;
          &:after {
            content: '';
            height: 8px;
            width: 8px;
            background-color: $notification-background;
            border-radius: border-inline-radius-values(50%);
            position: absolute;
            #{$end}: 0;
            #{$margin-inline-end}: -15px;
            margin-top: 5px;
          }
        }
      }

      .post-date {
        display: block;
      }

      .conversation-md-list-item-header-text {
        width: 100%;

        > .md-button {
          color: $white;
          margin: margin-inline-values(0 0 0 4px);
          font-size: .8rem;
          line-height: inherit;
          min-height: 14px;
          min-width: 140px;
        }

        .conversation-numbers-container {
          white-space: nowrap;
          #{$margin-inline-start}: 20px;
          align-self: center;
        }

        .conversation-numbers {
          width: 90px;
          text-align: center;
          display: inline-block;
          @include media(lt-md) {
            display: block;
            width: 56px;
            margin-bottom: 5px;
            > span {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          > span {
            display: block;
            white-space: nowrap;
          }
        }

        > div > h3,
        > div > .h3 {
          margin: margin-inline-values(0);
        }

        .dot {
          position: relative;
          width: 5px;
          display: inline-block;

          &:before {
            content: '.';
            position: absolute;
            font-size: 1.2rem;
            top: -18px;
            z-index: 1;
          }
          @include media(lt-md) {
            &:first-of-type {
              display: none;
            }
          }
        }
      }
    }

    & + md-divider {
      margin: margin-inline-values(0 25px);

      &:last-child {
        display: none;
      }
    }

    .hover-helper {
      position: absolute;
      top: 0;
      #{$start}: 0;
      #{$end}: 0;
      bottom: 0;
      z-index: 2;
      opacity: 0;
      transition: opacity .2s ease-in-out;
      cursor: pointer;
      &:focus {
        opacity: .05;
      }
    }

    &:hover {
      .hover-helper {
        opacity: .05;
      }
    }
  }
}
