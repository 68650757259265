﻿.warning-message {
  background-color: #EAEAEA;
  color: #838383;
  border-radius: $alert-border-radius;
  padding: 8px;

  > div > i {
    font-size: 2rem;
    margin-right: 8px;
    line-height: 1;
  }
}

.researcherImage {
  width: 200px;
  border-radius: 20px;
  display: block;
  margin-bottom: 10px;
}
