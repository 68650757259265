@import '../../_colors.scss';
@import '../../variables.scss';

md-card {
  border-radius: border-inline-radius-values(5px);
  border: solid 1px $lighter-grey;
  box-shadow: none !important;
  margin: margin-inline-values(10px);

  md-card-title {
    padding: padding-inline-values(20px);
    border-bottom: solid 1px $lighter-grey;

    md-card-title-text {
      display: block;
    }

    .md-headline {
      float: $start;
      color: $primary-text;
      font-size: 1.125rem;
      font-weight: 600;
      text-align: $start;
      line-height: 32px;

      h1,
      .h1,
      h2,
      .h2,
      h3,
      .h3,
      h4,
      .h4,
      h5,
      .h5,
      h6,
      .h6 {
        margin-top: 0;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .md-subhead {
      color: $primary-text;
      font-size: 1rem;
      text-align: $end;
      line-height: 32px;
      padding: padding-inline-values(0) !important;
    }
  }

  md-card-title + md-card-content {
    padding: padding-inline-values(20px);
  }

  .not-collapsible {
    cursor: default;
  }

  .right {
    float: $end;
  }

  .left {
    float: $start;
  }

  .collapsible-chevron {
    margin: margin-inline-values(10px);
  }

  .collapsible-content {
    margin-top: -100%;
    transition: margin-top .5s cubic-bezier(0, 0, .5, 1);
  }

  .expanded {
    margin-top: 0 !important;
  }

  .collapsive-container {
    overflow: hidden;
  }
}
