﻿.expander {
  header {
    border-bottom: 1px solid $light-grey;

    .fa {
      font-size: .875rem;
      vertical-align: text-top;
    }

    h3,
    .h3 {
      margin-top: 0;
      margin-bottom: 5px;
      display: inline-block;
    }
  }

  section {
    overflow: hidden;
    max-height: 0;
    transition: max-height .7s ease-in-out;
    margin-top: 10px;
  }

  section.notCollapsed {
    transition: max-height .9s ease-in-out;
    max-height: 100%;

    .expand-collapse-content {
      margin-top: 20px;
    }
  }
}
