﻿@import './../_colors.scss';

.highlighted {
  color: $insites-orange;
}

.as-sortable-item {
  background-color: transparent;
  touch-action: none;
  -webkit-touch-callout: none;
}

.as-sortable-placeholder {
  background-color: $isc-grey;
}

.theme {
  .themes-action-col {
    min-width: 200px;
    text-align: left;
    margin: 0;
    padding: 0;

    div {
      display: inline-block;
    }

    .as-sortable-item-handle {
      cursor: move;
    }
  }

  md-icon.typcn {
    line-height: 1;
    color: #C3C3C3;
  }

  .members-col {
    text-align: right !important;
  }
}

.dragiconcontainer {
  display: inline-block;
}

md-icon.dragicon {
  min-height: 16px;
  min-width: 16px;
  height: 16px;
  width: 16px;
  vertical-align: top;
  color: #929795;
  cursor: move;
}
