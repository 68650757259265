@import './mixins/info-page-mixins';

.infoPage {
  @include info-page-base;

  .inputField,
  .inputField > md-input-container {
    width: 350px;

    &.noblock {
      display: inline-block;
    }
  }

  &.squareInfo {
    .inputField,
    .inputField > md-input-container {
      width: 450px;
    }
  }

  md-autocomplete[md-floating-label] + md-input-container {
    margin-top: 2px;
  }

  .textAreaField > md-input-container {
    width: 700px;

    > fieldset > md-input-container {
      margin-bottom: 28px;

      &.url-input-container {
        margin-bottom: 52px;
      }

      .layout-row {
        width: 520px;
      }

      md-autocomplete {
        md-input-container {
          margin: 0;

          input {
            color: inherit !important;
          }
        }
      }

      input.squareSubDomainInput {
        margin-right: 10px;
      }

      .flex-33 {
        padding-top: 14px;
      }
    }

    &.noblock {
      display: inline-block;
    }

    .requiredJobIdLabel:after {
      content: ' *';
      font-size: .8rem;
      vertical-align: top;
    }
  }

  md-input-container + md-autocomplete[md-floating-label] {
    padding-top: 7px;
  }

  .textAreaField {
    width: 700px;

    label {
      color: $text-base !important;
    }
  }

  .datepicker {
    margin-bottom: 0;

    mdp-date-picker {
      button {
        padding-left: 0;
        width: auto;
      }
    }
  }

  .squareSubDomainSuffix {
    position: relative;
    top: 32px;
  }
}

.controls-fixed {
  bottom: 0;
  position: fixed;
  top: auto;
  z-index: 50;
}

md-dialog-content .controls-fixed {
  position: absolute;
}

.controls-fixed-measure-helper {
  height: 0 !important;
  overflow: hidden !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.descriptionField {
  width: 50em;
  height: 15em;
}

.fix-marginTop {
  margin-top: -24px;
}

.form-div-container {
  width: 350px;
  margin: 5px 0;
}

activity-qual-specify {
  .infoPage div.md-block {
    margin-bottom: 0;

    .ng-scope {
      font-size: .875rem;
    }
  }

  .activity-config label span.highlight {
    color: $insites-green;
    font-weight: bold;
  }
}
