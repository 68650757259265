﻿.admin-intf-card-container {
  margin: 0;
  background-color: $white;

  &.unread {
    background-color: rgba(90, 188, 150, .1);
    position: relative;
    transition: background-color 3s ease-in-out;

    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 5px;
      background-color: rgba(90, 188, 150, 1);
      transition: background-color 3s ease-in-out;
    }

    .admin-intf {
      background-color: rgba(0, 0, 0, 0);
    }

    &.reading {
      background-color: rgba(255, 255, 255, 1);

      &:before {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.isNotFiltered {
  opacity: .3;
}
