@import '../../../../styles/_colors.scss';

sticky-button.ng-hide:not(.ng-hide-animate),
home-button.ng-hide:not(.ng-hide-animate) {
  display: inline-block !important;
  visibility: hidden;
}

.search-button {
  margin: 1px 0 0 20px;
}

.actions-column-header {
  padding-right: 100px !important;
}

span.randomly-selected {
  margin-right: -3px;
  color: $insites-green;
  font-weight: bold;
}

span.randomly-slash {
  margin-right: -3px;
}

message-overview {
  isc-button,
  isc-input-button {
    vertical-align: bottom;
  }
}
