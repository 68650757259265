@import '../../../_colors.scss';
@mixin isc-pills($background) {
  content: '\A';
  width: 10px;
  height: 10px;
  border-radius: border-inline-radius-values(50%);
  background: $background;
  display: inline-block;
  margin: margin-inline-values(0 10px 0 0);
}

.red-dot {
  white-space: nowrap;
  color: $red;
  float: #{$end};
  position: relative;
  top: -20px;
  &:before {
    @include isc-pills($red);
  }
}

isc-incentive-card {
  @include timeline-badge();

  .timeline-item-date {
    position: absolute;
    top: 20px;
    font-size: .8rem;
    width: 150px;
    #{$start}: -185px;
    text-align: $end;
    @include media(xs) {
      top: 12px;
      #{$start}: -67px;
      width: 45px;
    }
  }

  .timeline-badge {
    #{$start}: 200px;

    .md-button {
      pointer-events: none;

      &.md-fab {

        &.outdated {
          md-icon {
            #{$margin-inline-start}: -3px;
          }
        }

        md-icon {
          font-size: 1.2rem;
          line-height: 20px;
          #{$margin-inline-start}: -2px;

          &.fas.fa-ban {
            margin-top: 4px;
            #{$margin-inline-start}: -4px;
            font-size: 1.4rem;
            member-details-saving-up & {
              margin-top: 3px;
              #{$margin-inline-start}: -4.5px;
            }
          }

          &.fas.fa-exclamation {
            margin-top: 4px;
            member-details-saving-up & {
              margin-top: 1px;
            }
          }
        }

        &:disabled {
          md-icon {
            color: $white;
          }
        }
      }
    }
  }

  .timeline-panel {
    #{$start}: -3.5%;
    padding: padding-inline-values(10px 15px);
    min-height: 63px;

    &.primary {
      .timeline-title {
        color: $square-primary;
      }
    }

    &.todo {
      padding-bottom: 0;
      border-bottom: $card-todo-border solid $red;
    }

    .timeline-title {
      text-transform: uppercase;

      > div > span:first-of-type {
        font-weight: bold;

        &.credit {
          font-size: 1.1rem;
        }
      }

      > div > div > span {
        text-transform: initial;
      }
    }

    .timeline-body {
      h4 {
        margin-top: 0;
        margin-bottom: 0;
        &.primary {
          color: $square-primary;
        }

        i {
          margin: margin-inline-values(5px 5px 5px 0);
        }
      }

      a {
        margin: margin-inline-values(0 0 7px);
        width: 190px;
      }

      .incomplete-reason {
        font-size: .8rem;
        font-weight: 300;
        margin-top: 5px;
      }

      .md-button {
        margin: margin-inline-values(10px 0 5px);
      }

      .reward-statusses {
        margin: margin-inline-values(0 5px);
      }

      .chip {
        font-family: 'Open Sans';
        font-size: .8rem;
        opacity: .75;
        border-radius: border-inline-radius-values(100px);
        padding: padding-inline-values(0 10px);
        margin: margin-inline-values(0 2px);
        text-transform: uppercase;
        font-weight: 600;
        color: $primary-text;
        border: $primary-text 1px solid;
        &.highlight {
          color: $square-primary;
          border: $square-primary 1px solid;
        }
      }

      .activity-name {
        color: $primary-text;
      }
    }
    .toggle {
      position: absolute;
      top: 0;
      bottom: 0;
      #{$end}: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
  }

  .activity-locked {
    padding: padding-inline-values(10px 0);
    height: 30px;
    md-icon {
      display: inline;
    }
  }
}
