@import '../../../styles/_colors.scss';
@mixin import-result($color) {
  color: $color;
  margin-top: 5px;
  ul {
    margin-top: 0;
    list-style: none;
    padding-left: 5px;
    li:before {
      content: '-';
    }
  }
}
.add-activity-dialog {
  width: 450px;
  .warning-messages {
    @include import-result($insites-orange);
  }
}
