@import '../../../../../styles/_colors.scss';
.add-member-dialog {
  width: 730px;
  md-input-container {
    width: 100%;
    i {
      font-size: .8rem;
    }
  }

  .add-member-dialog-content {
    .consent-date-picker {
      .md-input {
        color: $black;
      }
    }
  }

  isc-checkbox {
    md-input-container {
      margin: 0;
    }
  }

  .error-messages {
    color: $errors;
  }
  &.wide {
    width: 800px;
  }
}
