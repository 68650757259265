@import '../../_colors.scss';
@import '../../_variables.scss';

isc-save-button {
  #{$margin-inline-end}: 15px;

  isc-button {
    #{$margin-inline-end}: 0;
  }

  md-progress-circular {
    position: absolute;
    top: 50%;
    #{$start}: 50%;
    transform: translate(-50%, -50%);

    svg path {
      stroke: $white;
    }
  }

  &.flat {
    md-progress-circular {
      svg path {
        stroke: currentColor;
      }
    }
  }

  .md-button {
    .icon {
      visibility: hidden;
      position: absolute;
      top: 50%;
      #{$start}: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .is-saving {
    .md-button {
      .icon {
        visibility: visible;
      }

      span {
        visibility: hidden;
      }
    }
  }

  &.no-margin {
    #{$margin-inline-end}: 0;
  }
}
