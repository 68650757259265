﻿@import './mixins/main-mixins';

.ng-cloak,
.x-ng-cloak,
[data-ng-cloak],
[ng-cloak],
[ng\:cloak],
[x-ng-cloak] {
  display: none !important;
}

* {
  outline: none;
  padding: revert; // Necessary because of the reset styles in Vue
  margin: revert; // Necessary because of the reset styles in Vue
  // font-size: 16px; // all the REM values are calculated based on this root font size
}

*:focus-visible:not(input):not(.nw-editor__res):not(.ProseMirror):not(.main-content):not(.q-card) {
  outline: 1px auto $black !important;
}

input:focus-visible,
.nw-editor__res:focus-visible,
.ProseMirror:focus-visible {
  background-color: #F5F5F5 !important;
  border-bottom: 2px solid $black !important;
}

html {
  height: 100%;
  background-color: $background-base;
  overflow-y: hidden;
}

body {
  background-color: $background-base;
  font-family: $font-family-sans-serif-custom;
  font-size: $font-size-base;
  color: $text-base;
  min-height: 100%;
  height: 100%;
  overflow-y: hidden;

  // support for google translate toolbar
  .translated-ltr &,
  .translated-rtl & {
    $google-translate-bar-height: 40px;
    min-height: calc(100% - #{$google-translate-bar-height}) !important;
    height: calc(100% - #{$google-translate-bar-height}) !important;
  }
}

md-content[md-scroll-y] {
  z-index: 4;
}

.sticky {
  z-index: 4;
  top: 0;
  position: sticky;
}

.sticky-shadow {
  box-shadow: 0 1px 4px -2px #717171;
}

isc-sidenav {
  z-index: 11;
}

.stretch-y {
  height: 100%;
  overflow: hidden;
}

.content-wrapper {
  padding: 0;
  width: 100%;
  display: flex;
}

// fix for material select
md-select[disabled] .md-select-value {
  margin-bottom: 0;
}

.select-header-container {
  md-content._md {
    max-height: 240px;
  }
}

md-select-header.select-filter-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, .1), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12);
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;

  .select-filter-input {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding-left: 10.67px;
  }
}

/*fa icons*/
.fa {
  font-size: $font-size-large + .125rem;
  margin-right: 10px;
}
isc-button-icon .fa {
  text-align: center;
  top: 0 !important;
}
@media screen and (max-width: $nav-md-max) {
  .page-title {
    left: 111px !important;
  }
}
@media screen and (max-width: 959px) {
  .page-title {
    margin-top: 24px;
    left: 0 !important;
  }
}

.page-content {
  @include page-content;
}

.fit-content {
  max-height: fit-content;
}

.table-content {
  margin: 30px;
}

.publish {
  background: none;
}

.content-header {
  margin: 0;
  width: 100%;
  display: flex;
  flex: 1;
}

.nocaps {
  text-transform: none;
}

.home-page {
  position: relative;
  top: 150px;

  .h2 {
    text-align: center;
  }

  .client-search {
    width: 600px;
    margin: 0 auto;

    .h4 {
      margin-top: 100px;
      margin-bottom: 26px;
      font-weight: 600;
    }

    .main-search {
      display: block;
      width: 100%;
      left: 0;

      md-autocomplete {
        background: $background-base;

        md-autocomplete-wrap {
          border-bottom: 2px solid $light-grey;

          input:not(.md-input) {
            padding: 0;
            font-size: 1.6rem;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.md-tooltip.switchTooltip {
  margin-top: 0;
}

.md-tooltip {
  height: auto;
  margin-top: 5px !important;
}

.clearfix:after,
.clearfix:before {
  content: ' ';
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

.input-no-asterisk {
  md-input-container label.md-required:after {
    content: '';
  }
}

.platform-notice {
  padding: 5px;
  background-color: $square-tertiary;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: $white;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: .875rem;
  }

  .fa {
    color: $white;
    margin: 5px 10px 0;
    cursor: pointer;
  }
}

.table-cell {
  display: table-cell;
}

.use-chrome {
  background-color: $monza;
  color: $white;
  line-height: 40px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: block;
  text-align: center;
  word-wrap: break-word;
  padding: 0 20px;

  a {
    padding: 0 2px;
    text-decoration: underline;
    color: $white;
  }
}

.rtl {
  .ng-wig,
  isc-input input {
    direction: rtl;
    &:focus {
      background-color: #F5F5F5 !important;
    }
  }

  .nw-editor {
    font-size: .875rem;
    padding-right: 5px;
  }

  .ng-wig .nw-editor__res {
    padding: 0;
  }

  .nw-toolbar {
    height: 32px;

    > li {
      float: right;
    }

    .nw-toolbar__item {
      .list-ol,
      .list-ul,
      .fa-indent,
      .fa-outdent {
        transform: scaleX(-1);
      }
    }
  }
}

.error {
  color: $insites-red;
}

/* text markup */
span.warning {
  color: $insites-red;
}

.show-background-image {
  background-image: url('/images/landingpage-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
