﻿.left-alignment {
  text-align: left;
}

.right-alignment {
  text-align: right;
}

.margin-t-0 {
  margin-top: 0;
}

.margin-t-10 {
  margin-top: 10px;
}

.margin-t-20 {
  margin-top: 20px;
}

.margin-t-22 {
  margin-top: 22px;
}

.margin-t-30 {
  margin-top: 30px;
}

.margin-t-40 {
  margin-top: 40px;
}

.margin-b-10 {
  margin-bottom: 10px;
}

.margin-b-20 {
  margin-bottom: 20px;
}

.margin-b-22 {
  margin-bottom: 22px;
}

.margin-b-30 {
  margin-bottom: 30px;
}

.margin-b-40 {
  margin-bottom: 40px;
}

.margin-r-15 {
  margin-right: 15px;
}

.margin-r-20 {
  margin-right: 20px;
}

.margin-l-10 {
  margin-left: 10px;
}

.margin-l-20 {
  margin-left: 20px;
}

.padding-l-10 {
  padding-left: 10px;
}

.padding-b-30 {
  padding-bottom: 30px;
}
