﻿@import 'base-variables';
@mixin page-title {
  display: block;
  flex: 0 0 auto;
  padding-left: 40px;
  padding-right: 25px;
  padding-top: 20px;
  height: 70px;
  min-height: 70px;
  z-index: 5;
  right: 0;
  @media screen and (max-width: $nav-md-max) {
    left: 111px !important;
  }
  @media screen and (max-width: $screen-xs-max) {
    left: 0 !important;
  }

  h2,
  .h2 {
    display: inline-block;
    font-size: 1.875rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: $black;
    opacity: .87;
    padding-bottom: 15px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.page-title {
  @include page-title;
}
