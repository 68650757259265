@import '../../../styles/_colors.scss';

square-info {
  .other-urls {
    margin-top: -25px;
    width: 500px;
    .other-url {
      font-size: .8rem;
      color: $text-base;
    }
  }
  .no-binding {
    margin: -25px 0 30px;
  }
  .md-input-container {
    label {
      color: $text-base;
      font-size: .8rem;
    }
    i {
      color: $text-base;
      font-size: .8rem;
    }
  }
  .inactive {
    color: $text-base;
  }
  .digest-mail-settings {
    .digest-email-text {
      position: relative;
      top: 30px;
    }
    md-input-container {
      margin: 0;
      padding-left: 4px;
    }
    mdp-time-picker {
      md-input-container {
        width: 60px;
      }
      .md-button.md-icon-button {
        margin: 0;
      }
    }
    .span {
      padding-top: 8px;
    }
  }
  .sso-codes {
    > div {
      margin: 6px 0;
      label {
        margin: 0 5px 0 0;
        line-height: 36px;
        &.force-select-all {
          user-select: all;
        }
      }
      i {
        line-height: 36px;
      }
    }
  }
  md-switch {
    margin-top: 0;
    display: inline-block;
  }

  .screener-closed-error {
    color: $red;
  }

  isc-button.no-padding-left button {
    padding-left: 0;
  }
}
