@import '../../../../styles/_colors.scss';
isc-modal-dialog.activity-sequence-confirm {
  isc-modal-content.activity-sequence-confirm-content {
    max-width: 500px;
    li {
      font-weight: bold;
    }
    .warning-group {
      margin: 1em 0;
      &:first-child {
        margin-top: 0;
      }
      div:first-of-type {
        padding-top: 5px;
      }
      div {
        padding-left: 20px;
      }
    }
    .sequence-warning-message {
      color: $warning-message;
      font-weight: bold;
      font-size: 1rem;
    }
  }
}
