$white: #FFFFFF !default;
$black: #000000 !default;
$light-grey: #E3E2E2 !default;
$light-grey-menu-item: #E5E1E1 !default;
$isc-grey: #F0F0F0 !default;
$dark-grey: #1C2126 !default;
$lighter-grey: #E0E0E0 !default;
$text-base: rgba($black, .34) !default;

$border-grey: #D2D2D2;

$pink: #F06F7E !default;
$primary: #275B85 !default;
$red: #C2273D !default;
$green: #347F62 !default;
$orange: #F29303;
$monza:#D0021B;

$square-primary: #347F62;
$background-base: #FAFAFA;

// input
$valid-input-color: $green !default;
$mention-color: #E6E6E6;
$input-prefixsuffix: #E6E6E6;

// tags
$tag-color: $lighter-grey;
$tag-hover-color: $red !default;
$tag-starred: #F29303 !default;
$tag-valid: $green !default;

// Buttons
$button-disabled: #E0E0E0 !default;
$radio-button-on: $green;

//checkboxes
$checkbox-border: #9E9E9E !default;

// modals
$dialog-header-underline-color: #EBEBEB;

//tables
$table-background: #DDDDDD !default;
$neutral-pill: #808080 !default;
$grey-pill: #757575 !default;
$red-pill: #C2273D !default;
$green-pill: #347F62 !default;

//text
$primary-text: rgba($black, .87);
$secondary-text: rgba($black, .54);
$disabled-or-hint-text: #9E9E9E;
$dividers: #E0E0E0;

// stimuli
$stimuli-border-color: $border-grey;
$broken-stimuli-border-color: $red;

// forum
$forum-topic-background: #F0F3F5;
$notification-item-border: #E0DBDB;
// media-recorder
$media-recorder-recording-color: #FF0000;

// notification
$notification-background: #D0021B;
$notification-counter-background: $notification-background;
$notification-counter-color: #FFFFFF;
$notification-item-border: #E0DBDB;

//color-picker
$color-picker-input-error-color: #FF0000;
$color-picker-border-color:#DDDDDD;
$color-picker-select-icon-background-color: #808080;

//sequence
$card-stack-box-shadow-color: #9E9E9E;
$card-navigate-hover-background-color: rgba(158, 158, 158, .2);
$sticky-text-shadow-color: rgba($black, .5);
$error-toast-background: #FDDFE5;

//editor

$caption-editor-border-color: #CCCCCC;
$caption-editor-color: rgba(0, 0, 0, .54);
$caption-editor-error-color: #DD2C00;

$hashtag-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24), 0 0 8px 0 rgba(0, 0, 0, .12);
$hashtag-list-item-color:  rgba(0, 0, 0, .87);
$hashtag-active-color: #EEEEEE;

$warning-message: #AE1F26;
