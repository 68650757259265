﻿.menu-level {

  .dropdown {
    width: 100%;
    height: 100%;
    position: initial;

    .breadcrumb-menu {
      text-align: left;
      width: 100%;
      height: 100%;
      color: $human8-dark-teal;
      &:hover {
        .breadcrumb-icon,
        .breadcrumb-label,
        .breadcrumb-placeholder,
        .caret {
          opacity: 75%;
        }
      }

      .breadcrumb-icon {
        position: absolute;
        i {
          position: relative;
          top: 5px;
          font-size: 30px;
        }
        md-icon {
          position: relative;
          top: 5px;
          min-height: 30px;
          min-width: 30px;
          color: $white;
        }
      }

      .breadcrumb-label {
        font-size: .6rem;
        text-transform: capitalize;
        font-weight: 600;
        position: relative;
        left: 35px;
      }

      .breadcrumb-placeholder {
        display: block;
        font-size: .875rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        font-weight: 600;
        position: relative;
        left: 35px;
        max-width: 100px;
      }
      @media screen and (max-width: $breakpoint-md-lg) {
        .breadcrumb-placeholder {
          max-width: 6rem;
        }
      }
      @media screen and (max-width: $breakpoint-md) {
        .breadcrumb-placeholder {
          max-width: 50px;
        }
      }

      .caret {
        position: absolute;
        right: 0;
        top: 57%;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }

    .dropdown-list {
      background-color: $white;
      position: absolute;
      height: auto;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      width: 270px;

      .scroller {
        position: absolute;
        max-height: 260px;
        height: auto;
        overflow-y: hidden;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        width: 200px;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14);
      }

      .search-box {
        padding-bottom: 5px;
        margin-right: 10px;
        margin-left: 10px;

        > input {
          padding: 6px 15px 6px 4px;
          margin-bottom: 0;
        }
      }

      .scrollable-menu {
        margin-bottom: 0;
        background-color: $white;
        background-clip: padding-box;
        position: relative;
        &.starEnabled li {
          padding-right: 20px;
        }

        div {
          width: 200px;
        }

        li {
          height: 36px;
          line-height: 36px;
          padding: 0 15px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 200px;

          &.list-header:first-letter {
            text-transform: uppercase;
          }

          &:hover:not(.list-header) {
            background-color: $insites-orange;
            color: $black;
          }

          &.starPresent {
            padding-right: 20px;
          }

          &.active {
            font-weight: bold;
          }

          > i {
            float: right;
            position: absolute;
            right: 0;
            padding-right: 10px;
            margin-right: 2px;
          }
        }
      }
    }
  }

  &.menu-level0 {
    z-index: 999;
    .breadcrumb-menu {
      padding: 10px 50px 16px 0;
    }
  }

  &.menu-level1 {
    z-index: 998;
    .dropdown {

      .breadcrumb-menu {
        padding: 10px 65px 16px 50px;

        .breadcrumb-label {
          left: 30px;
        }

        .breadcrumb-placeholder {
          left: 30px;
        }
      }
    }
  }

  &.menu-level2 {
    z-index: 997;
    .dropdown {

      .breadcrumb-menu {
        padding: 10px 55px 16px 50px;

        .breadcrumb-label {
          left: 40px;
        }

        .breadcrumb-placeholder {
          left: 40px;
        }
      }
    }
  }

  &.menu-level3 {
    z-index: 996;
    .dropdown {

      .breadcrumb-menu {
        padding: 10px 55px 16px 50px;

        .breadcrumb-label {
          left: 40px;
        }

        .breadcrumb-placeholder {
          left: 40px;
        }
      }
    }
  }

  &.menu-level4 {
    z-index: 995;
    .dropdown {

      .breadcrumb-menu {
        padding: 10px 55px 16px 50px;

        .breadcrumb-label {
          left: 40px;
        }

        .breadcrumb-placeholder {
          left: 40px;
        }
      }
    }
  }
}
