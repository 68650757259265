.rewards-picker-noEdit {
  width: 350px;

  label {
    font-size: .8rem;
    text-transform: uppercase;
    font-style: normal;

    &.extra-label {
      font-size: .6rem;
      margin-bottom: 0;
      margin-top: 3px;
    }
  }

  button,
  md-input-container {
    width: 96px;
    margin: 18px 15px 0;
  }

  .rewards-layout {
    width: 74px;
  }

  .first {
    margin-left: 10px;
  }

  md-select {
    margin-top: -12px;
    margin-bottom: 25px;
  }

  button {
    margin-top: 25px;
    border: 1px solid $insites-orange;
    color: $insites-orange;

    &:disabled {
      color: $breadcrumb-grey;
      border: 1px solid $breadcrumb-grey;
    }
  }
}

.rewards-noEdit {
  width: 350px;

  md-input-container {
    height: 40px;
    margin-bottom: 0;
    margin-top: 10px;

    label {
      margin-bottom: 0;
    }

    label:not(.md-no-float):not(.md-container-ignore),
    md-input-container .md-placeholder {
      font-size: .8rem;
    }

    input {
      font-size: .8rem;
    }
  }
}

.rewards-picker .rewards {
  border: 1px dashed $background-base;
  padding: 10px 0;
  width: 454px;

  label {
    text-align: center;
    font-size: .8rem;
    text-transform: uppercase;
    font-style: normal;

    &.extra-label {
      font-size: .6rem;
      margin-bottom: 0;
      margin-top: 3px;
    }
  }

  span {
    margin-top: 26px;
  }

  button,
  md-input-container {
    width: 96px;
    margin: 18px 15px 0;
  }

  .rewards-layout {
    width: 96px;
    margin: 0 15px;
  }

  md-select {
    margin-top: -12px;
    margin-bottom: 25px;
  }

  button {
    margin-top: 25px;
    border: 1px solid $insites-orange;
    color: $insites-orange;

    &:disabled {
      color: $breadcrumb-grey;
      border: 1px solid $breadcrumb-grey;
    }
  }
}

.topLabel {
  margin-bottom: 16px;
}
