@import '../../../styles/_colors.scss';

.isc-activity-status-filter {
  position: relative;

  .activity-drag-and-drop-help {
    margin: 15px 0;
    color: $secondary-text;
    font-style: italic;
    font-size: .875rem;
  }

  md-input-container {
    margin: 0;
  }

  md-select {
    font-size: .875rem;
  }
}

.md-select-menu-container.md-active.activity-filter-container {
  md-select-menu,
  md-select-menu md-content {
    max-height: 292px;
  }
}
