@import '../../_colors.scss';

isc-input-button {
  display: inline-block;

  input {
    border: solid 1px;
    border-radius: border-inline-radius-values(2px 0 0 2px);
    padding: padding-inline-values(4px);
    background-color: $white;
    color: $text-base;
    height: 45px;
  }

  .edit-mode {
    display: inline-block;
    margin-top: -19px;

    label {
      font-size: .875rem;
    }
  }

  isc-button {
    margin: margin-inline-values(0);
  }

  .submit-button {
    .md-button {
      border-radius: border-inline-radius-values(0 2px 2px 0);
      #{$padding-inline-start}: 17px;
      #{$padding-inline-end}: 17px;
    }
  }

  md-input-container {
    display: inline;
    position: relative;
    padding: padding-inline-values(0);
    margin: margin-inline-values(0);
    vertical-align: middle;
  }
}
