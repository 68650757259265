@import './../media-mixin';
@import './../colors';

.conversation-add-stimuli {
  font-size: .875rem;
  button.flex {
    min-height: 50px;
    border: 1px dashed $light-grey;
    border-radius: border-inline-radius-values(5px);
    cursor: pointer;
    background-color: $white;
    color: $text-base;
    text-align: center;
    font-size: .875rem;

    &.drag-over-accept {
      background-color: $green;
    }

    &.drag-over-reject {
      background-color: $red;
    }

    md-icon {
      line-height: 18px;
      font-size: 1.3rem;
    }

    &:disabled {
      opacity: .3;
      cursor: no-drop;
    }
    @include media(gt-xs) {
      flex-grow: 0;
      flex-shrink: 0;
      min-width: calc(25% - 16px);
      &.isc-row {
        &:not(:first-child) {
          #{$margin-inline-start}: 20px;
        }
      }

      &.isc-column {
        &:not(:first-child) {
          margin: margin-inline-values(5px 0 0 0);
        }
      }
    }
    @include media(xs) {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include media(lt-md) {
      min-height: 50px;
      border-radius: border-inline-radius-values(3px);
    }
  }
}

.conversation-buttons {
  margin-top: 10px;

  button:first-child {
    #{$margin-inline-start}: 0;
    min-width: 150px;
    @include media(lt-md) {
      width: initial;
      &.isc-row {
        margin: margin-inline-values(20px 0);
      }

      &.isc-column {
        margin: margin-inline-values(5px 0 0 0);
      }
    }
  }
  @include media(lt-md) {
    flex-direction: column;
    width: initial;
    &.isc-row {
      margin: margin-inline-values(20px 0);
    }

    &.isc-column {
      margin: margin-inline-values(5px 0 0 0);
    }

    button {
      #{$margin-inline-start}: 0;
      #{$margin-inline-end}: 0;
    }
  }
}

.message-error {
  color: #DD2C00;
}

div.message-error {
  font-size: .8rem;
  padding-top: 5px;
}

.info-text {
  font-style: italic;
  font-size: .75rem;
  position: relative;
  margin: margin-inline-values(10px 0);
  color: $secondary-text;
}
