isc-timeline ul {
  &:before {
    background-color: $border-grey;
  }

  li {
    &.moreCards {
      > span {
        border: 2px solid $border-grey;

        &.done {
          border-top: 2px solid $border-grey;
        }
      }

      md-progress-circular {
        svg path {
          stroke: $border-grey;
        }
      }
    }

    .timeline-panel {
      border: 1px solid $border-grey;
      background-color: $white;

      .before {
        border-left: 15px solid $border-grey;
        border-right: 0 solid $border-grey;
      }
    }
  }

  .timeline-card {
    .timeline-panel {
      .timeline-title {
        .sticky {
          color: $light-grey;
        }
      }

      .timeline-body {
        .text {
          color: $text-paragraph;
        }
      }

      md-divider {
        background-color: $light-grey;
      }

      .timeline-footer {
        .call-to-action {
          .md-button {
            color: $white;
          }
        }
      }
    }
  }
}
