﻿.insites-progress-linear {
  .md-container {
    border-radius: 5px;
    background-color: $background-base;
  }

  &.active {
    .md-bar2 {
      background-color: $status-active;
    }
  }
}
