@import '../../styles/colors';
table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: 0 10px;
}

.manually-handled-rewards-table-wrapper {
  padding: 0 10px;

  md-card {
    border: 0;
    opacity: 1;
    margin: 0;
    padding: 15px 30px 0;
  }

  td {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

isc-checkbox.manually-handled-rewards-checkbox md-input-container md-checkbox div.md-container {
  margin-top: 23px;
  margin-left: 17px;
}

isc-table-container.with-border {
  md-table-container table.md-table {
    padding-top: 0;
  }
}

.manually-handled-reward-action-bar {
  height: 80px;
  overflow: hidden;
  background-color: $white;
}
