@import '../../../styles/_colors.scss';

.landing {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 15px;

  > section {
    background-color: $white;
    border: 1px solid $border-grey;
    border-radius: 5px;
    box-shadow: 0 0 2px $border-grey;
    margin: 15px;

    .h4 {
      line-height: 40px;
      padding: 0 20px;
      font-weight: bold;
    }

    > p {
      padding: 20px;
    }
  }

  hr {
    background-color: $border-grey;
    height: 2px;
    border: none;
  }

  .message {
    flex: 0 1 530px;
  }

  .squares {
    flex: 1 0 250px;

    .name {
      text-transform: uppercase;
    }

    .list {
      display: flex;
      flex-flow: wrap;
      flex-direction: row;
      width: 100%;
      padding: 10px;

      article {
        border: 1px solid $border-grey;
        border-radius: 5px;
        height: 200px;
        width: 200px;
        margin: 8px;
        padding: 15px;
        position: relative;

        $font-size: .875rem;
        $line-height: 1.4;
        $lines-to-show: 2;

        .name {
          display: block;
          max-width: 180px;
          max-height: $font-size*$line-height*$lines-to-show;
          margin: 0 auto;
          font-size: $font-size;
          line-height: $line-height;
          -webkit-line-clamp: $lines-to-show;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
        }

        > div {
          display: table;
          margin: 5px 0;
        }

        > button {
          position: absolute;
          bottom: 8px;
          left: 8px;

          &.right {
            right: 8px;
            left: auto;
          }

          &.disabled {
            opacity: .3;
            cursor: default;
          }
        }

        .status {
          border-radius: 3px;
          padding: 3px 5px;
          color: $white;
          font-size: .6rem;
        }

        .status-active {
          background-color: $status-active;
        }

        .status-closed {
          background-color: $status-closed;
        }

        .status-archived {
          background-color: $status-closed;
          opacity: .5;
        }

        .status-scheduled {
          background-color: $status-scheduled;
        }

        pin-button {
          float: right;
          position: relative;
          top: -4px;
          right: -4px;
        }

        i {
          margin-bottom: 12px;
        }
      }
    }
  }

  button {
    border: none;
    border-radius: 3px;
    color: $white;
    padding: 5px 10px;
    font-size: .8rem;
  }
}
