isc-timeline-challenge-card {
  .card-stack-one-card,
  .card-stack-two-cards {
    margin-top: 0;
    #{$border-top-start-radius}: 2px;
    #{$border-top-end-radius}: 2px;
    height: 12px;
    background-color: $white;
    box-shadow: 0 -2px 4px 0 $card-stack-box-shadow-color;
  }

  .card-stack-one-card {
    #{$margin-inline-start}: 9px;
    #{$margin-inline-end}: 9px;
  }

  .card-stack-two-cards {
    #{$margin-inline-start}: 19px;
    #{$margin-inline-end}: 19px;
  }

  .timeline-panel {
    &.sequenced {
      box-shadow: 0 -2px 4px 0 $card-stack-box-shadow-color;
    }
  }

  .cards-stack-navigation,
  .activity-locked {
    padding: padding-inline-values(0 20px);
    height: 48px;
    md-icon {
      display: inline;
    }
  }

  .activity-locked {
    height: 48px;
  }

  .cards-stack-navigation {
    font-weight: bold;
    padding-bottom: 20px;
  }

  .timeline-footer {
    challenge-card-footer {
      white-space: nowrap;
      max-width: 200px;
    }
  }

  .call-to-action {
    align-self: flex-end;
  }

  .navigate-stack-button {
    background: none;
    color: inherit;
    border: none;
    padding: padding-inline-values(0 !important);
    font: inherit;
    cursor: pointer;

    &:hover {
      background-color: $card-navigate-hover-background-color;
    }

    &:focus {
      outline: 0;
    }
  }
}
