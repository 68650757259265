@import '../../_colors.scss';

isc-radio-button {
  font-size: $radio-button-font-size;

  md-radio-button {
    .md-label {
      padding-bottom: 2px;
      #{$margin-inline-start}: $radio-button-size + 15px;
    }

    .md-container {
      width: $radio-button-size;
      height: $radio-button-size;
    }

    .md-off {
      width: $radio-button-size;
      height: $radio-button-size;
      border: 1px solid rgba($black, .6);
    }

    .md-on {
      width: $radio-button-size;
      height: $radio-button-size;
    }

    &.md-checked {
      .md-on {
        transform: scale(.58);
      }
    }
  }
}
