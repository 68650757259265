// the values and the naming are the same as in angular-material for consistency
// they represent the max width eg. xs means <600px
$breakpoint-xxs: 375px !default;
$breakpoint-xs: 600px !default;
$breakpoint-sm: 960px !default;
$breakpoint-md: 1280px !default;
$breakpoint-lg: 1980px !default;
@mixin media($type) {
  @if $type == xxs {
    @media only screen and (max-width: $breakpoint-xxs - 1) {
      @content;
    }
  }
  @else if $type == xs {
    @media only screen and (max-width: $breakpoint-xs - 1) {
      @content;
    }
  }
  @else if $type == gt-xs {
    // greater than xs
    @media only screen and (min-width: $breakpoint-xs) {
      @content;
    }
  }
  @else if $type == lt-xs {
    // less than xs
    @media only screen and (max-width: $breakpoint-xs) {
      @content;
    }
  }
  @else if $type == sm {
    @media only screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm - 1) {
      @content;
    }
  }
  @else if $type == gt-sm {
    // greater than sm
    @media only screen and (min-width: $breakpoint-sm) {
      @content;
    }
  }
  @else if $type == lt-sm {
    // greater than sm
    @media only screen and (max-width: $breakpoint-sm) {
      @content;
    }
  }
  @else if $type == lt-md {
    // less than md
    @media only screen and (max-width: $breakpoint-sm - 1) {
      @content;
    }
  }
  @else if $type == md {
    @media only screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md - 1) {
      @content;
    }
  }
  @else if $type == gt-md {
    // greater then md
    @media only screen and (min-width: $breakpoint-md) {
      @content;
    }
  }
  @else if $type == lt-lg {
    // less then lg
    @media only screen and (max-width: $breakpoint-md - 1) {
      @content;
    }
  }
  @else if $type == lg {
    @media only screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
      @content;
    }
  }
  @else if $type == gt-lg {
    // greater than lg
    @media only screen and (min-width: $breakpoint-lg) {
      @content;
    }
  }
}
