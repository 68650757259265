@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_variables.scss';

isc-sidenav {
  header {
    width: 100%;
    min-height: $menu-bar-height - 1;
    max-height: $menu-bar-height - 1;
    cursor: pointer;
    text-transform: uppercase;
    font-size: .75rem;
    letter-spacing: 1px;
    color: $white;
    padding: 20px;
    border-bottom: 1px solid $white;

    img {
      min-height: 32px;
      max-height: 32px;
      min-width: 32px;
      max-width: 32px;
      padding-right: 12px;
    }

    &.client-logo {
      max-height: 120px;
      padding: 20px;

      img {
        margin-left: auto;
        margin-right: auto;
        max-height: 80px;
        max-width: 180px;
        padding-right: unset;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    section {
      padding: 25px 20px 10px;
      border-top: 1px solid $white;
      color: $white;
      font-weight: bold;
      font-size: .75rem;
      margin-top: -1px;
    }

    li {
      overflow: hidden;

      menu-toggle:focus {
        outline: 2px solid $white;
        outline-offset: -2px;
      }
    }

    li.level1 {
      z-index: 5;
    }

    li.level2 {
      z-index: 1;
      font-weight: normal;

      &.active {
        font-weight: bold;
        color: $human8-dark-teal;
      }
    }

    a {
      display: block;
      overflow: hidden;
      height: 100%;
      &:focus {
        outline: 2px solid $white;
        outline-offset: -2px;
      }
    }

    .active {
      font-weight: bold;

      &:not(.open) {
        background-color: $sidenav-active-color;

        .md-button {
          opacity: 1;
          color: $human8-dark-teal;
        }
      }
    }
  }

  md-sidenav {
    width: $sider-nav-width;
    height: 100%;
    z-index: 100;
    background-color: $sidenav-color;
    .bottom {
      padding-top: 24px;
    }

    md-content {
      height: 100%;
      background-color: $sidenav-color;
      display: flex;
      flex-direction: column;
    }
  }

  .powered-by-logo-container {
    padding: 20px 20px 0;
    display: flex;
    color: $menu-color;

    img {
      min-width: 35px;
      min-height: 35px;
      padding-right: 12px;
    }
  }

  footer {
    margin-bottom: 20px;
    .footer-links {
      margin-top: 15px;
      a {
        padding: 5px 20px;
        color: $menu-color;
        font-size: .75rem;
        font-family: 'Open Sans';
        display: block;
        text-decoration: underline;
      }
    }
  }

  .menu-icon {
    padding: 8px 0 0;
    z-index: 10;
    display: none;

    .menu {
      position: absolute;
      left: 12px;
      line-height: 21px;
      height: 48px;
      width: 48px;
    }
    .remove {
      color: $white;
      line-height: 21px;
      height: 36px;
      width: 36px;
      margin: 8px;
    }
    @media screen and (max-width: 957px) {
      display: block;
    }
  }
}
