@import '../../../styles/_colors.scss';

mailtemplate-overruler {
  width: 630px;
  display: block;

  .template-container {
    border: 1px solid $border-grey;
    padding: 5px;
    height: 500px;
    overflow: auto;
    margin-top: 21px;
    &.ng-hide {
      opacity: 0;
      height: 0;
    }
    &.ng-hide-add,
    &.ng-hide-remove {
      transition: all linear .2s;
    }
  }

  .subject-input {
    display: block;
  }
}
