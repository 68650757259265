@import '../../../styles/_colors.scss';

activities-all-tab,
activities-qual-tab,
activities-quant-tab {
  isc-table-container.with-border table.md-table.activities-table {
    display: table;
    table-layout: auto;
    border-collapse: collapse;
    padding: 0;

    /*No select */
    -webkit-touch-callout: none; /* iOS Safari */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    td.reply {
      .search-counter-container {
        width: 62px;
      }
    }

    .angular-ui-tree-handle {
      &:not(.nodrag) {
        cursor: move;
      }
      &:hover {
        background: transparent !important;
      }
      ~ .removing {
        height: 1px !important;
        background: $move-placeholder;
        border: none;
      }
      ~ .move-disabled {
        display: none !important;
      }
      md-icon.dragicon {
        cursor: inherit;
      }
    }

    .ui-tree-handle {
      padding: 10px 10px 10px 30px;

      tr {
        height: 1px !important;
      }
    }
    .angular-ui-tree-placeholder {
      &.removing {
        background: inherit;
        box-sizing: border-box;

        &:before {
          border-radius: 100%;
          content: '';
          padding: 5px;
          position: absolute;
          border: 1px solid $move-placeholder;
          margin-left: -12px;
          margin-top: -6px;
        }
      }
      &:not(.removing) {
        div {
          height: 51px;
          &:not(.position-zero) {
            border: 2px dashed rgba(0, 0, 0, .12);
          }
        }
      }
    }

    .first-column {
      padding-left: 22px !important;
      &-header {
        padding-left: 30px !important;
      }
    }
    .disabled {
      opacity: .3;
    }
    thead.md-head > tr.md-row {
      height: 56px;
    }
    tr {
      border-bottom: 1px solid $dividers;
      td,
      th {
        &:first-child {
          min-width: 200px;
          width: auto;
        }
        &:not(:first-child) {
          width: 110px;
        }
        &.complete {
          width: 200px;

          .progress-bar-line {
            width: 80px;
          }
        }
        &.theme {
          width: 150px;
        }
        &.actions {
          width: 150px;
          min-width: 150px;
          padding-bottom: 0;
          text-align: right;
        }
        &.completionRatio {
          width: 160px;
        }
        &.type {
          min-width: 160px;
        }
        &.endDate {
          min-width: 120px;
        }
      }
      &.non-filtered {
        .first-column span {
          opacity: .6;
        }
        td:not(.first-column) {
          opacity: .6;
        }
      }
    }
  }
}

.angular-ui-tree-drag {
  position: absolute;
  pointer-events: none;
  z-index: 7 !important;
  opacity: .8;
}

tbody.angular-ui-tree-drag {
  tr {
    display: table;
    table-layout: fixed;
    position: absolute;
    width: 100%;
    height: 50px;
    border: 2px solid rgba(0, 0, 0, .12);
    padding-top: 10px;
    background-color: #FFFFFF;
    td,
    th {
      &:first-child {
        width: auto;
        i {
          padding-left: 30px;
          padding-right: 10px;
        }
      }
      &:not(:first-child) {
        width: 110px;
      }
      &.complete {
        width: 200px;
      }
      &.theme {
        width: 150px;
      }
      &.actions {
        width: 150px;
        padding-bottom: 0;
      }
      &.completionRatio {
        width: 160px;
      }
      &.reply {
        padding-left: 25px;
      }
    }
  }
}

table.md-table td.md-cell.actionsRight {
  text-align: right;
}

.moreActions {
  .md-button.md-icon-button {
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 0;

    &:hover {
      background-color: rgba(158, 158, 158, .2);
    }
  }
}
