@import '../../../styles/_colors.scss';

program-info {
  .warning {
    color: $insites-orange;
  }

  isc-button.no-padding-left button {
    padding-left: 0;
  }
}
