@import '../../../../styles/_colors.scss';

isc-modal-dialog.squareinfo-change-confirm {
  isc-modal-content.squareinfo-change-confirm-content {
    max-width: 700px;

    li {
      font-weight: bold;
    }

    .warning-group {
      margin: 1em 0;

      &:first-child {
        margin-top: 0;
      }

      div:first-of-type {
        padding-top: 5px;
      }

      div {
        padding-left: 20px;
      }
    }
  }

  isc-modal-content.modal-title {
    border-bottom: 1px solid;
    border-color: $light-grey;
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: bold;
  }

  .title {
    font-size: .875rem;
    font-weight: bold;
    margin: 10px 0;
    display: block;
    color: $text-base;
  }
}
