log-list,
export-log-list {
  div {
    margin: 0 10px;
  }

  .log-content {
    height: 80%;
    width: 100%;
    overflow: scroll;
  }

  .list-formatting {
    word-break: break-all;
  }
}
