@import '../../styles/_colors.scss';

activity-wizard-type {
  .tooltip-container {
    height: 20px;
    position: relative;
    color: $disabled-or-hint-text;
    left: 5px;
    &.specify {
      top: 40px;
    }
    &.date {
      top: 10px;
    }
    &.theme {
      top: 25px;
    }
    &.tooltip-checkmarket-link {
      top: -20px;
    }
  }

  .reached {
    margin-left: 8px;
    md-input-container {
      margin-top: 8px;
    }
  }

  .reached-updown {
    margin-left: 8px;
    margin-bottom: 15px;
    md-input-container {
      margin-top: 0;
    }
  }

  .span {
    margin-top: 11px;
    font-size: .9rem;
  }

  .wizard-row-details {
    align-self: center;
    font-size: .8rem;
  }

  .moderator-curation {
    margin-top: -12px;
  }

  activity-probe-qual-present,
  activity-qual-builder {
    isc-checkbox md-input-container {
      height: 25px;
    }

    isc-checkbox:last-child md-input-container {
      height: 35px;
    }
  }

  .warning {
    color: $insites-orange;
  }
}
