@import '../../../styles/_colors.scss';

isc-segmentations-filter {
  md-input-container.md-icon-left {
    padding-left: 0;

    label {
      left: 0;
      font-size: .875rem;
    }
  }

  md-icon {
    display: none;
  }

  md-input-container {
    width: 240px;

    label.dropdown-label {
      font-size: .875rem;
    }
  }
}

md-select-header.select-filter-header.segmentations-filter-header {
  padding-left: 0;

  md-icon {
    margin-right: 10px;
    margin-left: 0;

    svg {
      fill: $breadcrumb-base-color;
    }
  }

  input {
    border: 0;
  }

  .search {
    margin-bottom: -10px;
  }

  .options,
  .search {
    padding-left: 10px;
  }
}

.md-select-menu-container.md-active.segmentations-filter-container {
  md-select-menu {
    box-shadow: 1px 3px 10px 2px $box-shadow;
    position: relative;
    bottom: 20px;
    left: 30px;
    max-height: 500px;
    max-width: 300px;

    md-option {
      padding-right: 5px;
    }

    .bold {
      font-weight: bold;
    }

    .md-text {
      width: 100%;

      .segmentation-question {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: 215px;
      }

      md-icon {
        float: right;
        font-size: 1.1rem;
        display: inline;
        margin-top: -7px;
      }
    }

    md-content {
      max-height: 300px;
    }
  }
}
