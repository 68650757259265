@import '../../../../../../../styles/colors.scss';

.add-question-block {
  height: 58px;
  min-width: 58px;
  width: 58px;
  border: 1px solid $border-grey;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;

  .md-icon-button {
    font-size: 1.5rem;

    &[disabled] md-icon.fa-circle-plus {
      color: $text-base;
    }

    md-icon.fa-circle-plus {
      color: $red;
    }
  }
}

.question-block-container {
  border: 1px solid $border-grey;
  border-radius: 2px;
  width: 100%;

  .question-answer-required {
    margin-left: 15px;
    padding: 0 20px 20px;
    font-size: .875rem;
  }

  .question-block {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 15px;

    &.new-question {
      color: $red;
    }

    .question-text {
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: .875rem;

      .lightweight-font {
        font-weight: 300;
      }
    }

    .empty-question-error:before {
      color: $red;
    }

    .question-block-icons {
      margin-top: -10px;
      height: 24px;
      padding: 20px 20px 20px 0;

      .md-button.md-icon-button {
        margin: 0;

        md-icon {
          color: $black;

          &[disabled] {
            color: $disabled-or-hint-text;
          }
        }

        .fa-times {
          margin-top: -8px;
          font-size: 1.75rem;
        }
      }
    }

    .question-config-area {
      flex-grow: 1;
      padding: 20px;
      width: calc(100% - 105px);
      display: grid;
      grid-template-rows: min-content auto min-content;

      &[disabled] {
        cursor: default;
        color: $text-base;
      }
    }

    isc-rich-input {
      flex-grow: 1;
      margin-right: 100px;

      md-input-container {
        margin-top: 0;
        padding-top: 0;

        .nw-editor {
          height: 75px;
          min-height: unset;
        }
      }
    }
  }

  md-icon.fa-circle-plus {
    color: $red;
  }

  md-switch {
    .fa.fa-circle-info {
      font-size: .875rem;
    }
  }
}
