﻿isc-info-chart {
  .isc-info-chart-container {
    width: 400px;
    margin-right: 20px;

    .info-chart-header {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      min-height: 48px;
      background-color: #FFFFFF;
      .info-chart-header-title {
        left: 40px;
        top: 20px;
        font-weight: bold;
        text-transform: capitalize;
        color: $dark-grey;
        position: relative;
      }
    }

    .info-chart-content {
      width: 400px;
    }

    .info-chart-footer {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      min-height: 48px;
      background-color: #FFFFFF;
    }
  }
}
