@import './../colors';

.isc-inline-image-stimuli img {
  cursor: pointer;
}

isc-stimuli,
isc-stimulus-item-preview {
  display: block;
}

div.label-preparing {
  position: relative;
  span {
    position: absolute;
    #{$start}: 5px;
    bottom: 5px;
    font-size: .8rem;
  }
}

div.label-red {
  position: absolute;
  margin-top: -22px;
  font-size: 1.1rem;
  span {
    background-color: $notification-background;
    color: $white;
    text-transform: uppercase;
    padding: padding-inline-values(2px);
    border-radius: border-inline-radius-values(3px);
  }
}

.broken-stimuli-container {
  border: dashed 1px $broken-stimuli-border-color !important;
}

.stimuli-container {
  display: block;
  position: relative;
  outline: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-color: transparent;
  padding: padding-inline-values(0);
  width: 100%;
  padding-top: calc(100% / 1.86);
  border: none;

  .video-overlay {
    height: 0;
    width: 30%;
    padding-bottom: 30%;
    background-color: rgba($black, .5);
    border-radius: border-inline-radius-values(50%);
    position: absolute;
    margin: auto;
    #{$start}: 0;
    top: 0;
    #{$end}: 0;
    bottom: 0;

    &:focus-visible {
      outline: auto;
    }

    svg {
      top: 0;
      bottom: 0;
      #{$end}: 0;
      #{$start}: 0;
      position: absolute;
      height: 50%;
      fill: $white;
      margin: auto;

      &.play-icon,
      &.video-icon {
        #{$start}: 5%;
      }
    }

    md-progress-circular {
      height: 100% !important;
      width: 100% !important;
      #{$start}: 0;
      top: 0;
      position: absolute;

      &.white {
        svg path {
          stroke: $white;
        }
      }

      svg {
        width: 100% !important;
        height: 100% !important;
        transform-origin: inherit !important;
      }
    }
  }

  .hover-over-container {
    padding-bottom: 15%;
    background-color: $border-grey;
    position: absolute;
    margin: auto;
    #{$start}: 0;
    top: 0;
    #{$end}: 0;
    bottom: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $text-base;

    &:hover {
      opacity: 1;
    }

    .icon {
      font-size: 2em;
    }
  }
}

.image-stimuli-dialog {
  position: relative;
  display: block;
  background-color: transparent;
  box-shadow: none;
  overflow: visible;

  img {
    max-height: 80vh;
    max-width: 80vw;
  }

  isc-stimuli-download {
    position: absolute;
    color: #FFFFFF;
    top: 5px;
    margin: margin-inline-values(0);
    #{$end}: 0;
    z-index: 2;
    text-shadow: -1px 0 $black, 0 1px $black, 1px 0 $black, 0 -1px $black;
  }
}

.youtube-stimuli-dialog {
  @extend .image-stimuli-dialog;
  width: 80vw;
  height: 80vh;
  overflow: hidden;

  > .container {
    width: 80vw;
    height: 80vh;
  }

  iframe {
    width: 100%;
    height: 100%;
    background-color: $black;
  }
}

.vimeo-stimuli-dialog {
  @extend .youtube-stimuli-dialog;

  vimeo-video {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    #{$start}: 0;
    #{$end}: 0;
    background-color: $black;
  }

  iframe {
    height: 100%;
  }
}

.video-stimuli-dialog {
  @extend .youtube-stimuli-dialog;
  overflow: hidden;

  .azuremediaplayer {
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    #{$start}: 0;
    #{$end}: 0;
  }
}
