@mixin timeline-badge() {
  .timeline-badge {
    width: 31px;
    height: 31px;
    text-align: center;
    position: absolute;
    #{$end}: -7px;
    top: 20px;
    z-index: 68;
    &.two-cards {
      top: 40px;
    }

    &.one-card {
      top: 30px;
    }

    .md-button {
      &.md-fab {
        box-shadow: unset;
        height: 31px;
        width: 31px;
        min-height: 31px;
        min-width: 31px;
        line-height: 31px;

        &:not([disabled]):active,
        &:not([disabled]):hover {
          box-shadow: none;
        }

        md-icon {
          font-size: 1.4rem;
          line-height: 16px;
          width: 23px;
          height: 22px;
          min-width: 22px;
          min-height: 22px;
          color: #FFFFFF;
          margin: auto;
          #{$margin-inline-start}: -4px;
          margin-bottom: 1px;
        }
      }
    }
  }
}
