@import '../_media-mixin.scss';
@import '../_colors.scss';
@import '_timeline-badge-inline.mixin.scss';
@import '_timeline-badge.mixin.scss';
@import 'cards/incentive-card/incentive-card.scss';
@import './timeline-card.scss';
@import './cards/challenge-card/challenge-card.scss';
@import './cards/challenge-card/challenge-card-footer/challenge-card-footer.scss';

isc-timeline ul.timeline {
  list-style: none;
  padding: padding-inline-values(0);
  position: relative;
  clear: both;
  overflow: hidden;
  margin: margin-inline-values(5px 0 20px);

  div.timeline-badge.hide-ending:after {
    width: 40px;
    height: 100px;
    content: ' ';
    display: block;
    background-color: $background-base;
    margin-top: -10px;
  }

  &:before {
    top: 26px;
    bottom: 45px;
    position: absolute;
    content: ' ';
    width: 2px;
    #{$start}: 50%;
    #{$margin-inline-start}: -1.5px;
  }

  &.right-only {
    &:before {
      #{$start}: 22.4%;
    }

    li.listItem {
      margin-bottom: 20px;
      width: 72.5%;
      #{$start}: 22.5%;

      .timeline-panel {
        float: $end;

        .before {
          border-#{$start}-width: 0;
          border-#{$end}-width: 14px;
          #{$start}: -15px;
          #{$end}: auto;
        }

        .after {
          border-#{$start}-width: 0;
          border-#{$end}-width: 14px;
          #{$start}: -14px;
          #{$end}: auto;
        }
      }

      &.small-cards {
        .timeline-panel {
          .before {
            #{$start}: -11px;
            #{$end}: auto;
            display: inline-block;
            border-top: 12px solid transparent;
            #{$border-inline-start}: 0 solid $border-grey;
            #{$border-inline-end}: 10px solid $border-grey;
            border-bottom: 12px solid transparent;
          }

          .after {
            #{$start}: -10px;
            #{$end}: auto;
            display: inline-block;
            border-top: 12px solid transparent;
            #{$border-inline-start}: 0 solid $white;
            #{$border-inline-end}: 10px solid $white;
            border-bottom: 12px solid transparent;
          }

          &.highlighted {
            .after {
              #{$border-inline-start}: 0 solid $isc-grey;
              #{$border-inline-end}: 10px solid $isc-grey;
            }
          }
        }
      }

      .card-stack-panel {
        float: $end;

        .before {
          #{$border-inline-start}: 0;
          #{$border-inline-end}: 14px;
          #{$start}: -15px;
          #{$end}: auto;
        }

        &after {
          #{$border-inline-start}: 0;
          #{$border-inline-end}: 14px;
          #{$start}: -14px;
          #{$end}: auto;
        }
      }

      .timeline-badge {
        #{$start}: -25px;

        md-icon {
          #{$margin-inline-start}: -4px;
          &.fa {
            #{$margin-inline-start}: -4px;
            margin-top: 2px;
          }

          &.fa-times {
            margin-bottom: -2px;
            #{$margin-inline-start}: -2px;
            font-size: 1.7rem;
          }

          &.fa-envelope {
            margin-top: 2px;
          }
        }
      }

      &.moreCards {
        > span {
          #{$margin-inline-start}: -16px;
          #{$start}: -27px;
        }
      }
    }

    .last-item {
      margin-bottom: 80px;
    }
  }

  .last-item li.timeline-inverted,
  .last-item li.timeline-left {
    margin-bottom: 80px;
  }

  li.listItem {
    margin-bottom: 20px;
    position: relative;
    width: 50%;
    float: $start;
    clear: $start;

    &.moreCards {
      position: absolute;
      bottom: 5px;
      #{$start}: 25%;
      margin-bottom: 0;
      user-select: none;

      > span {
        outline: none;
        height: 31px;
        width: 31px;
        font-size: 1.4rem;
        display: block;
        margin: margin-inline-values(0 auto);
        cursor: pointer;
        border-radius: border-inline-radius-values(50%);
        line-height: 14px;
        text-align: center;
        position: relative;

        span {
          position: absolute;
          #{$start}: 0;
          #{$end}: 0;
          bottom: 0;
          top: 0;
        }

        &.disabled {
          border: none;
          cursor: not-allowed;
        }

        &.done {
          border: none;
          border-top: 2px solid $border-grey;
          cursor: default;
          border-radius: border-inline-radius-values(0);
          @include media(lt-md) {
            display: none;
          }

          * {
            display: none;
          }
        }
      }

      md-progress-circular {
        position: absolute;
        #{$start}: 0;
      }
    }

    &.timeline-inverted {
      float: $end;
      clear: $end;
      margin-bottom: 20px;

      li.list-item.small-cards {
        .timeline-panel {
          .before {
            #{$start}: -11px;
            #{$end}: auto;
            display: inline-block;
            border-top: 12px solid transparent;
            #{$border-inline-start}: 0 solid $border-grey;
            #{$border-inline-end}: 10px solid $border-grey;
            border-bottom: 12px solid transparent;
          }

          .after {
            #{$start}: -10px;
            #{$end}: auto;
            display: inline-block;
            border-top: 12px solid transparent;
            #{$border-inline-start}: 0 solid $white;
            #{$border-inline-end}: 10px solid $white;
            border-bottom: 12px solid transparent;
          }

          &.highlighted {
            .after {
              #{$border-inline-start}: 0 solid $isc-grey;
              #{$border-inline-end}: 10px solid $isc-grey;
            }
          }
        }
      }

      .timeline-panel {
        float: $end;

        .before {
          border-#{$start}-width: 0;
          border-#{$end}-width: 14px;
          #{$start}: -15px;
          #{$end}: auto;
        }

        .after {
          border-#{$start}-width: 0;
          border-#{$end}-width: 14px;
          #{$start}: -14px;
          #{$end}: auto;
        }
      }

      .card-stack-panel {
        float: $end;

        .before {
          #{$border-inline-start}: 0;
          #{$border-inline-end}: 14px;
          #{$start}: -15px;
          #{$end}: auto;
        }

        .after {
          #{$border-inline-start}: 0;
          #{$border-inline-end}: 14px;
          #{$start}: -14px;
          #{$end}: auto;
        }
      }

      .timeline-badge {
        #{$start}: -25px;

        md-icon {
          #{$margin-inline-start}: -4px;
        }
      }
    }

    .timeline-panel {
      width: calc(93% - 10px);
      float: $start;
      position: relative;
      font-size: .875rem;
      border-radius: border-inline-radius-values(3px);

      .before {
        position: absolute;
        top: 22px;
        #{$end}: -15px;
        display: inline-block;
        border-top: 19px solid transparent;
        #{$border-inline-start}: 14px solid $border-grey;
        #{$border-inline-end}: 0 solid $border-grey;
        border-bottom: 19px solid transparent;
      }

      .after {
        position: absolute;
        top: 22px;
        #{$end}: -14px;
        display: inline-block;
        border-top: 19px solid transparent;
        #{$border-inline-start}: 14px solid $white;
        #{$border-inline-end}: 0 solid $white;
        border-bottom: 19px solid transparent;
      }

      &.no-arrow {
        .before {
          display: none !important;
        }
        .after {
          display: none !important;
        }
      }

      &.no-border {
        border: 0;
        background-color: $isc-grey;
      }

      &.highlighted {
        background-color: $isc-grey;
        .after {
          #{$border-inline-start}: 14px solid $isc-grey;
          #{$border-inline-end}: 0 solid $isc-grey;
        }
      }
    }

    .card-stack-panel {
      width: calc(93% - 10px);
      float: #{$start};
      position: relative;
      font-size: .875rem;
      border-radius: border-inline-radius-values(3px);
      margin-top: 3px;

      .before {
        position: absolute;
        top: 22px;
        #{$end}: -15px;
        display: inline-block;
        border-top: 19px solid transparent;
        #{$border-inline-start}: 14px solid $border-grey;
        #{$border-inline-end}: 0 solid $border-grey;
        border-bottom: 19px solid transparent;
      }

      .after {
        position: absolute;
        top: 22px;
        #{$end}: -14px;
        display: inline-block;
        border-top: 19px solid transparent;
        #{$border-inline-start}: 14px solid $white;
        #{$border-inline-end}: 0 solid $white;
        border-bottom: 19px solid transparent;
      }
    }
    &.small-cards {
      .timeline-badge,
      .timeline-item-date {
        top: 5px;
      }

      .timeline-panel {
        .before {
          top: 13px;
          #{$end}: -11px;
          display: inline-block;
          border-top: 12px solid transparent;
          #{$border-inline-start}: 10px solid $border-grey;
          #{$border-inline-end}: 0 solid $border-grey;
          border-bottom: 12px solid transparent;
        }

        .after {
          top: 13px;
          #{$end}: -10px;
          display: inline-block;
          border-top: 12px solid transparent;
          #{$border-inline-start}: 10px solid $white;
          #{$border-inline-end}: 0 solid $white;
          border-bottom: 12px solid transparent;
        }
      }
    }
    &.extra-small-cards {
      .timeline-badge,
      .timeline-item-date {
        top: -2px;
      }

      .timeline-panel {
        min-height: 39px;
      }
    }
  }
}
@include media(lt-md) {
  isc-timeline ul.timeline {
    &:before {
      content: none;
    }

    &.right-only {
      &:before {
        content: ' ';
        #{$start}: 9%;
      }
      .last-item {
        margin-bottom: 50px;
      }
      li.listItem {
        margin-bottom: 10px;
        width: 100%;
        #{$start}: auto;

        &.moreCards {
          #{$start}: 0;
        }

        &.first-inverted {
          margin-top: 0;
        }

        .timeline-badge {
          display: initial;
          #{$start}: 9%;
          #{$margin-inline-start}: -24px;
        }
        .timeline-item-date {
          display: none;
        }

        .timeline-panel {
          width: 80%;
        }
      }
    }

    .last-item li.timeline-inverted,
    .last-item li.timeline-left {
      margin-bottom: 50px;
    }

    li.listItem {
      width: 100%;
      margin-bottom: 10px;

      &.moreCards {
        #{$start}: 0;
      }

      .timeline-panel {
        width: 100%;
        float: $end;

        .after,
        .before {
          display: none;
        }
      }

      .card-stack-panel {
        width: 100%;
        float: #{$end};

        .after,
        .before {
          display: none;
        }
      }

      .timeline-badge {
        display: none;
      }

      &.timeline-inverted {
        margin-bottom: 10px;

        &.first-inverted {
          margin-top: 0;
        }
      }
    }
  }
}
