﻿background-variable-editor {
  display: block;
  margin-bottom: 10px 0 20px;
}

.background-variables-table-container {
  width: 800px;
  input::-ms-clear {
    display: none;
  }

  > div.background-variables-table {
    > div {
      display: flex;

      &:first-child {
        border-bottom: 1px solid $lighter-grey;
      }
    }

    > * {
      vertical-align: top;
    }

    md-input-container,
    .input {
      margin-top: 0;
      margin-bottom: 0;
      width: 320px;

      span {
        padding: 4px;
        line-height: 26px;
        height: 30px;
        display: block;
      }
    }

    .varname {
      padding: 4px;
      line-height: 26px;
      height: 30px;
      display: inline-block;
      min-width: 90px;
    }

    .icon {
      margin-top: 8px;
      vertical-align: top;
    }
    md-icon {
      // md-data-table sets this as !important, so to override it, we need to be even more !important
      width: 20px !important;
      height: 20px !important;
      font-size: 1.3rem !important;
      line-height: 20px !important;
    }
  }

  .typcn-tick:before {
    // there is a margin added in _buttons.scss for all the tick icons... so let's remove it here :(
    font-size: 1em;
    margin-right: 0;
  }

  md-input-container.inline-editor {
    margin: 0;
    padding: 0;

    > .md-input {
      padding: 0;
    }

    > .md-errors-spacer {
      margin-top: -24px;
      padding: 0;
    }
  }
}
