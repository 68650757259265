@import '../../_colors.scss';
@import '../../variables.scss';
// dropdown sizes
$dropdown-small: 90px;
$dropdown-small-medium: 160px;
$dropdown-medium: 200px;
$dropdown-medium-large: 350px;
$dropdown-large: 454px;
@mixin label-position {
  &:not(.ignore-label-position) {
    md-input-container {
      .dropdown-label {
        position: relative;
      }
    }
  }
}
@mixin give-dropdown-size($size) {
  width: $size;

  md-input-container {
    &.dropdown-container {
      width: $size;
    }
  }
}
@mixin give-label-size($size) {
  md-input-container {
    .dropdown-label {
      font-size: $label-default;
    }
  }

  &-upper {
    text-transform: uppercase;
  }
}

.hr-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $light-grey-menu-item;
  padding: padding-inline-values(0);
  width: 240px;
}

isc-dropdown {
  display: block;

  md-input-container {
    margin-bottom: 20px;

    md-select {
      font-size: .875rem;
    }
    md-select:not(.md-no-asterisk) .md-select-value span:first-child:after {
      content: ' ' !important;
    }

    .dropdown-label {
      font-size: $label-default;
      font-weight: normal;
    }
  }

  &.ignore-margin {
    md-select {
      margin: inherit;
    }
  }

  &.ignore-display-block {
    .dropdown-container {
      display: inline-flex;
    }
  }

  md-select:not([placeholder]) {
    .md-select-placeholder {
      > span {
        visibility: hidden;
      }
    }
  }

  isc-dropdown-errors {
    font-size: .8rem;
  }

  &.small-size {
    @include give-dropdown-size($dropdown-small);
  }

  &.medium-qual-size {
    @include give-dropdown-size($dropdown-small-medium);
  }

  &.medium-size {
    @include give-dropdown-size($dropdown-medium);
  }

  &.medium-large-size {
    @include give-dropdown-size($dropdown-medium-large);
  }

  &.large-size {
    @include give-dropdown-size($dropdown-large);
  }

  &.small-label {
    @include give-label-size($label-small);
    @include label-position;
  }

  &.medium-label {
    @include give-label-size($label-medium);
    @include label-position;
  }
  &.default-label {
    @include give-label-size($label-default);
    @include label-position;
  }
}

isc-dropdown.bordered {
  md-input-container {
    margin: margin-inline-values(0);
    padding: padding-inline-values(0);
  }

  md-select {
    height: 30px;
    border: solid 1px $light-grey;
    padding: padding-inline-values(0 10px);
    color: $primary-text;
    font-size: $label-medium-large;

    &:not([disabled]) .md-select-value {
      border: none;
      padding: padding-inline-values(0);
    }
  }
}

.select-header-container {
  #{$margin-inline-start}: 40px;
  margin-top: 50px;
}
