@import '../../_colors.scss';
@import '../../variables.scss';
@mixin md-icon-size($size: 1rem) {
  font-size: $size;
  max-height: $size;
  min-height: $size;
  max-width: $size;
  min-width: $size;
}

isc-challenge-card,
isc-incentive-card {
  &.todo {
    .wrapper {
      .card-detail {
        padding-bottom: 0;
        border-bottom: $card-todo-border solid $red;

        .content {
          padding-bottom: $card-content-padding-vertical - $card-todo-border;
        }
      }
    }
  }

  .wrapper {
    padding: padding-inline-values(12px);

    .card-stack-one-card,
    .card-stack-two-cards {
      #{$border-top-start-radius}: 5px;
      #{$border-top-end-radius}: 5px;
      height: 13px;
      background-color: $white;
      box-shadow: 0 -2px 4px 0 $card-stack-box-shadow-color;
    }

    .card-stack-one-card {
      #{$margin-inline-start}: 9px;
      #{$margin-inline-end}: 9px;
    }

    .card-stack-two-cards {
      margin-top: 4px;
      #{$margin-inline-start}: 19px;
      #{$margin-inline-end}: 19px;
    }

    .navigate-stack-icon {
      display: inline;
    }

    .navigate-stack-button {
      background: none;
      color: inherit;
      border: none;
      padding: padding-inline-values(0) !important;
      font: inherit;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background-color: $card-navigate-hover-background-color;
      }

      &:focus {
        outline: 0;
      }
    }

    .activity-locked-icon {
      display: inline;
    }

    .activity-locked-message {
      color: $primary-text;
      font-family: 'Open Sans';
      font-size: .8rem;
      line-height: 22px;
    }

    .activity-locked {
      margin-top: 25px;
    }

    .card-wrapper {
      min-height: $card-height;
      max-height: $card-height;
      height: $card-height;
      overflow: hidden;
    }

    .card-detail {
      background-color: $white;
      border: 1px solid $lighter-grey;
      border-radius: border-inline-radius-values(5px);
      overflow: hidden;

      header {
        position: relative;

        isc-stimuli button {
          cursor: default;
          #{$border-top-start-radius}: 5px;
          #{$border-top-end-radius}: 5px;
        }

        .sticky-container {
          position: absolute;
          top: 0;
          #{$start}: 0;
          #{$end}: 0;
          height: 74px;
          background-image: linear-gradient(to bottom, rgba($black, .4), transparent);
          padding-top: 15px;
          #{$padding-inline-start}: 15px;
          #{$padding-inline-end}: 15px;
          display: flex;
          justify-content: space-between;

          div.notification-bell {
            padding: padding-inline-values(4px 6px);
            border-radius: border-inline-radius-values(50%);
            background-color: $notification-background;
            text-align: center;
            font-size: 1.1rem;
            z-index: 2;
            width: min-content; // Prevents warping on smaller screens
            height: min-content;
            md-icon {
              color: $white;
            }
          }

          .chip,
          .sticky {
            color: $white;
            font-family: 'Open Sans';
            font-size: .8rem;
            opacity: .75;
            border-radius: border-inline-radius-values(100px);
            background-color: $black;
            padding: padding-inline-values(5px 15px);
            height: min-content;
          }
        }
      }

      .content {
        padding: padding-inline-values($card-content-padding-vertical 25px);
        height: 300px;
        min-height: 0;
        @include media(lt-md) {
          padding: padding-inline-values($card-content-padding-vertical-mobile $card-content-padding-horizontal-mobile);
        }

        .title {
          line-height: 1.25;
          padding-bottom: 15px;
          min-height: 40px; // fallback for non-webkit browsers
          max-height: floor(18px * 1.35 * 2); // fallback for non-webkit browsers
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          /* stylelint-disable */
          display: -webkit-box;
          -webkit-box-orient: vertical;
          /* stylelint-enable */
          h1 {
            font-size: 1.1rem;
            font-weight: bold;
            margin: margin-inline-values(0);
          }
        }

        .text-wrapper {
          position: relative;
          overflow: hidden;

          .text {
            line-height: $card-line-height;
            font-size: $card-text-size;
            max-height: $card-line-height*$card-text-size*$card-lines-to-show;
          }

          .text-fader {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1.5*($card-line-height * $card-text-size);
            background: linear-gradient(to bottom, rgba($white, 0), rgba($white, 1));
          }
        }

        p {
          margin: margin-inline-values(0);
        }
      }

      .open {
        margin-top: 25px;
        z-index: 15;
        @include media(lt-md) {
          margin-top: 15px;
        }

        .md-primary {
          margin: margin-inline-values(0);
        }
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    #{$start}: 0;
    #{$end}: 0;
    background-color: transparent;
    z-index: 10;
    outline: none;
  }
}
