activity-instinct-survey-id {
  md-chips .md-chips {
    box-shadow: none;
  }

  md-input-container {
    margin: 0 !important;
  }

  md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
    margin-top: 0;
  }
}
