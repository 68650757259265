@import './../_colors';
@import './../_variables';
@import './status.scss';
@mixin isc-pills($background) {
  content: '\A';
  width: 10px;
  height: 10px;
  border-radius: border-inline-radius-values(50%);
  background: $background;
  display: inline-block;
  margin: margin-inline-values(0 10px 0 0);
}
@mixin isc-status {
  .status-closed {
    white-space: nowrap;
    color: $red;

    &:before {
      @include isc-pills($red-pill);
    }
  }

  .status-archived {
    white-space: nowrap;
    color: $red;
    opacity: .5;

    &:before {
      @include isc-pills($red-pill);
      opacity: .5;
    }
  }

  .status-active {
    white-space: nowrap;
    color: $green;

    &:before {
      @include isc-pills($green-pill);
    }
  }

  .status-scheduled {
    white-space: nowrap;
    color: $grey-pill;

    &:before {
      @include isc-pills($grey-pill);
    }
  }

  .status {
    background-color: $neutral-pill;
    border-radius: border-inline-radius-values(3px);
    padding: padding-inline-values(0 3px);
    color: $white;
    font-size: $label-small;
  }
}
@mixin change-default-sort-icon($fontWeight, $beforeContent, $afterContent) {
  md-icon.md-sort-icon {
    opacity: 1;
    min-width: 11px;
    width: 11px;

    svg {
      display: none;
    }

    &:before {
      content: $beforeContent;
      font-family: $fa-font-family;
      font-weight: $fontWeight;
    }

    &:after {
      content: $afterContent;
      font-family: $fa-font-family;
      position: absolute;
      #{$margin-inline-start}: -10px;
      margin-top: 8px;
    }
  }
}

isc-table-container {
  position: relative;
  background-color: $white;

  md-table-container {
    table.md-table {

      &:not(.md-row-select) {
        td.md-cell,
        th.md-column {
          &:first-of-type {
            #{$padding-inline-start}: 0;
          }

          &:last-of-type {
            #{$padding-inline-start}: 0;
          }
        }

        td.md-cell:nth-child(n+2):nth-last-child(n+2).no-width {
          #{$padding-inline-start}: 5px;
          #{$padding-inline-end}: 5px;
          width: 5px;
          font-size: $label-small;
        }
      }

      td.md-cell {
        border: none;
        color: rgba($black, .49);
        font-size: .875rem;
        @include isc-status();
      }

      .status-closed {
        white-space: nowrap;
        color: $red;

        &:before {
          @include isc-pills($red-pill);
        }
      }

      .status-archived {
        white-space: nowrap;
        color: $red;
        opacity: .5;

        &:before {
          @include isc-pills($red-pill);
          opacity: .5;
        }
      }

      .status-active {
        white-space: nowrap;
        color: $green;

        &:before {
          @include isc-pills($green-pill);
        }
      }

      .status-scheduled {
        white-space: nowrap;
        color: $grey-pill;

        &:before {
          @include isc-pills($grey-pill);
        }
      }

      .status {
        background-color: $neutral-pill;
        border-radius: border-inline-radius-values(3px);
        padding: padding-inline-values(0 3px);
        color: $white;
        font-size: $label-small;
      }

      .solid-background {
        margin: margin-inline-values(0 10px);
        padding: padding-inline-values(0 10px);
        border: 1px solid $table-background;
        text-transform: uppercase;
        border-radius: border-inline-radius-values(5px);
      }
    }

    thead.md-head {
      > tr.md-row {
        height: auto;
      }

      th.md-column {
        font-size: $label-default;
        border-bottom: 1px solid $lighter-grey;
        padding-bottom: 5px;
        font-weight: normal;
      }

      td {
        vertical-align: bottom;
      }

      td.no-width {
        #{$padding-inline-start}: 5px;
        #{$padding-inline-end}: 5px;
        font-size: $label-small;
      }
    }

    tbody.md-body {
      > tr.md-row {
        height: 50px;

        &:hover {
          background-color: #EEEEEE;
          color: $black;
        }

        &.noAccess[role='button'] {
          cursor: default;

          &:hover {
            background-color: inherit;
            color: inherit;
          }

          td {
            color: $grey-pill;
          }
        }
      }
    }
  }

  .nothing-found {
    text-align: center;
    font-size: 1rem;
    background-color: $white;
    padding: padding-inline-values(20px);
  }

  &.with-border {
    md-table-container {
      border-radius: border-inline-radius-values(5px);
      border: solid 1px $lighter-grey;

      table.md-table {
        border-collapse: inherit;
        background-color: $white;
        padding: padding-inline-values(30px);

        th.md-column {
          @include change-default-sort-icon(0, $fa-caret-up, $fa-caret-down);

          &.md-active {
            @include change-default-sort-icon(900, $fa-caret-up, '');
          }
        }
      }
    }
  }
}

// show the status also on the dragging element
tbody.angular-ui-tree-drag {
  td.md-cell {
    @include isc-status();
  }
}
