﻿.nw-button.emoji-picker-button:before {
  content: '\f118';
}

.emoji,
.emojione {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-bottom: -6px;
}

md-menu-item.emoji-picker {
  color: #000000;
  padding: padding-inline-values(0 5px);

  > .md-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
