@import '../../../styles/base/_colors.scss';

splash-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splash-page-content {
  margin: 2em;
  background: $white;
  border-radius: 5px;
  border: solid 1px $menu-color;
  width: 70%;
  padding: 3em 2em;

  .message-content {
    border-radius: 3px;
    border: 1px solid $menu-color;
    padding: 20px 30px;
    vertical-align: middle;
    margin-bottom: 20px;
    color: $light-grey;
    font-size: .875rem;
    background-color: $alabaster;
  }

  .submit-button {
    color: $white;
    border-radius: 3px;
    font-weight: 800;
    padding-left: 2em;
    padding-right: 2em;
    font-size: .875rem;

    &--agree {
      background-color: $black;
    }

    &--disagree {
      color: $black;
      font-size: .8rem;
    }
  }
}
