@import '../../_colors.scss';

isc-redemption-threshold-card {
  md-card._md,
  md-card.md-secondary-theme {
    width: 205px;
    height: 145px;
    background-color: $lighter-grey;
    text-align: center;
    text-transform: uppercase;
    color: $primary-text;

    md-card-title {
      padding-top: 10px;
      #{$padding-inline-start}: 16px;
      padding-bottom: 16px;
      #{$padding-inline-end}: 16px;
      font-size: .875rem;
      font-weight: bold;
      border: 0;
    }

    md-card-content {
      font-size: .875rem;
      line-height: 20px;
      font-weight: 600;

      .icons {
        font-size: 1.875rem;
        margin-bottom: 5px;
        md-icon {
          color: $square-primary;
          font-size: inherit;
          margin: auto;

          &.fa-arrow-right {
            color: $primary-text;
            font-size: 1.4rem;
          }
        }
      }

      &.mobile {
        display: none;
      }
    }
  }
  &.active {
    cursor: pointer;
    md-card {
      color: $lighter-grey;
      background-color: $square-primary;

      md-card-content {
        .icons {
          md-icon {
            color: $lighter-grey;
            &.fa-arrow-right {
              color: $lighter-grey;
            }
          }
        }
      }
    }
  }
  @include media(lt-md) {
    md-card._md,
    md-card.md-secondary-theme {
      width: auto;
      height: auto;

      md-card-title {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      md-card-content {
        display: none;
        padding-top: 0;
        padding-bottom: 10px;

        .points {
          align-items: flex-start;
          padding-inline-start: 5px;
          line-height: 1.2;
        }

        .icons {
          margin-bottom: 0;
          text-align: $end;
          padding-inline-end: 5px;
        }

        md-icon {
          padding-top: 5px;
          &.fa-arrow-right {
            font-size: 1.4rem;
            color: $primary-text;
          }
        }

        &.mobile {
          display: block;
        }

        &.web {
          display: none;
        }
      }
    }
    &.active {
      md-card {
        md-card-content {
          md-icon.fa-arrow-right {
            color: $lighter-grey;
          }
        }
      }
    }
  }
}
