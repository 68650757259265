@mixin timeline-badge-inline() {
  .timeline-badge-inline.md-button.md-fab {
    width: 31px;
    height: 31px;
    text-align: center;
    box-shadow: unset;
    min-height: 31px;
    min-width: 31px;
    line-height: 31px;
    margin: margin-inline-values(0 3px 0 0);

    &:not([disabled]):active,
    &:not([disabled]):hover {
      box-shadow: none;
    }

    md-icon {
      font-size: 1.4rem;
      line-height: 16px;
      width: 23px;
      height: 22px;
      min-width: 22px;
      min-height: 22px;
      #{$margin-inline-start}: -4px;
      color: #FFFFFF;
      margin-bottom: 1px;
    }
  }
}
