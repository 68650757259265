@import '../../../../../styles/_colors.scss';

isc-modal-content.segmentation-dialog-content.segmentation-links {
  display: flex;
  flex-wrap: wrap;

  div.already-linked,
  div.to-link {
    flex: 1 0 50%;
    div.questions-dropdown-container {
      display: flex;
    }
    isc-button {
      color: $text-base;
      &.sync {
        margin: -30px 0 0 25px;
      }
      &.unlink {
        margin: 30px 0 0 -28px;
      }
    }
    div.border {
      border-radius: 5px;
      border: solid 1px $dividers;
      height: 150px;
    }
    md-input-container {
      margin: 15px 0 0 20px;
    }
  }

  div.segmentation-form-container {
    width: 100%;
  }

  isc-button {
    &.link {
      float: right;
      margin-right: 380px;
      &.edit {
        margin-right: 280px;
      }
    }
  }

  .processDataButton {
    display: block;
    margin: 10px 0 auto;
    text-align: center;
  }

  .segment-treeview {
    &.questions {
      border-radius: 5px;
      height: 150px;
      width: 95%;
    }
  }

  isc-input.medium-size {
    width: 72px;
  }
}

.populateSegmentsViaSurveyDataCheckbox {
  margin-top: 10px;
}
