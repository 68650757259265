@import '_variables.scss';
@import '_colors.scss';
@mixin arrow-after($color) {
  content: '';
  display: inline-block;
  height: 47px;
  width: 47px;
  transform: rotate(135deg);
  transform-origin: center center;
  border-top: 1px solid $color;
  border-left: 1px solid $color;
  position: absolute;
  right: -25px;
  top: 9px;
  background-color: $white;
  z-index: -1;
}
@mixin vertical-bar-after($color) {
  transform: rotate(180deg);
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  right: -40px;
  border: 1px solid;
  opacity: $opacity-onethird;
  background-color: $divider-color;
}

isc-topnav {
  z-index: 10;
  .top-banner {
    background-color: $breadcrumb-background-color;
    min-height: $menu-bar-height;
    width: 100%;
    border-bottom: 1px solid $breadcrumb-border-color;
    flex-flow: row wrap;

    // Remove the X on IE when typing in an input
    input::-ms-clear {
      display: none;
    }

    .menu-level {
      display: inline-block;
      position: relative;
      height: $menu-bar-height;
      vertical-align: middle;

      &:after {
        @include arrow-after($breadcrumb-base-color);
      }
    }

    .dropdown {
      color: $breadcrumb-base-color;

      md-autocomplete {
        min-width: 0;
      }
    }

    > div:nth-of-type(2) {
      > div:last-of-type {
        .dropdown {
          background-color: $human8-dark-teal;
        }
        &:after {
          border-top: 1px solid $human8-dark-teal;
          border-left: 1px solid $human8-dark-teal;
          background-color: $human8-dark-teal;
        }

        .breadcrumb-menu {
          color: $white;
          .breadcrumb-label,
          .breadcrumb-placeholder {
            font-weight: bold;
          }
          &:hover {
            .breadcrumb-icon,
            .breadcrumb-label,
            .breadcrumb-placeholder,
            .caret {
              color: $white;
              opacity: 100%;
            }
          }
        }
      }
    }

    .observer-landing + .search-container {
      .main-search {
        left: 20px;
      }
    }

    &__logos {
      text-decoration: none;
      width: 225px;
      color: $human8-dark-teal;
      font-size: 1.25rem;
      font-weight: bold;
      margin-left: 20px;
    }

    &__dev-tools {
      margin: 2px 4px 0;
    }
  }

  .search-container {
    max-width: 200px;
    flex: 1;
  }

  .main-search {
    max-width: 200px;

    md-icon {
      height: 35px;
      width: 35px;
      min-height: 35px;
      min-width: 35px;
    }

    svg {
      fill: $breadcrumb-base-color;
    }

    input {
      min-width: 100px;
      margin-left: -4px;
      color: $breadcrumb-secondary-color;
      font-size: .875rem;
    }
  }

  .single-level-square .menu-level .dropdown {
    .breadcrumb-menu {
      padding-left: 30px;
    }
  }

  .profile-image {
    margin: 5px;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 100%;
    border: 1px solid;
    border-color: $divider-color;
  }

  .profile {
    margin-right: 6px;
    md-menu {
      width: 100px;
    }

    .overflow {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .topnav-navbar {
    margin-left: auto;
  }

  .cogs {
    padding: 0;
    margin: 0;
  }

  .posting-on-behalf-of {
    display: block;
    font-size: .6rem;
    color: $disabled-or-hint-text;
  }

  .username {
    display: block;
    max-width: 4.7rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .875rem;
    color: $primary-text;
  }

  .caret {
    margin-left: 10px;
    top: 57%;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .switch-interfaces {
    div {
      margin: 12px 0;
    }
  }

  isc-user-notification {
    min-height: 55px;
    display: block;

    .notifications {
      margin-top: 10px;
      padding-top: 3px;

      a.bell {
        margin: 6px 0;

        .menu-icon {
          top: 10px;
          margin: auto 5px;
        }
      }
    }
  }
}

.md-scroll-mask {
  height: 65px;
}
