@import '../../../../../styles/colors';

activity-observation-build {
  .radioButtons {
    margin-top: 12px;
  }

  .isc-input-label-subscript {
    color: $disabled-or-hint-text;
    font-size: .7rem;
    font-weight: 600;
    line-height: 15px;
    display: block;
  }

  .nw-editor {
    height: 167px;
  }

  .tooltip .fa {
    font-size: .875rem;
    color: #9E9E9E;
    margin-left: 4px;
  }
}
