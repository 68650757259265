@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_variables.scss';

isc-sidenav-item {
  display: block;
  height: 100%;
  .md-button {
    line-height: 50px;
    min-height: 50px;
    max-height: 50px;
    background-color: inherit;
    font-weight: inherit;
    border-radius: 0;
    color: $white;
    overflow: hidden;
    text-align: left;
    text-decoration: none;
    white-space: normal;
    width: 100%;
    text-transform: none;
    padding: 0 20px;
    z-index: inherit;
    margin: 0;

    &:active,
    &:focus,
    &:hover {
      outline: 2px solid $white;
      outline-offset: -2px;
    }

    i,
    md-icon.icon {
      font-size: 20px;
      width: 25px;
      margin-right: 5px;
      position: relative;
    }

    &:focus,
    &:focus-within .md-action-icon {
      opacity: 1;
    }

    i {
      top: 2px;
    }

    md-icon.icon {
      color: $white;

      svg {
        height: 12px;
        width: 12px;
        overflow: visible;
      }
    }

    .md-only-on-hover {
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    &:hover .md-only-on-hover {
      opacity: 1;
      color: $white;
    }

    .sidenav-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-word;
      overflow: hidden;
      padding-right: 5px;
    }
  }

  &:focus {
    outline: 2px solid $white;
    outline-offset: -2px;
  }

  &.isc-sidenav-sub-item {
    .md-button {
      line-height: 40px;
      min-height: 40px;
      max-height: 40px;
      padding-left: 43px;
      font-size: .8rem;
    }
  }

  .md-action-icon {
    text-align: center;
    display: block;
    transition: transform .3s ease-in-out;

    md-icon {
      color: $white;
    }
  }

  .md-toggle-icon {
    transform: rotate(0deg);

    &.toggled {
      transform: rotate(-180deg);
    }
  }
}
