@import '../../../../../styles/colors';

activity-scout-build {
  .radioButtons {
    margin-top: 12px;
  }

  .isc-input-label-subscript {
    color: $disabled-or-hint-text;
    font-size: .7rem;
    font-weight: 600;
    line-height: 15px;
    display: block;
  }

  .nw-editor {
    height: 167px;
  }
}
