@import '../../../styles/_colors.scss';
@import '../../../styles/_media-mixin.scss';

activity-health-component {
  .page-title {
    padding-top: 0;
    height: initial;
    min-height: initial;
  }

  isc-button {
    &.go-back {
      margin-top: 5px;

      .md-button {
        color: $insites-green;
        padding: 0;

        .icon {
          margin-right: 0;

          i {
            font-weight: bold;
          }
        }

        .label {
          font-size: 1rem;
        }
      }
    }

    &.right {
      float: right;
    }
  }

  isc-progress-circular {
    &.progress-circular-margin {
      margin: 32px 0 48px;
    }

    isc-progress-circular-content {
      .text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;

        .percentage {
          font-size: 1.875rem;
          margin: 0;
          color: $primary-text;
        }

        .fraction {
          font-size: .875rem;
          color: $disabled-or-hint-text;
          margin: 0;
        }
      }
    }
  }

  targeting-quota-table.quota-table isc-table-container md-table-container {
    width: 500px;
  }

  .activity-health-content {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    @include media(gt-xs) {
      margin-left: 20px;
      margin-right: 20px;
    }

    isc-card {
      display: flex;
      md-card {
        flex-grow: 1;
        md-card-title {
          max-height: 70px;
        }
      }
    }
  }

  isc-card-content {
    .communication-header {
      color: $secondary-text;
      &.activity-target-header {
        font-size: large;
        margin-bottom: 50px;
      }

      .communication-target {
        float: right;
      }
    }

    .segments-communication-targeting {
      margin-top: 25px;
      margin-bottom: 20px;

      .communication-target-option {
        margin-right: 40px;
        font-size: 1rem;
        color: $primary-text;
      }

      .segments-target-text {
        font-size: .8rem;
        color: $disabled-or-hint-text;
      }
    }

    .date-last-synced {
      color: $secondary-text;
    }

    .float-left {
      float: left;
    }

    .clear-left {
      clear: left;
    }

    .exclusion {
      font-size: small;
    }

    isc-table-container md-table-container table.md-table .target-group-table-content td.md-cell {
      font-size: 1rem;
      color: $primary-text;
    }

    md-divider {
      margin: 30px -20px;
    }

    .activity-dates {
      display: flex;
      flex-direction: column;

      >:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}
