@import './../media-mixin';
@import './../colors';

isc-conversation-editor {
  .scout-conversation {
    max-width: 614px;
    margin: 0 auto;
  }

  div.disabled md-input-container {
    margin-top: 5px;
  }

  > div {
    > md-input-container {
      margin-bottom: 10px;
      padding: padding-inline-values(0);
    }

    .conversation-stimulus-preview,
    .attachments {
      margin-top: 7px;
    }

    .attachments:last-of-type {
      margin-bottom: 10px;
    }

    isc-caption-editor {
      margin-top: 20px;
    }
  }

  .nw-editor {
    height: auto;
    min-height: 200px;
    color: rgba(0, 0, 0, .54);
  }
  @include media(lt-md) {
    .nw-editor {
      height: 100px;
    }
  }

  .conversation-title {
    margin-bottom: 25px;
  }

  .messageLabel {
    margin-bottom: 10px;
  }

  .conversation-add-stimuli {
    font-size: .875rem;
    button.flex {
      min-height: 50px;
      border: 1px dashed $light-grey;
      border-radius: border-inline-radius-values(5px);
      cursor: pointer;
      background-color: $white;
      color: $text-base;
      text-align: center;

      &.drag-over-accept {
        background-color: $green;
      }

      &.drag-over-reject {
        background-color: $red;
      }

      md-icon {
        line-height: 18px;
        font-size: 1.3rem;
      }

      &:disabled {
        opacity: .3;
        cursor: no-drop;
      }
      @include media(gt-xs) {
        flex-grow: 0;
        flex-shrink: 0;
        min-width: calc(25% - 16px);
        &:not(:first-child) {
          #{$margin-inline-start}: 20px;
        }
      }
      @include media(xs) {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      @include media(lt-md) {
        min-height: 50px;
        border-radius: border-inline-radius-values(3px);
      }
    }
  }

  .conversation-buttons {
    margin-top: 10px;

    button:first-child {
      #{$margin-inline-start}: 0;
      min-width: 150px;
      @include media(lt-md) {
        margin: margin-inline-values(20px 0);
        width: initial;
      }
    }
    @include media(lt-md) {
      flex-direction: column;
      margin: margin-inline-values(20px 0);
      width: initial;

      button {
        #{$margin-inline-start}: 0;
        #{$margin-inline-end}: 0;
      }
    }
  }

  isc-save-as-draft-message {
    display: inline-block;
    margin-top: 5px;
  }

  .save-as-draft-message-transclude {
    flex-grow: 1;
    text-align: end;
    #{$margin-inline-end}: 6px;
  }

  .saved-as-draft-message {
    color: $disabled-or-hint-text;
    font-size: .8rem;
    #{$margin-inline-start}: 30px;
  }

  .message-error {
    color: $caption-editor-error-color;
  }

  div.message-error {
    font-size: .8rem;
    padding-top: 5px;
  }

  .info-text {
    font-style: italic;
    font-size: .8rem;
    position: relative;
    margin: margin-inline-values(10px 0);
    color: $secondary-text;
  }
}
