
@import './../media-mixin';
@import './../colors';

.probequestions-container {
  border-top: 1px solid $border-grey;

  &.edit {
    border-top: none;
  }

  .border-bottom {
    padding-top: 30px;
    border-bottom: 1px solid $border-grey;
    #{$margin-inline-start}: 0;
  }

  &.disabled isc-forum-post {
    margin-top: 0;
  }

  .answers-placeholder {
    #{$padding-inline-start}: 5px;
  }
}

div.probequestions-container.disabled {
  border-top: none;
  padding-bottom: 15px;
  div.border-bottom {
    display: none;
  }
  md-input-container {
    margin-top: 0;
  }
  div.nw-editor__res {
    height: auto;
  }
}

div.probequestions-container.questions-under-text {
  #{$padding-inline-start}: 0;
  #{$margin-inline-start}: 0;
  #{$margin-inline-end}: 0;

  div.border-bottom {
    #{$margin-inline-end}: -30px;
  }
}

button {
  &.submit-answer-button {
    &.margin-l-10 {
      #{$margin-inline-start}: 10px;
    }
  }

  &.skipQuestion {
    font-weight: 500;
    color: #{$text-base};
  }
}

.save-as-draft-message-transclude {
  flex-grow: 1;
  text-align: end;
  #{$margin-inline-end}: 6px;
}
