﻿md-table-pagination.md-table-pagination {
  height: 45px;
  color: $black;
  opacity: .56;
  border-top: none;

  .label {
    padding: 0;
    font-size: inherit;
    font-weight: normal;
    opacity: .56;
    color: $black;
  }

  .buttons {
    padding: 0 10px;
    background-color: transparent;
    box-shadow: none;
  }
}
