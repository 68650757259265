@import '../_colors.scss';
@import '../_variables.scss';

.media-recorder {
  .pull-right {
    #{$margin-inline-end}: 0;
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 100%;

    canvas,
    video,
    .status-message {
      width: 640px;
      height: 480px;
    }

    .status-message {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    video {
      min-width: 100%;
      min-height: 100%;
      position: relative;
      z-index: 1;
    }

    .overlay {
      height: 99.5%;
      width: 100%;
      position: absolute;
      top: 0;
      #{$start}: 0;
      z-index: 10;
      background: $light-grey;
      opacity: .5;
      text-align: center;
      font-size: 3.1rem;
      font-weight: bold;

      .overlay-content {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .video-toolbar {
    display: inline;

    .options-button {
      isc-button-icon {
        font-size: $isc-media-recorder-icons-size;
      }
    }
  }

  .video-progress-container {
    min-height: 40px;
    font-weight: bold;

    i {
      font-size: $isc-media-recorder-icons-size;
      color: $media-recorder-recording-color;
    }
  }

  isc-button-icon {
    md-icon {
      margin-bottom: 5px;
    }
  }
}
