﻿@import '../colors';

.edit-recruitment-partners-dialog {
  width: 970px;

  md-autocomplete {
    margin-bottom: 35px;

    input:not(.md-input) {
      padding: 0;
    }
  }

  .md-whiteframe-1dp,
  .md-whiteframe-z1 {
    box-shadow: none;
    border-bottom: 1px solid;
    border-color: $dividers;
  }

  .inputField {
    min-width: 100%;
  }

  isc-button {
    &.primary {
      margin: 10px 0 35px;
    }
  }

  isc-checkbox {
    md-input-container {
      margin-top: 0;
      padding-left: 0;
      .md-container {
        margin-left: 0;
      }
    }
  }

  label.section-title {
    color: $secondary-text;
    font-weight: bold;
    margin: 0 0 20px;
    padding: 20px 0 0;
    display: block;
  }
}
