@import './../media-mixin';
@import './../colors';

isc-caption-editor {
  isc-rich-input md-input-container {
    margin-top: 0 !important;
    padding: padding-inline-values(0);
  }

  ng-wig div.ng-wig {
    > ul.nw-toolbar {
      display: none;
    }
    .nw-editor-container {
      border: 1px solid $caption-editor-border-color;
    }
  }

  div.disabled md-input-container {
    margin-top: 5px;
  }

  > div {
    > md-input-container {
      margin-bottom: 10px;
      padding: padding-inline-values(0);
    }
  }

  .nw-editor {
    height: auto;
    min-height: auto;
    color: $caption-editor-color;
    .nw-editor__res {
      min-height: 0;
      display: inline-block;
    }
  }

  .conversation-title {
    margin-bottom: 25px;
  }

  .messageLabel {
    margin-bottom: 10px;
  }
}

.message-error {
  padding-bottom: 5px;
}

.caption-disabled {
  color: rgba(0, 0, 0, .54);
  clear: both;
  font-size: .8rem;
  font-weight: bold;
}
