﻿.select-segments-offset {
  margin-left: 5px;
  margin-top: 5px;
}

square-health-component {
  display: flex;
  flex-direction: column;

  md-card md-card-title {
    .md-headline,
    .md-subhead {
      float: none;
      display: block;
      text-align: left;
    }
  }

  isc-data-table {
    margin: 0;
  }

  md-chips .md-chips,
  md-chips.md-default-theme .md-chips {
    box-shadow: none;
  }

  .segment-chips {
    max-width: 300px;
    display: block;
  }

  .flex-none {
    min-height: 60px;
  }

  .typcn-star-full-outline {
    color: $insites-orange;
  }

  .inbetween-text {
    font-style: italic;
    top: 30px;
    position: relative;
    padding-right: 35px;
    padding-left: 15px;
  }

  .filter {
    box-shadow: 0 4px 20px 1px rgba(50, 50, 50, .3);
    background-color: $white;
    padding: 10px 30px;
    right: 0;

    .filter-title {
      font-weight: bolder;
      color: $insites-orange;

      i {
        font-size: 1.1rem;
        margin-right: 5px;
      }
    }

    .filter-description {
      font-style: italic;
      font-size: .8rem;

      .filter-number {
        font-style: normal;
        font-size: .875rem;
      }
    }

    .expander {
      header {
        border-bottom: none;
        position: absolute;
        right: 30px;
        top: -5px;

        i {
          font-size: 1.3rem;
        }
      }

      section {
        table {
          background-color: #FFFFFF;
          margin-bottom: 0;
        }

        table.md-table td.md-cell > *,
        table.md-table th.md-column > * {
          vertical-align: initial;
        }

        table.md-table:not(.md-row-select) td.md-cell:first-child,
        table.md-table:not(.md-row-select) th.md-column:first-child {
          padding: 0 0 0 30px;
        }

        table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
        table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
          padding: 0;
        }

        .member-header,
        .segment-header,
        .segmentation-header {
          width: 250px;
          padding-right: 20px;
          margin: 0 20px 0 0;
        }

        mdp-date-picker {
          button {
            margin-top: -2px;
          }
        }
      }
    }
  }
  @media screen and (max-width: $nav-md-max) {
    .filter {
      left: 111px !important;
    }
  }
  @media screen and (max-width: $screen-xs-max) {
    .filter {
      left: 0 !important;
    }
  }

  md-chips.margin-card md-chip {
    margin: 0 8px 0 0;
  }

  .health-page {
    .page-content {
      background: none;
      border: 0;
    }

    .keySegmentContainer {
      flex-flow: row wrap;
      display: flex;
    }

    .keySegmentTable {
      background: $table-grey;
      margin-right: 30px;
      margin-bottom: 30px;
      padding: 10px;
    }

    table.md-table thead.md-head > tr.md-row {
      height: 40px;
    }

    isc-table-container md-table-container table.md-table:not(.md-row-select) td.md-cell:first-of-type,
    isc-table-container md-table-container table.md-table:not(.md-row-select) th.md-column:first-of-type {
      padding-left: 5px;
    }

    .remove-margin {
      margin-left: -10px;
    }

    .filter-choice {
      isc-dropdown {
        position: relative;
        top: 15px;
      }

      &.large {
        width: 650px;
      }

      .input-container,
      md-input-container {
        margin: 0;
        padding-left: 0;
        md-select-value {
          color: rgba(0, 0, 0, .38);
        }
      }
    }

    .filtered-data-area {

      .expander {
        .arrow {
          position: relative;
          float: right;
          top: 30px;
        }
      }
    }
  }
}
