md-tabs.activities-tabs {
  float: right;
  margin-left: 20px;

  md-tabs-wrapper {
    border-width: 0;

    .md-tab {
      font-size: 1rem;
      text-transform: none;
      &.md-active {
        color: $black;
        font-weight: 600;
      }
    }

    md-ink-bar {
      height: 5px;
    }
  }
}
