h5.underlined-title,
.h5.underlined-title {
  border-bottom: 1px solid $light-grey;
  margin-bottom: 15px;
  font-weight: bold;
  color: $square-secondary;
  margin-top: 0;

  span {
    border-bottom: 2px solid $square-secondary;
    line-height: 33px;
    font-size: 1.1rem;
    padding-bottom: 4px;
  }
  @include media(lt-md) {
    &:not(.keep-underline-on-mobile) {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;

      > span {
        border-bottom: none;
      }
    }
  }
}
