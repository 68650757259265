table-arrows {
  display: block;

  .arrow-table {
    position: absolute;
    top: 250px;
    font-size: 3rem;
    cursor: pointer;

    &.fa-circle-chevron-left {
      left: 20px;
    }

    &.fa-circle-chevron-right {
      right: 20px;
    }
  }
}
