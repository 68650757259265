@import '../../../../../styles/_colors.scss';
@mixin import-result($color) {
  color: $color;
  margin-top: 5px;
  ul {
    margin-top: 0;
    list-style: none;
    padding-left: 5px;
    li:before {
      content: '-';
    }
  }
}
.import-members-dialog {
  .import-dialog-content {
    .consent-date-picker {
      .md-button {
        width: auto;
      }
      .md-input {
        color: $black;
      }
    }
    md-input-container {
      width: 100%;
      i {
        font-size: .8rem;
      }
      isc-checkbox {
        md-input-container {
          margin: 0;
        }
      }
    }

    isc-button {
      width: 100%;
      margin-bottom: 15px;
    }

    .md-button {
      width: 100%;
      color: $disabled-or-hint-text;
    }
  }

  .dotted {
    &.md-button {
      border: dotted 1px;
      border-color: $dividers;
      font-family: 'Open Sans';
      font-weight: bold;
      font-style: normal;
      text-transform: none;
      padding-left: 75px;
      padding-right: 75px;
      min-width: 0;
      margin: 0;
    }
  }

  .error-messages {
    @include import-result($errors);
  }
  .warning-messages {
    @include import-result($insites-orange);
  }

  .succes-messages {
    color: $insites-green;
    margin-top: 5px;
  }
}
