isc-spinner-component .spinnerContent {
  z-index: 9001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .25);

  &.ng-enter {
    transition: .5s linear all;
    opacity: 0;
  }

  &.ng-enter.ng-enter-active {
    opacity: 1;
  }

  &.ng-leave {
    transition: .5s linear all;
    opacity: 1;
  }

  &.ng-leave.ng-leave-active {
    opacity: 0;
  }

  .loadingindicator {
    color: $insites-orange;
    font-size: 5.6rem;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    transform: translateZ(0);
    animation: load6 1.7s infinite ease;
    position: absolute;
    left: 50%;
    top: calc(50% - 120px);
  }
}
@keyframes load6 {
  0% {
    transform: rotate(0deg);
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.48em;
  }

  5%,
  95% {
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.48em;
  }

  10%,
  59% {
    box-shadow: 0 -.83em 0 -.4em, -.09em -.83em 0 -.42em, -.17em -.81em 0 -.44em, -.26em -.79em 0 -.46em, -.3em -.78em 0 -.48em;
  }

  20% {
    box-shadow: 0 -.83em 0 -.4em, -.34em -.76em 0 -.42em, -.56em -.62em 0 -.44em, -.67em -.49em 0 -.46em, -.75em -.34em 0 -.48em;
  }

  38% {
    box-shadow: 0 -.83em 0 -.4em, -.38em -.74em 0 -.42em, -.65em -.52em 0 -.44em, -.78em -.23em 0 -.46em, -.82em -.09em 0 -.48em;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.48em;
  }
}
