@import '../../_colors.scss';
@import '../../_variables.scss';

isc-checkbox {
  md-input-container {
    width: auto !important;
    margin-bottom: 0 !important;

    md-checkbox {
      .md-container {
        .md-icon {
          width: 19px;
          height: 19px;
          border-radius: border-inline-radius-values(5px);
          border-width: 1px;
        }
      }

      &:not(.md-checked) .md-icon {
        border-color: $checkbox-border;
      }

      &.md-checked .md-label {
        color: $green;
      }

      &[disabled] .md-label {
        color: $disabled-or-hint-text;
      }

      .md-label {
        font-size: $label-medium-large;
        color: $black;
      }
    }

    md-checkbox.md-default-theme.md-checked .md-icon:after,
    md-checkbox.md-checked .md-icon:after {
      border-color: $white;
      border-width: 1px;
      transform: rotate(35deg);
      #{$start}: 5.67px;
    }
  }

  .tooltip {
    display: inline;
  }
}
