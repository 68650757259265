﻿@import '_colors.scss';

.members-in-target {
  margin: 10px 0;
}

.wrapper-channel {
  overflow-x: hidden;
  isc-radio-group {
    padding-top: 16px;
  }
}

p {
  font-size: .875rem;
}

.segmentation-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, .1), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12);
  padding-left: 10.67px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}

.targeting {
  .segment-match {
    .global-option {
      md-select-value {
        border-bottom-color: transparent;
        background-color: $breadcrumb-grey;
        padding: 0 10px 4px;
        border-radius: 4px;
      }
    }
  }

  .segment-header,
  .segmentation-header {
    border: 1px solid $breadcrumb-grey;
    border-radius: 2px;
    width: 250px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .segment-options {
    @extend .segment-header;
    width: 80px;
  }

  .add-segment {
    .md-icon-button {
      font-size: .75rem;
      width: 120px;
      padding: 0;
      text-transform: none;
      border-radius: 0;
      line-height: 22px;
      color: $insites-orange;

      md-icon {
        float: left;
        border: 1px solid $insites-orange;
        border-radius: 50%;
        line-height: 22px;
        color: $insites-orange;
      }

      span {
        position: absolute;
        right: 5px;
        top: 10px;
      }
    }
  }

  md-select.md-table-select > .md-select-value > span > .md-text {
    display: inline;
  }

  .memberids-label {
    position: relative;
  }

  .targeting-by-ids {
    md-input-container {
      max-width: 900px;
      width: 100%;
      margin-top: 40px;
    }

    md-chips-wrap {
      max-height: 600px;
      overflow-y: scroll;
    }

    .md-chip-input-container {
      height: 35px;
    }
  }
}

.date-label {
  font-size: .75rem;
}

.target-label {
  font-size: .75rem;
  position: absolute;
  bottom: 5px;
}

.channel-select-label {
  display: block;
  margin: 50px 0 0;
  color: rgba($black, .38);
  text-align: center;
}

.channel-select {
  position: relative;
  background-color: $white;
  color: rgba($black, .38);
  border-radius: 5px;
  border: 1px solid rgba($black, .12);
  width: 225px;
  padding: 0 30px 30px;
  margin: 30px;
  text-align: left;
  font-size: .75rem;
  @media (max-width: 2060px) and (min-width: 1702px) {
    margin: 65px;
  }

  .channel-select-title {
    font-weight: bolder;
    font-size: 1rem;
    line-height: 22px;
    margin: 0 0 10px;
    color: rgba($black, .56);
    text-align: center;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border: solid 1px $insites-green;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    background-color: $white;
    display: none;

    md-icon {
      width: 25px;
      height: 25px;
      color: $insites-green;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .channel-targets-list {
    padding: 0;
    list-style-type: none;

    li {
      line-height: 28px;
    }

    md-icon {
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
      color: rgba($black, .38);
    }
  }

  &.active {
    border: 1px solid $insites-green;
    color: rgba($black, .56);

    .img-container {
      opacity: .56;
    }

    .channel-select-title {
      color: rgba($black, .87);
    }

    .checkmark {
      display: block;
    }

    .channel-targets-list md-icon {
      color: $insites-green;
    }
  }

  .img-container {
    width: 100%;
    height: 100px;
    text-align: center;
    position: relative;
    padding: 23px;
    opacity: .38;

    img {
      height: 54px;
    }
  }
}

.media-preview {
  width: 178px;
  height: 100px;
  display: inline-block;
  position: relative;
  opacity: .7;
  margin: 10px 15px 10px 0;
  border: 1px solid rgba(128, 128, 128, 0);
  border-radius: 7px;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }

  .image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      z-index: -1;
    }
  }

  .media-remove {
    position: absolute;
    top: -50px;
    right: 5px;
    text-align: center;
    cursor: pointer;
    &:focus-visible {
      top: 5px;
    }
  }

  &:hover .media-remove {
    top: 5px;
  }
}

.channelPage,
.infoPage {
  .content-wrapper {
    flex-wrap: wrap;

    .column {
      min-width: 400px;
    }

    .title {
      font-size: .875rem;
      font-weight: bold;
      margin: 10px 0;
      display: block;
      color: $text-base;
    }
  }
}

.tooltip-container {
  color: $disabled-or-hint-text;
  .fa {
    font-size: .875rem;
    margin-left: 1%;
  }
}

.fieldWithAsterisk:after {
  content: ' *';
  font-size: .75rem;
  vertical-align: top;
}

.drop-box.fieldWithAsterisk:after {
  float: right;
  margin-right: 10px;
}

.sendEmailDelayInputContainer {

  > span {
    font-weight: bold;
    vertical-align: middle;
  }

  .sendEmailDelayInput {
    > md-input-container {
      position: relative;
      bottom: 10px;
      margin-top: 0;

      > input {
        text-align: center;
        display: inline-block;
        float: none;
      }
    }
  }
}

.iscLabel {
  overflow: hidden;
  padding-left: 3px;
  padding-right: 0;
  margin-bottom: 0;
  pointer-events: none;
}

.dateTime-label {
  .tooltip-container {
    .fa {
      margin-top: 0;
    }
  }
}

.cta-tab-options {
  md-radio-group {
    display: block;
    margin-top: 5px;
    min-width: 230px;

    md-radio-button {
      display: inline-block;

      &:last-of-type {
        margin-left: 20px;
      }
    }
  }
}

.sso-engagement-message {
  .sso-ideastream {
    position: relative;
    bottom: 46px;
    min-width: 350px;

    .sso-ideastream-error {
      position: relative;
      top: 38px;
    }
  }
}

.publish-overview {
  .publish {
    width: auto;

    isc-dropdown {
      md-input-container {
        display: table-cell;
      }
    }
  }

  md-input-container {
    padding: 0;
    margin-bottom: 0;

    .md-errors-spacer {
      display: none;
    }
  }
}

.message-fields-container {

  .timezone-label {
    margin-bottom: 28px;
  }
}

.default-footer {
  padding-left: 5px;
  font-size: .75rem;
}

.publish-comm {
  label.section-title {
    margin: 0 0 20px;
  }

  .md-block {
    width: 350px;
    margin-right: 20px;

    .publish-value {
      color: $black;
      border-bottom: solid 1px $dividers;
      padding: 2px 0;
    }
  }

  timezone-dropdown {
    isc-dropdown.medium-small-size {
      display: inline-block;
      width: 70px;

      md-input-container.dropdown-container {
        display: block;
        width: 70px;
        margin: 0;

        md-select[disabled] .md-select-value {
          background-image: none;

          span {
            margin-top: -1px;
          }

          .md-select-icon {
            display: none;
          }
        }
      }
    }
  }
}

file-input {
  .ng-active .error-message.md-input-message-animation {
    font-size: .75rem;
    color: $errors;
  }
}

.communication-guidance {
  margin: 15px 0;
  color: $black-50;
  font-size: .875rem;
}

md-tabs {
  &.communication-tabs {
    float: right;
    margin-left: 20px;

    md-tabs-wrapper {
      border-width: 0;

      .md-tab {
        font-size: 1.063rem;
        text-transform: none;
        &.md-active {
          color: $black;
          font-weight: 600;
        }
      }

      md-ink-bar {
        height: 5px;
      }
    }
  }
}

.open-link-option {
  md-input-container {
    left: 100%;
    bottom: 22px;

    md-radio-button:first-of-type {
      margin-top: .75rem;
    }
  }
}
