﻿@import '~isc-ui/dist/components/_colors.scss';

$border-color-base: #E3E2E2;

$square-primary: #347F62;
$square-secondary: #3272A8;
$square-tertiary: #2A608E;
$square-quaternary: #374A5B;
$square-quinary: #F29303;
$square-filter-fields-title: rgba(#000000, .87);
$square-filter-fields-label: rgba(#000000, .38);

$text-base: #757575;
$text-paragraph: #6C6C6C;
$background-base: #FAFAFA;
$breadcrumb-grey: #DEDEDE;
$breadcrumb-base-color: rgba(0, 0, 0, .34);
$border-grey: $breadcrumb-grey;
$white: #FFFFFF;
$black: #000000;
$black-50: rgba($black, .5);
$gray-special: #CADEFF;
$light-grey: #C9C9C9;
$insites-orange: #F29303;
$insites-red: #F32651;
$dropdown-blue: #8FABC9;
$status-closed: #F32651;
$status-active: #4EBBBE;
$status-archived: #8BA7E2;
$status-scheduled: #F6C8EC;
$table-grey: #F9F9F9;
$hover-grey: #E0E0E0;
$box-shadow: #A5A3A3;
$dark-grey: #595959;
$consent-banner-background: #FF820026;
$consent-banner-link: #FF9200;
$consent-banner-text: #232323;

$status-hidden: #F32651;
$status-visible: #4EBBBE;

$btn-save-continue: #4EBBBE;

$insites-green: #347F62;
$human8-dark-teal: #004750;

$page-content-border-color: $border-color-base;

//text
$primary-text: rgba($black, .87);
$secondary-text: rgba($black, .54);
$disabled-or-hint-text: rgba($black, .38);
$dividers: rgba($black, .12);
$errors: rgba(202, 21, 42, 1);

//conversation stimuli
$conversation-stimuli-border-color: #D2D2D2;
$conversation-stimuli-video-overlay-background: $black-50;

$arrow-box-background: #F0F3F5;
$arrow-box-border: #E7E9E9;

$move-handle-hover: $hover-grey;
$move-placeholder: $insites-green;
$warning-message: #AE1F26;

$highlight: #9CD7C0;
