@import '../../../styles/_colors.scss';

.isc-segmentation-status-filter {
  position: relative;
  align-self: flex-end;

  .segmentation-status-select {
    min-width: 198px;
  }

  md-input-container {
    font-size: .875rem;
    margin: 0;
  }
}

.md-select-menu-container.md-active.segmentation-filter-container {
  md-select-menu,
  md-select-menu md-content {
    max-height: 292px;
  }
}
