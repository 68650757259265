activity-credits {
  label.reward-section-title {
    width: 140px;
  }

  .question {
    margin-bottom: 15px;

    md-input-container {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  i.fa {
    font-size: .8rem;
  }
}
