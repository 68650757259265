.position-right {
  position: absolute;
  right: 0;
}

.number {
  padding-left: 16px;
}

home-platform-notice {
  position: relative;

  .notice-type {
    padding-top: 32px;
  }

  .notice-small {
    padding-top: 8px;
  }

  .message {
    padding-bottom: 16px;
  }

  .message-combo {
    padding-left: 16px;
    padding-top: 32px;
  }

  .target {
    padding-top: 4px;
  }

  md-checkbox {
    margin-bottom: 0;
  }

  md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
    margin-top: 0;
    opacity: 1;
  }
  md-input-container .md-input-message-animation:not(.ng-animate) {
    margin-top: 0;
    opacity: 1;
  }

  mdp-date-picker .md-button.md-icon-button {
    padding-left: 0;
  }

  mdp-time-picker .md-button.md-icon-button {
    padding-left: 0;
  }

  md-input-container {
    padding-left: 0;
  }

  .ignore-input-container {
    padding-left: 0;
  }
}

landing-page {
  height: 100%;
}

.main-menu-col {
  color: rgba(0, 0, 0, .54);
}

.hours {
  align-self: center;
  margin: 6px;
  margin-top: -5px;
}
