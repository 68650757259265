@import './../colors';

isc-search-counter {
  .search-counter-container {
    background-color: $green-pill;
    color: $white;
    border-radius: border-inline-radius-values(5px);
    display: inline-flex;
    padding: padding-inline-values(5px);
    font-size: .8rem;
    justify-content: center;

    &:hover {
      text-decoration: underline;
    }

    .search-counter {
      width: auto;
    }
  }
}
