﻿@import 'colors';

//TODO: check why this value
$nav-lg-min: 1170px !default;
$nav-md-max: ($nav-lg-min - 1) !default;

$sider-nav-width: 200px;
$menu-bar-height: 65px;

$font-family-sans-serif-custom: 'Open Sans', 'Helvetica Neue', helvetica, arial, sans-serif !default;

$page-content-margin-top: 205px;
$page-content-border-radius: 8px;

$dialog-border-radius: 5px;
$dialog-title-height: 50px;
$dialog-padding: 20px;
$dialog-padding-bottom: 30px;
$dialog-title-icon-size: 24px;
$line-thickness: 2px;

// some stuff which we depended on when using Bootstrap, should be replaced/removed during redesign
$font-size-base: 16px;
$font-size-large: 1.125rem;
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-xs-max: ($screen-sm-min - 1);
$padding-large-horizontal: 16px;
$font-size-small: .75rem;
$border-radius-base: 4px;
$state-info-bg: #D9EDF7;
$alert-border-radius: $border-radius-base;
$state-info-border: darken($state-info-bg, 7%);
$alert-info-border: $state-info-border;
$state-danger-bg: #F2DEDE;
$state-danger-border: darken($state-danger-bg, 5%);
$alert-danger-border: $state-danger-border;
$line-height-base: calc(20 / 14);

$gray-base: #000000;
$gray-light: lighten($gray-base, 46.7%); // #777
$headings-small-color: $gray-light;
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: inherit;
$line-height-computed: 1.2rem;

$font-size-h1: 2.25rem;
$font-size-h2: 1.875rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: .75rem;

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: $line-height-computed;
  margin-bottom: calc($line-height-computed / 2);
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: calc($line-height-computed / 2);
  margin-bottom: calc($line-height-computed / 2);
}

.h1,
h1 {
  font-size: $font-size-h1;
}

.h2,
h2 {
  font-size: $font-size-h2;
}

.h3,
h3 {
  font-size: $font-size-h3;
}

.h4,
h4 {
  font-size: $font-size-h4;
}

.h5,
h5 {
  font-size: $font-size-h5;
}

.h6,
h6 {
  font-size: $font-size-h6;
}
