﻿isc-reward {
  .select-text {
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .multiply-icon {
    font-size: 1.5rem;
    padding-bottom: 5px;
  }

  .reward-list {
    background-color: $table-grey;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    height: 30px;

    > div {
      background-color: $table-grey;
      padding: 10px;
      line-height: 0;
    }
  }

  .add-reward-fields {
    padding: 5px;
  }

  .reward-spacer {
    padding-left: 5px;
    padding-right: 5px;
    width: 130px;
  }
}
