@import '../../../../../styles/_colors.scss';

isc-wizard-step {
  .info-text {
    font-weight: bold;
    color: $warning-message;
  }
  isc-input.cta-url-input md-input-container {
    width: 200%;
  }
  communication-publish {
    .publish-overview table.md-table td.md-cell {
      max-width: 110px;
    }
    .publish-launch {
      md-input-container {
        margin: 0;
      }
    }
    .md-input-no-label {
      top: -18px;
    }
  }
}
