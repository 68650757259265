@import './../media-mixin';
@import './../colors';

div.label-preparing {
  position: inherit;
  span {
    font-size: .8rem;
    color: $text-base;
  }
}

isc-stimuli-preview {
  > div {
    .conversation-stimulus-preview,
    .attachments {
      margin-top: 7px;
    }

    .attachments:last-of-type {
      margin-bottom: 10px;
    }
  }

  .conversation-stimulus-preview {
    position: relative;
    margin-bottom: 0;

    &:not(:last-child) {
      #{$margin-inline-end}: 8px;
    }

    .preview-card {
      border-radius: border-inline-radius-values(5px);
      height: 105px;
      width: 187px;
      background-size: cover;
      background-position: center;

      &.broken-stimuli-container {
        border: dashed 2px $broken-stimuli-border-color;
      }
    }

    .stimulus-remove {
      opacity: .4;
      position: absolute;
      top: 5px;
      #{$end}: 5px;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .video-overlay {
    height: 0;
    width: 30%;
    padding-bottom: 30%;
    background-color: rgba($black, .5);
    border-radius: border-inline-radius-values(50%);
    position: absolute;
    margin: auto;
    #{$start}: 0;
    top: 0;
    #{$end}: 0;
    bottom: 0;

    svg {
      top: 0;
      bottom: 0;
      #{$end}: 0;
      #{$start}: 0;
      position: absolute;
      height: 50%;
      fill: $white;
      margin: auto;

      &.play-icon,
      &.video-icon {
        #{$start}: 5%;
      }
    }

    md-progress-circular {
      height: 100% !important;
      width: 100% !important;
      #{$start}: 0;
      top: 0;
      position: absolute;

      &.white {
        svg path {
          stroke: $white;
        }
      }

      svg {
        width: 100% !important;
        height: 100% !important;
        transform-origin: inherit !important;
      }
    }
  }
}
