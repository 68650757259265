@mixin md-icon-size($size: 1.1rem) {
  font-size: $size;
  max-height: $size;
  min-height: $size;
  max-width: $size;
  min-width: $size;
}
@mixin disabled {
  opacity: .5;
}
