@import '../../../../../styles/_colors.scss';
$member-action-bar-height: 80px;
member-action-bar {
  height: $member-action-bar-height;
  display: block;
  overflow: hidden;
  background-color: $white;
  md-card {
    border: 0;
    opacity: 1;
  }
  &.hidden {
    height: 0;
    md-card {
      opacity: 0;
    }
    &.sticky {
      border: 0;
    }
  }
  &.sticky {
    margin-top: - $member-action-bar-height;
    top: 50px;
    z-index: 3;
    padding: 0;
    border-bottom: solid $border-color-base;
    border-width: 1px 0;
    md-card {
      margin: 0;
    }
    &.under-filter-fields {
      top: 120px;
    }
  }

  .member-action-bar-container {
    padding: 15px 30px 0;
    isc-button:last-child {
      margin-right: 0;
    }
  }

  .fa,
  .fas {
    font-size: .875rem;
    margin: 0 -8px 0 0;
  }

  /* This css is needed to make sure that the tooltip covers the entire request a call button */

  .request-a-call-btn {
    position: relative;
    margin-right: 15px;
  }

  .request-a-call-btn.tooltip-enabled:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    z-index: 1;
  }

  .request-a-call-btn:hover md-tooltip {
    display: block;
  }

  md-tooltip {
    position: absolute;
    display: none;
  }
}
