isc-modal-dialog.data-export-modal {
  width: 700px;
  .infotext {
    margin-bottom: 20px;
  }
  .bottomtext {
    margin-top: 20px;
  }
  .discussion-export-options {
    isc-checkbox {
      position: relative;
      top: -25px;
      left: 25px;
    }
  }
  isc-checkbox {
    span {
      font-size: .875rem;
    }
  }
  isc-radio-button {
    display: block;
  }
}
