@import '../_colors.scss';

isc-color-picker {
  .selectColorDivContainer {
    display: inline-block;
    margin: margin-inline-values(15px 0 25px);

    .input-error {
      color: $color-picker-input-error-color;
    }

    .selectColorDiv {
      display: inline-block;
      border: 1px solid $color-picker-border-color;
      margin: margin-inline-values(0 20px 5px 0);

      &.borderError {
        border-color: $color-picker-input-error-color;
      }

      .md-color-picker-input-container {
        display: inline;

        .md-color-picker-preview {
          border-radius: border-inline-radius-values(0);
          width: 120px;
          height: 120px;
          box-shadow: none;
          position: static;
          margin: margin-inline-values(5px 5px 0);
          border: none;
        }

        md-input-container {
          margin: margin-inline-values(3px 2px 5px);

          .md-color-picker-input {
            width: 120px;
          }
        }
      }

      .selectColor {
        display: inline-block;
        width: 122px;
        min-height: 122px;
        border: 1px solid $color-picker-border-color;
        border-radius: border-inline-radius-values(0);
        margin: margin-inline-values(5px 5px 3px);
      }

      .selectIcon {
        width: 122px;
        min-height: 122px;
        border-radius: border-inline-radius-values(0);
        background-color: $color-picker-select-icon-background-color;
        border: 1px solid $color-picker-border-color;
        margin: margin-inline-values(5px 5px 3px);
      }
    }
  }
}
