challenge-card-footer {
  .widgets {
    margin-top: 25px;
    display: block;
    font-size: .8rem;
    @include media(lt-md) {
      margin-top: 15px;
    }

    &.has-previous {
      padding-bottom: 10px;
    }

    .widget {
      line-height: 28px;
      vertical-align: middle;
      display: inline-block;
      #{$margin-inline-end}: 5px;
    }

    .star-widget {
      line-height: 28px;
    }

    .slash {
      #{$margin-inline-end}: 8px;
      #{$margin-inline-start}: 8px;
    }

    md-icon {
      @include md-icon-size(13px);
      min-height: 14px;
      max-height: 14px;
      #{$margin-inline-end}: 5px;
    }

    .fa.fa-hourglass-end {
      #{$margin-inline-end}: 0;
    }

    .fa.fa-star {
      margin: margin-inline-values(0 5px 0 -2px);
    }

    .fa.fa-database {
      #{$margin-inline-end}: 1px;
    }

    .fa.fa-clock {
      font-size: .8rem;
      position: relative;
      top: -2px;
    }

    .rewards {
      font-size: .8rem;
      margin-top: 4px;
    }
  }
}
