@import '../colors';
@import '../_media-mixin.scss';

isc-redemption-threshold {
  margin-bottom: 20px;
  display: inline-block;

  span.connecting-line {
    width: 4vw;
    height: 3px;
    background-color: $neutral-pill;
    display: block;

    &.active {
      background-color: $square-primary;
    }
  }

  table.container td {
    padding: padding-inline-values(0);
  }

  isc-redemption-threshold-card md-card {
    margin: margin-inline-values(0);
    box-shadow: none;
  }
  @include media(lt-md) {
    width: 100%;

    span.connecting-line {
      width: 3px;
      height: 20px;
      background-color: $neutral-pill;
      display: inline-block;
    }

    table.container {
      width: 100%;

      td {
        display: block;
        width: 100%;
        &.connecting-line-column {
          height: 20px;
        }
      }
    }

    isc-redemption-threshold-card md-card {
      width: 100%;
      height: 80px;

      md-card-title {
        &.subtitle {
          padding-top: 20px !important;
        }
        md-card-title-text {
          &.less-left-margin-on-lt-sm md-icon {
            #{$margin-inline-start}: 20px;
          }
          &.less-right-margin-on-lt-sm md-icon {
            #{$margin-inline-end}: 20px;
          }
          md-icon span.md-subhead {
            display: inline;
          }
        }
      }
    }
  }
}
