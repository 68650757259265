.timeline-card {
  opacity: 1;
  transition: opacity 1s;
  display: block;

  div.notification-bell {
    position: absolute;
    top: 0;
    #{$end}: 0;
    padding: padding-inline-values(4px 6px);
    border-radius: border-inline-radius-values(50%);
    background-color: $notification-background;
    text-align: center;
    font-size: 1.1rem;
    md-icon {
      color: $white;
    }
  }
  .sticky-container div.notification-bell {
    margin-top: -4px;
  }
  &.invisible {
    opacity: 0;
  }

  .timeline-panel {
    .timeline-title {
      display: inline-block;
      font-size: 1.1rem;
      text-align: $start;
      font-weight: bold;
      word-wrap: break-word;
      padding: padding-inline-values(20px);
      @include media(lt-md) {
        @include timeline-badge-inline();
      }

      &:focus {
        outline: none;
      }
    }

    .sticky-container {
      padding: padding-inline-values(0 20px);
      margin-bottom: 10px;

      &.stimuli {
        position: relative;
        top: 15px;
        height: 0;
        z-index: 1;
        padding: padding-inline-values(0);
        margin: margin-inline-values(0 30px 0 30px);
      }

      .sticky,
      .chip {
        color: $white;
        font-family: 'Open Sans';
        font-size: .8rem;
        opacity: .75;
        background-color: $black;
        border-radius: border-inline-radius-values(20px);
        padding: padding-inline-values(5px 15px);
        margin: margin-inline-values(0 5px 0 0);
      }

      .sticky {
        line-height: 28px;
      }

      .chip {
        position: absolute;
        #{$end}: 0;
      }
    }

    .timeline-theme {
      margin-bottom: 20px;

      .theme-name {
        font-size: .8rem;
        padding: padding-inline-values(5px 20px 0);
      }
    }

    isc-stimuli {
      margin: margin-inline-values(0 20px 20px);

      button {
        cursor: default;
      }
    }

    .timeline-body {
      margin-bottom: 20px;

      .text {
        text-align: $start;
        word-break: break-word;
        padding: padding-inline-values(0 20px);

        p:empty {
          display: none;
        }

        > p {
          margin: margin-inline-values(10px 0 0);

          &:first-child {
            margin-top: 0;
          }

          & + p {
            margin-top: 5px;
          }
        }
      }

      .overlay-container {
        position: relative;
      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        #{$start}: 0;
        #{$end}: 0;
        background-color: transparent;
        z-index: 10;
        outline: none;
      }
    }

    md-divider {
      margin: margin-inline-values(0 20px);
    }

    .timeline-footer {
      padding: padding-inline-values(0);
      challenge-card-footer {
        margin: margin-inline-values(0 0 20px 20px);
      }

      .md-button {
        margin: margin-inline-values(0);
        font-weight: bold;
      }

      .call-to-action {
        margin-bottom: 20px;
        #{margin-inline-start}: auto;
        padding: padding-inline-values(0 20px);
        @include media(xs) {
          width: 100%;

          .md-button {
            width: 100%;
          }
        }
      }

      md-icon {
        width: 12px;
        min-width: 12px;
        font-size: .8rem;
        min-height: 14px;
        max-height: 14px;
        color: inherit;
      }

      .typcn:before {
        vertical-align: top;
      }

      .fa.fa-clock {
        font-size: 1.4rem;
        position: relative;
        top: -1px;
      }

      .fa.fa-gift {
        font-size: .8rem;
      }

      .footer-icons {
        margin-bottom: 20px;
        #{$padding-inline-start}: 20px;
        white-space: nowrap;

        .footer-icon {
          line-height: 28px;
          vertical-align: center;
          white-space: nowrap;
          display: inline-block;

          .date-label {
            #{$margin-inline-end}: -5px;
          }

          > span {
            vertical-align: middle;
          }

          &:last-child {
            #{$margin-inline-end}: 0;
          }

          &.separator {
            margin: margin-inline-values(0 10px);

            md-icon {
              line-height: 15px;
              font-size: .4rem;
              margin: margin-inline-values(0);
              min-width: 6px;
              width: 6px;
            }
          }
        }
      }
    }

    .clickable {
      cursor: pointer;
    }
  }
  @include timeline-badge();
}
