isc-wizard-step {
  div.section-title-container {
    margin: 0 0 20px;
    padding: 20px 0 0;
  }

  h2 {
    font-weight: bold;
  }

  label.section-title {
    font-size: .875rem;
    color: rgba(0, 0, 0, .56);
    font-weight: bold;
    margin: 0 0 20px;
    padding: 20px 0 0;
    display: block;
  }

  .publish-value,
  publish-label {
    font-size: .875rem;
  }
}

label.small-label {
  font-size: .875rem;
}

label.input-title {
  margin-top: 0;
  margin-bottom: .8rem;
  font-size: .75rem;
  color: $text-base;
  left: 2px;
  display: block;

  &.withhelper {
    display: inline-block;
  }

  &.tooltip {
    width: max-content;
    margin-bottom: 0;
    i.fa {
      font-size: .8rem;
    }
  }
}

publish-url,
publish-date,
publish-credits {
  margin-bottom: 30px;
  display: block;
}

label.input-rich-title {
  font-size: .8rem;
  color: $text-base;
  padding-left: 2px;
}

.tooltip-rich {
  top: -12px;
  position: relative;
}

.tooltip-input {
  top: -28px;
  position: relative;
}

timezone-dropdown {
  isc-dropdown {
    md-input-container.md-block {
      display: table-cell;
    }
  }
}

.zIndex {
  position: relative;
  z-index: 2;
}
