@import 'base-variables';
@import 'colors';
@mixin info-box {
  display: block;
  flex: 0 0 auto;
  padding-left: 40px;
  padding-right: 25px;
  padding-top: 0;
  height: 70px;
  min-height: 70px;
  z-index: 5;
  right: 0;
  @media screen and (max-width: $nav-md-max) {
    left: 111px !important;
  }
  @media screen and (max-width: $screen-xs-max) {
    left: 0 !important;
  }

  p {
    display: inline-block;
    color: $dark-grey;
    opacity: .87;
    padding-top: 0;
    margin-top: 0;
    font-style: italic;
  }
}

.info-box {
  @include info-box;
}
