﻿@import 'base/_variables.scss';

.drop-box {
  border: 3px dotted $breadcrumb-grey;
  width: 388px;
  height: 78px;
  text-align: center;
  background: url('/images/dropzone.png');
  margin-bottom: 28px;
}

span.stimuli-required {
  position: absolute;
  margin-left: 200px;
}

.drop-box[disabled] {
  opacity: $opacity-onethird;
}

.drag-over-accept {
  border-color: $alert-info-border;
}

.drag-over-reject {
  border-color: $alert-danger-border;
}

.media-preview {
  width: 178px;
  height: 100px;
  display: inline-block;
  position: relative;
  opacity: .7;
  margin: 10px 15px 10px 0;
  border: 1px solid rgba(128, 128, 128, 0);
  border-radius: 7px;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }

  .media-remove {
    position: absolute;
    top: -50px;
    right: 5px;
    text-align: center;
    cursor: pointer;
    &:focus-visible {
      top: 5px;
    }
  }

  &:hover .media-remove {
    top: 5px;
  }

  .image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      z-index: -1;
    }
  }
}
