@import '../_colors.scss';

notification-counter {
  .numbered-circle {
    border-radius: border-inline-radius-values(50%);
    width: 15px;
    height: 15px;
    background: $notification-counter-background;
    color: $notification-counter-color;
    font-size: .6rem;
    font-weight: bold;
    position: relative;
    bottom: 22px;
    #{$start}: 12px;
    text-align: center;
    line-height: 15px;
    cursor: pointer;
  }
  &.hidden-counter {
    visibility: hidden;
  }
}
