﻿.ng-fade {
  transition: .1s linear all;
  opacity: 1;

  &.ng-enter {
    transition: .1s .1s linear all;
    opacity: 0;

    &.ng-enter-active {
      opacity: 1;
    }
  }

  &.ng-leave {
    transition: .1s linear all;
    opacity: 1;

    &.ng-leave-active {
      opacity: 0;
    }
  }

  &.ng-hide {
    opacity: 0;
  }
}

.ng-slide {
  //transition: all .2s ease-in-out;
  transform: translateY(0);
  transition-duration: .5s;
  opacity: 1;
  z-index: -1;

  &.ng-hide {
    transform: translateY(-50px);
    //transform: scale(1.1);
    transition-duration: .5s;
    opacity: 0;
  }
}
