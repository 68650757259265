@import './../media-mixin';
@import './../colors';

isc-visual-focused-conversation {
  display: flex;

  .visual-focused-conversation-container {
    width: 100%;
  }

  .centered-stream {
    max-width: 614px;
    margin: 0 auto;
  }

  isc-upload-conversation {
    margin-bottom: 20px;
  }

  isc-caption-editor {
    max-width: 614px;
  }

  .read-only-thumbnails {
    margin-bottom: 20px;
  }
}
