@import '../../../../../styles/_colors.scss';
consent-expiration-banner {
  md-card-content {
    background-color: $consent-banner-background;
    color: $consent-banner-text;
    a {
      color: $consent-banner-link;
      text-decoration: underline;
    }
    a:hover {
      cursor: pointer;
    }
  }
}
