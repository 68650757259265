@import '../../../../../styles/colors';

.quota-table {
  md-table-container {
    min-width: 700px;
    width: 50%;
  }

  .quota-number-error {
    color: $errors;
  }

  isc-table-container {
    md-table-container {
      margin-bottom: 10px;

      table.md-table {
        thead td.md-cell {
          text-align: center;
          min-width: 120px;
        }

        tbody {
          td.md-cell {
            text-align: center;

            input {
              text-align: center;
              padding-left: 1rem;
            }
          }

          tr {
            height: 70px;
          }
        }
      }
    }
  }

  tfoot > tr > td > div {
    margin: none auto;
    width: 100%;
  }
}
