@import '../../../../styles/_colors.scss';

conversation-post {
  .stimuli-item {
    float: left;
    position: relative;
    isc-stimuli-download i {
      position: absolute;
      display: block;
      color: #FFFFFF;
      top: 6px;
      right: 3px;
      z-index: 2;
      cursor: pointer;
      text-shadow: -1px 0 $black, 0 1px $black, 1px 0 $black, 0 -1px $black;
      visibility: hidden;
    }
  }
  .stimuli-item:hover isc-stimuli-download i {
    visibility: visible;
  }
}
