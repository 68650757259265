@import '../../../styles/_colors.scss';

md-progress-linear.job-progress-bar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.modal-title {
  border-bottom: 1px solid;
  border-color: $light-grey;
  margin-bottom: 5px;
  margin-top: 15px;
  font-weight: bold;
}

.job-progress-result {
  .result-row {
    cursor: default;
    &.collapsible {
      cursor: pointer;
      position: relative;
      p {
        margin-bottom: 0;
      }
      > md-content {
        font-size: .8rem;
        transition: all .2s;
        height: 100px;
        opacity: 1;
        padding-bottom: 0;
        span {
          padding: 0 8px;
        }
      }

      &:after {
        content: '-';
        position: absolute;
        left: -10px;
        top: 12px;
      }

      &.collapsed {
        > md-content {
          height: 0;
          opacity: 0;
          overflow: hidden;
        }
        &:after {
          content: '+';
        }
      }
    }
  }
}
