@import '../../../../../styles/_colors.scss';

md-tabs.configuration-tabs {
  width: 100%;
  display: block;
  overflow-y: none;

  md-tabs-canvas {
    padding: 0 50px;
  }

  md-pagination-wrapper {
    border-bottom: solid 1px rgba($black, .12);
  }

  md-tabs-canvas,
  md-pagination-wrapper,
  .md-tab {
    height: 74px;
  }

  .page-content {
    border: none;
    padding: 30px 0;
  }

  .tab-subtitle {
    color: rgba($black, .38);
    font-size: .75rem;
  }

  .tab-title {
    color: rgba($black, .87);
    font-size: 1rem;
  }

  md-ink-bar {
    height: 5px;
  }

  .pages-error {
    color: $errors;
    font-size: .75rem;
  }
}
