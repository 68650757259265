$dir: ltr !default;
@function if-rtl($if, $else: null) {
  @return if-ltr($else, $if);
}
@function if-ltr($if, $else: null) {
  @if $dir != rtl {
    @return $if;
  }
  @else {
    @return $else;
  }
}
@function corner-values($values) {
  @if $dir == rtl and length($values) > 1 {
    // Reorder right and left positions in list
    @if length($values) == 2 {
      @return nth($values, 2) nth($values, 1);
    }
    @else if length($values) == 3 {
      @return nth($values, 2) nth($values, 1) nth($values, 2) nth($values, 3);
    }
    @else {
      @return nth($values, 2) nth($values, 1) nth($values, 4) nth($values, 3);
    }
  }
  @else {
    @return $values;
  }
}
@function side-values($values) {
  @if $dir == rtl and length($values) >= 4 {
    // Reorder right and left positions in list
    @return nth($values, 1) nth($values, 4) nth($values, 3) nth($values, 2);
  }
  @else {
    @return $values;
  }
}
@function margin-inline-values($values) {
  @return side-values($values);
}
@function padding-inline-values($values) {
  @return side-values($values);
}
@function border-inline-values($values) {
  @return side-values($values);
}
@function border-inline-radius-values($values) {
  @return corner-values($values);
}
@function translate-inline-value($value) {
  @return if-ltr($value, -$value);
}
@function rotate-inline-value($value) {
  @return if-ltr($value, $value + 180deg);
}

$start: if-ltr(left, right);
$end: if-ltr(right, left);
$margin-inline-start: if-ltr(margin-left, margin-right);
$margin-inline-end: if-ltr(margin-right, margin-left);
$padding-inline-start: if-ltr(padding-left, padding-right);
$padding-inline-end: if-ltr(padding-right, padding-left);
$border-inline-start: if-ltr(border-left, border-right);
$border-inline-end: if-ltr(border-right, border-left);
$border-top-start-radius: if-ltr(border-top-left-radius, border-top-right-radius);
$border-top-end-radius: if-ltr(border-top-right-radius, border-top-left-radius);
$border-bottom-start-radius: if-ltr(border-bottom-left-radius, border-bottom-right-radius);
$border-bottom-end-radius: if-ltr(border-bottom-right-radius, border-bottom-left-radius);
