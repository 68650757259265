intro-or-url-editor {
  .description-error,
  sso-idea-stream > div,
  .url {
    margin-left: 30px;
  }

  isc-rich-input {
    position: relative;
    top: 20px;
  }
}
